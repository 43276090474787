import { Component, ContentChildren, Input, QueryList } from '@angular/core';

@Component({
	selector: 'service-data-row',
	templateUrl: 'service-data-row.component.html',
	styleUrls: ['service-data-row.component.scss']
})
export class ServiceDataRowComponent {
	@ContentChildren('content') contentChildren: QueryList<any>;

	@Input() wide: boolean = false;
	@Input() question: string = '';
	@Input() response: string = '';
	@Input() detail: string = '';
	@Input() showDetail: boolean = true;
	@Input() bg: number = 0;

	getContentLength(): number {
		return this.contentChildren.length;
	}
}
