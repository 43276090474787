<div class="tab-theme">
  <app-tab-set #tabSet [tabs]="getTabs()"></app-tab-set>

  <div class="tab-content">
    <!--component html goes here -->
    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0 }">
      <app-cell-visit-list></app-cell-visit-list>
    </div>
    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1 }">
      <app-client-search listType='cell' (itemSelected)="selectClient($event)"></app-client-search>
    </div>
    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 2 }">
      <div *ngIf="isOffline"> <!-- Conditionally render the content only when isOffline is false -->
        <app-cell-visit-sync-list></app-cell-visit-sync-list>
      </div>
    </div>
    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 3 }">
      <div *ngIf="isOffline"> <!-- Conditionally render the content only when isOffline is false -->
        <app-cell-visit-synced-list></app-cell-visit-synced-list>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h3 class="modal-title">Referral Form</h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="form-theme">
      <p>Please make sure to consider that consent can only be obtained if (All options must be ticked):</p>
      <div class="form-check mb-3">
        <input type="checkbox" value="" id="intoxicated" class="form-check-input">
        <label for="intoxicated" class="form-check-label"> The Client is sober and not intoxicated</label>
      </div>
      <div class="form-check mb-3">
        <input type="checkbox" value="" id="impaired" class="form-check-input">
        <label for="impaired" class="form-check-label"> The Client is sound mind and their decision-making is not
          impaired</label>
      </div>
      <div class="form-check mb-4">
        <input type="checkbox" value="" id="consent" class="form-check-input">
        <label for="consent" class="form-check-label"> The Client has provided verbal consent</label>
      </div>
      <div class="">
        <div class="mb-3">
          <label for="">The Client Agrees That:</label>
        </div>
        <div class="form-check mb-3">
          <input type="checkbox" value="" id="personal" class="form-check-input">
          <label for="personal" class="form-check-label"> My personal details may be used so that the following
            additional
            support can be provided to me:</label>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-lg-5">
            <div class="form-check mb-2">
              <input type="checkbox" value="" id="referral" class="form-check-input">
              <label for="referral" class="form-check-label"> A referral to a support service</label>
            </div>
          </div>
          <div class="col-lg-6">
            <select name="" id="" class="form-select">
              <option value="">Andrew Stangret (Moonya )</option>
              <option value="">Tom Gamosh(Murriwatch)</option>
            </select>
          </div>
        </div>

        <textarea class="form-control" rows="2"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-red">Save</button>
    <button type="button" class="btn btn-red" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
