<!--component html goes here -->
<div class="theme-box">
	<div class="text-center mb-4">
		<h3 *ngIf="id">Edit User</h3>
		<h3 *ngIf="!id">Add New User</h3>
	</div>
	<form [formGroup]="userForm" class="mb-xl-4 mb-3">
		<div class="row g-3 align-items-center">
			<div class="col-lg-6 textarea-wrapper">
				<div class="form-group">
					<label>First Name</label>
					<input #firstNameTb type="text" class="form-control" formControlName="firstName"
						[ngClass]="{ 'is-invalid': userForm.controls.firstName.errors }" maxlength="250">
					<div *ngIf="userForm.controls.firstName.errors" class="invalid-feedback">
						<div *ngIf="userForm.controls.firstName.errors.required">
							Firstname is required
						</div>
					</div>
					<span class="remaning">{{firstNameTb.value.length}}/250</span>
				</div>
			</div>
			<div class="col-lg-6 textarea-wrapper">
				<label>Last Name</label>
				<input #lastNameTb type="text" class="form-control" formControlName="lastName"
					[ngClass]="{ 'is-invalid': userForm.controls.lastName.errors }" maxlength="250">
				<div *ngIf="userForm.controls.lastName.errors" class="invalid-feedback">
					<div *ngIf="userForm.controls.lastName.errors.required">
						Lastname is required
					</div>
				</div>
				<span class="remaning">{{lastNameTb.value.length}}/250</span>
			</div>
			<div class="col-lg-6 textarea-wrapper">
				<label>Email</label>
				<input #emailTb type="email" class="form-control" formControlName="email"
					[ngClass]="{ 'is-invalid': userForm.controls.email.errors }" maxlength="250">

				<div *ngIf="userForm.controls.email.errors" class="invalid-feedback">
					<div *ngIf="userForm.controls.email.errors.email">
						Please enter valid email address
					</div>
					<div *ngIf="userForm.controls.email.errors.required">
						Please enter email address
					</div>
				</div>
				<span class="remaning">{{emailTb.value.length}}/250</span>
			</div>

			<div class="col-lg-6 textarea-wrapper" *ngIf="userRole == 'Admin'">
				<label>Role</label>
				<select #roleDd class="form-select" aria-label="Default select example" formControlName="role"
					[ngClass]="{ 'is-invalid': userForm.controls.role.errors }" maxlength="250">
					<option>Admin</option>
					<option>CaseWorker</option>
					<option>CellVisitor</option>
					<option>SupportOfficer</option>
				</select>

				<div *ngIf="userForm.controls.role.errors" class="invalid-feedback">
					<div *ngIf="userForm.controls.role.errors.required">
						Please select valid role
					</div>
				</div>
				<span class="remaning">{{roleDd.value.length}}/250</span>
			</div>

			<div class="col-lg-6 textarea-wrapper">
				<label>Phone</label>
				<input #phoneText type="text" class="form-control" formControlName="phone"
					(input)="formatContactNumber(phoneText.value)" [ngClass]="{ 'is-invalid': userForm.controls.phone.errors }"
					maxlength="250">

				<div *ngIf="userForm.controls.phone.errors" class="invalid-feedback">
					<div *ngIf="userForm.controls.phone.errors.required">
						Phone is required
					</div>
				</div>
				<span class="remaning">{{phoneText.value.length}}/250</span>
			</div>

			<div class="col-lg-6 textarea-wrapper">
				<label>Pin</label>
				<input #pinText type="text" class="form-control" formControlName="pin"
					[ngClass]="{ 'is-invalid': userForm.controls.pin.errors }" maxlength="4">
				<span class="remaning">{{pinText.value.length}}/250</span>
			</div>

			<div class="col-lg-6 textarea-wrapper" *ngIf="userRole == 'Admin'">
				<label>Location</label>
				<select class="form-select" aria-label="Default select example" formControlName="locationId"
					[ngClass]="{ 'is-invalid': userForm.controls.locationId.errors }" maxlength="250">
					<option value="" disabled selected>Select Location</option>
					<option *ngFor="let loc of locationList" value="{{loc.id}}">{{loc.name}}</option>
				</select>
				<div *ngIf="userForm.controls.locationId.errors" class="invalid-feedback">
					<div *ngIf="userForm.controls.locationId.errors.required">
						Location is required
					</div>
				</div>
			</div>

		</div>

		<div class="row align-items-center mt-3">
			<div class="col-auto" *ngIf="!isConfirmSignup && id">
				<div class="alert alert-success my-0" role="alert">
					<i class="fa-solid fa-circle-check me-2"></i>Invite Accepted
				</div>
			</div>
		</div>

		<div class="row align-items-center mt-3">
			<div class="col-auto" *ngIf="isConfirmSignup">
				<div class="alert alert-success my-0" role="alert">
					<i class="fa-solid fa-circle-check me-2"></i>Invited
				</div>
			</div>
		</div>
	</form>

	<div *ngIf="id" class="row align-items-center">
		<div class="col-auto ms-auto">
			<button class="btn btn-theme d-inline-block" *ngIf="isConfirmSignup" (click)="inviteAgainUser()">Send
				Invite</button>
			<button *ngIf="!isArchived" class="btn btn-danger d-inline-block ms-3" (click)="deleteUser()">Archive</button>
			<button *ngIf="isArchived" class="btn btn-danger d-inline-block ms-3" (click)="restoreUser()">UnArchive</button>
			<button *ngIf="!isArchived" [disabled]="userForm.invalid" class="btn btn-theme d-inline-block ms-3"
				(click)="updateUser()">Update</button>
		</div>
	</div>

	<div *ngIf="!id" class="row">
		<button [disabled]="userForm.invalid" class="btn btn-theme col-auto ms-auto" (click)="updateUser()">Invite</button>
	</div>
</div>
