import { GenericApi } from '../../main/api/generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDiversionCentre } from '../model/diversion-centre.model';
import { createUrl } from './api.util';

@Injectable()
export class DiversionCentreApi extends GenericApi<IDiversionCentre> {
  public path = "diversion-centre";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  /**
   * createDiversionCentre
   */
  public createDiversionCentre(credentials: {}) {
    return this.httpClient.post<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'createDiversionCentre'), credentials);
  }

  /**
   * updateDiversionCentre
   */
  public updateDiversionCentre(diversionId, credentials: {}) {
    return this.httpClient.put<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'updateDiversionCentre', diversionId), { ...credentials, diversionId: diversionId });
  }

  /**
  * updateDiversionCentre
  */
  public updateBulkDiversionCentre(diversionId, credentials: {}) {
    return this.httpClient.put<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'updateBulkDiversionCentre', diversionId), { ...credentials, diversionId: diversionId });
  }

  /**
   * listingDiversionCentre
   */
  public listingDiversionCentre(credentials: {}) {
    return this.httpClient.post<{
      message: string,
      status: boolean,
      data: {
        count: number,
        rows: unknown[]
      }
    }>(createUrl(this.path, 'listingDiversionCentre'), credentials);
  }
}