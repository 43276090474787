<div class="mb-30">
    <div class="row g-3">
        <div class="col">
            <div class="input-group">

                <div class="form-floating">
                    <input #searchText type="text" class="form-control" id="floatingInputGroup1" placeholder="Search" (keyup)="searching(searchText.value)">
                    <label for="floatingInputGroup1">Client Search</label>
                </div>
                <span class="input-group-text">
                    <svg class="" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path
                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                        </path>
                    </svg></span>
            </div>
        </div>
        <div class="col-md-auto"><button class="btn btn-large btn-primary" [routerLink]="['/admin/users/edit']">Add New</button></div>
    </div>
</div>

<div class="theme-box p-0">
    <div class="table-responsive mb-30">
        <table class="table table-hover table-theme table-stripedTheme table-dashboard text-center">
            <thead class="thead-dark">
                <tr>
                    <th>FirstName</th>
                    <th>LastName</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Location</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let usr of userList | paginate: { 
                itemsPerPage: pageLimit, 
                currentPage: currentPage,
                totalItems :totalCount
            }" (click)="selectUser(usr)">
                    <td>{{usr.firstName}}</td>
                    <td>{{usr.lastName}}</td>
                    <td>{{usr.email}}</td>
                    <td>{{usr.role}}</td>
                    <td>{{usr.locationId}}</td>
                </tr>
            </tbody>
        </table>
        <div class="has-text-centered">
            <app-pagination [currentPage]="currentPage" [pageSize]="pageLimit"
            [totalRows]="totalCount"
            (pageChange)="pageChanged($event)" 
            ></app-pagination>
        </div>
    </div>
    <div class="row align-items-center p-4">
    <div *ngIf="!isArchived"><button (click)="showArchived()" class="btn btn-theme col-auto ms-auto">Show Archived</button></div>
    <div *ngIf="isArchived"><button (click)="getUserCollection()" class="btn btn-theme col-auto ms-auto">Hide Archived</button></div>
    </div>
</div>