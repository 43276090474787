import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createUrl } from './api.util';
import { IReportOptions } from '../model/report.model';

@Injectable()
export class ReportApi {
  public path = "report";

  constructor(
    public httpClient: HttpClient
  ) { }

  getReport = (opts: IReportOptions): Observable<string> => {
    return this.httpClient.post<string>(
      createUrl(this.path), opts
    );
  };
}