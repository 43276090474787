import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ContactService } from 'src/app/main/services/contact.service';
import { IContact } from '../../model/contact.model';
import { CategoryService } from '../../services/category.service';
import { ICategory } from '../../model/category.model';
import { LocationService } from '../../services/location.service';
import { ILocation } from '../../model/location.model';
import { nameValidator } from './contact-edit.validators';
import { logger } from '../../util/Logger';
import { getAllFormErrors } from '../../util/formUtils';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.sass']
})
export class ContactEditComponent implements OnInit {

  public id: string = "";
  contactForm: FormGroup;
  public submitted = false;
  public currentContact: IContact;
  public categoryList: ICategory[] = [];
  public locationList: ILocation[] = [];

  constructor(
    private fb: FormBuilder,
    private __contactService: ContactService,
    private __categoryService: CategoryService,
    private __route: ActivatedRoute,
    private _notifications: NotificationsService,
    private __router: Router,
    private __locationService: LocationService,
  ) {
    this.getCategoryData();
    this.getLocations();

    this.contactForm = this.fb.group({
      categoryId: ['', [Validators.required]],
      firstName: ['', [nameValidator]],
      organizationName: ['', [nameValidator]],
      lastName: ['', [Validators.maxLength(250)]],
      address: ['', [Validators.maxLength(250)]],
      location: ['', [Validators.required, Validators.maxLength(250)]],
      phone: ['', [Validators.maxLength(250)]],
      mobile: ['', [Validators.maxLength(250)]],
    });
  }

  ngOnInit(): void {
    this.__route.paramMap.subscribe(params => {
      this.id = atob(params.get('id') || '');
      if (this.id) {
        this.__contactService.get(this.id).subscribe(async (response: IContact) => {
          let { firstName, lastName, categoryId, location, phone, address, mobile, organizationName } = response;

          this.currentContact = response;

          this.contactForm.controls['firstName'].setValue(firstName);
          this.contactForm.controls['lastName'].setValue(lastName);
          this.contactForm.controls['categoryId'].setValue(categoryId);
          this.contactForm.controls['location'].setValue(location);
          this.contactForm.controls['phone'].setValue(phone);
          this.contactForm.controls['address'].setValue(address);
          this.contactForm.controls['mobile'].setValue(mobile);
          this.contactForm.controls['organizationName'].setValue(organizationName);
          // this.contactForm.controls['pages'].setValue(this.pages().push(pages));

        }, err => {
          console.error(err);
          this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
        })
      }
    })

  }

  /**
     * getCategoryData
     */
  public getCategoryData(): void {
    this.__categoryService.list().subscribe(async (response: any) => {
      this.categoryList = response.rows;
    }, err => {
      console.error(err);
      this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
    })
  }

  /**
     * updateContact
     */
  public updateContact(): void {
    this.submitted = true;

    if (!this.contactForm.valid) {
      logger.error(getAllFormErrors(this.contactForm));
      return;
    }

    if (this.id) {
      this.__contactService.update(this.id, this.contactForm.value).subscribe(() => {
        this.__router.navigate([`/contacts/list`]);
      })
    } else {
      this.__contactService.create(this.contactForm.value).subscribe(() => {
        this.__router.navigate([`/contacts/list`]);
      })
    }
  }

  /**
     * deleteContact
     */
  public deleteContact(): void {
    if (this.id) {
      this.__contactService.delete(this.id).subscribe(() => {
        this.__router.navigate([`/contacts/list`]);
      })
    }
  }

  getLocations() {
    this.__locationService.list({ limit: 1000 }).subscribe(async (response: any) => {
      if (response) {
        this.locationList = response.rows;
      } else {
        this._notifications.warn("ERROR", response.message || "", {});
      }
    }, err => {
      console.error(err);
      this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
    })
  }

  changeVal(event) {
    this.contactForm.controls['organizationName'].updateValueAndValidity();
    this.contactForm.controls['firstName'].updateValueAndValidity();
  }

  formatContactNumber(inputValue: String): String {
    const value = inputValue.replace(/[^0-9]/g, ''); // remove except digits
    let format = '** **** ****'; // You can change format

    for (let i = 0; i < value.length; i++) {
      format = format.replace('*', value.charAt(i));
    }

    if (format.indexOf('*') >= 0) {
      format = format.substring(0, format.indexOf('*'));
    }

    this.contactForm.controls['phone'].setValue(format.trim());
    return format.trim();
  }

}
