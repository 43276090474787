import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { environment } from "../../../environments/environment";
import { IContact } from '../../main/model/contact.model';
import { ContactService } from '../services/contact.service';
import { logger } from '../util/Logger';

const className = "ContactComponent";

@Component({
  moduleId: module.id,
  selector: 'app-contact',
  templateUrl: 'contact.component.html',
  styleUrls: ['contact.component.scss']
})
export class ContactComponent implements AfterViewInit {

  constructor(
    private _notifications: NotificationsService,
    private __router: Router,
    public __contactervice: ContactService,
  ) {
  }

  public contactList = <any>[];
  public totalCount: number = 0;
  public currentPage: number = 1;
  public pageLimit: number = environment.defaultCollectionLimit || 10;
  public search: string = "";

  pageChanged(page: number) {
    this.currentPage = page;
    this.getContactCollection();
  }

  getContactCollection() {
    const signature = className + ".getContactCollection: ";
    logger.silly(signature + 'Started');
    
    let skip = (this.currentPage * this.pageLimit) - this.pageLimit;
    let search = (this.search) ? { $or: { location: { $like: `%${this.search}%` }, firstName: { $like: `%${this.search}%` }, lastName: { $like: `%${this.search}%` }, phone: { $like: `%${this.search}%` }, address: { $like: `%${this.search}%` }, mobile: { $like: `%${this.search}%` }, organizationName: { $like: `%${this.search}%` } } } : {};

    this.__contactervice.list({
      skip: skip,
      limit: this.pageLimit,
      filter: search,
    }).subscribe(async (response: any) => {
      if (response && response.rows) {
        this.contactList = response.rows;
        this.totalCount = response.count;
        // this._notifications.success("Done", response.message || "", {});
      } else {
        this._notifications.warn("ERROR", response.message || "", {});
      }
    }, err => {
      console.error(err);
      this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
    })
  }

  ngAfterViewInit() {
    this.getContactCollection();
  }

  searching(searchText: string) {
    this.search = searchText;
    this.getContactCollection();
  }

  /**
     * selectContact
     */
  public selectContact(cat: IContact): void {
    this.__router.navigate([`/contacts/edit`, btoa(cat.id)]);
  }
}
