import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { JwtService } from '../../services/jwt.service';
import { AdminService } from '../../services/admin.service';
import { NotificationsService } from 'angular2-notifications';
import { environment } from 'src/environments/environment';

export type MenuStates = 'open' | 'close';

type TPathMenuIcon = {
  type: 'path';
  d: string;
};

type TCircleMenuIcon = {
  type: 'circle';
  cx: string;
  cy: string;
  r: string;
};

type TMenuIcon = TPathMenuIcon | TCircleMenuIcon;

type TMenuItem = {
  icon?: TMenuIcon[];
  url?: string;
  prefix?: string;
  label: string;
  children?: TMenuItem[];
  open?: boolean;
  access?: string;
};

type TMenuItems = TMenuItem[];

@Component({
  selector: 'app-menu',
  templateUrl: 'app-menu.component.html',
  styleUrls: ['app-menu.component.scss']
})
export class AppMenuComponent {

  public menuItems: TMenuItems = [
    {
      icon: [{
        type: "path",
        d: 'M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z',
      }],
      url: '/dashboard',
      label: 'Dashboard'
    }, {
      icon: [{
        type: "path",
        d: 'M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z',
      }],
      url: '/clients',
      prefix: '/clients',
      label: 'Clients',
      children: [{
        label: 'List',
        url: '/clients'
      }, {
        label: 'Add New',
        url: '/clients/edit'
      }]
    }, {
      icon: [{
        type: "path",
        d: 'M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3H6.75V4h10.5v14z',
      }],
      url: '/contacts/list',
      label: 'Contacts',
      prefix: '/clients',
      children: [{
        label: 'List',
        url: '/contacts/list'
      }, {
        label: 'Add New',
        url: '/contacts/edit'
      }]
    }, {
      icon: [{
        type: "path",
        d: 'M5 15H3v4c0 1.1.9 2 2 2h4v-2H5v-4zM5 5h4V3H5c-1.1 0-2 .9-2 2v4h2V5zm14-2h-4v2h4v4h2V5c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z',
      }],
      url: '/diversion-centre',
      label: 'Diversion Centre'
    }, {
      icon: [{
        type: "path",
        d: 'M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z',
      }],
      url: '/cell-visits',
      label: 'Cell Visits'
    }, {
      icon: [{
        type: "path",
        d: 'M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z',
      }],
      url: '/community-patrol',
      label: 'Community Patrol'
    }, {
      icon: [{
        type: "path",
        d: 'M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z',
      }],
      label: 'Reports',
      url: '/reports'
    }, {
      icon: [{
        type: "path",
        d: 'M15 11V5l-3-3-3 3v2H3v14h18V11h-6zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z',
      }],
      url: '/locations',
      label: 'Locations'
    }, {
      icon: [{
        type: "path",
        d: 'M3 13.5h8v8H3z',
      }, {
        type: "path",
        d: 'M12 2l-5.5 9h11z',
      }, {
        type: "circle",
        cx: "17.5",
        cy: "17.5",
        r: "4.5"
      }],
      url: '/categories',
      label: 'Categories'
    }, {
      icon: [{
        type: 'path',
        d: 'M5 15H3v4c0 1.1.9 2 2 2h4v-2H5v-4zM5 5h4V3H5c-1.1 0-2 .9-2 2v4h2V5zm14-2h-4v2h4v4h2V5c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z'
      }],
      label: 'Admin',
      prefix: '/admin',
      url: '/admin/users',
      children: [{
        label: 'List',
        url: '/admin/users'
      }, {
        label: 'Add New',
        url: '/admin/users/edit'
      }, {
        label: 'Tools',
        url: '/admin/tools',
        access: 'SuperAdmin'
      }]
    }, {
      icon: [{
        type: 'path',
        d: 'M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9H9V9h10v2zm-4 4H9v-2h6v2zm4-8H9V5h10v2z'
      }],
      label: 'NextView'
    }];

  public currentRoute = '';
  @Input()
  menuState: MenuStates = 'open';

  constructor(
    private router: Router,
    private __adminUserService: AdminService,
    private _notifications: NotificationsService
  ) {
    this.__adminUserService.getAuthenticatedUser().subscribe({
      next: usr => {
        if (usr) {
          if (usr.role !== "Admin") {
            this.menuItems = this.menuItems.filter(item => item.label !== 'Admin');
          }

          if (!usr.email.match(/.*@wkdigital\.com\.au/)) {
            this.menuItems
              .filter(item => item.label === 'Admin')
              .forEach(menuItem => {
                if (menuItem.children) {
                  menuItem.children = menuItem.children.filter(item => !item.access || !(item.access === 'SuperAdmin'));
                }
              });
          }
        }
      },
      error: err => {
        console.error(err);
        this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
      }
    });

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    )
      .subscribe(
        {
          next: (e: NavigationEnd) => this.currentRoute = e.urlAfterRedirects.toLowerCase()
        }
      );
  }

  // Returns true if lowercase url is equal to this.currentRoute or this.currentRoute starts with the lowercase url
  public isActiveMenuItem(url: string | undefined, allowPrefix: boolean = false) {
    if (!url) return false;
    const lowerCaseUrl = url.toLowerCase();
    return this.currentRoute === lowerCaseUrl || (allowPrefix && this.currentRoute.startsWith(lowerCaseUrl));
  }

  public clickMenuItem(item: TMenuItem) {
    item.open = !item.open;

    this.menuItems.forEach(menuItem => {
      if (!menuItem.open) return;
      if (menuItem === item) return;

      menuItem.open = false;
    });

    if (item.label === 'NextView') {
      // Launch NextView
      this.__adminUserService.prepareNextviewSSO().subscribe({
        next: resp => {
          window.location.href = environment.nextview_url + '/?token=' + resp.data.token + "&logoutUrl=" + encodeURIComponent(window.location.href) + "&server=" + environment.endpoint;
        }
      });
    }
  }
}
