import { Component, Input, ApplicationRef, OnInit, ElementRef, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { showError } from '../../../main/util/formUtils';
import { logger } from "../../../main/util/Logger";

const className = "TextInutComponent";

@Component({
  moduleId: module.id,
  selector: 'app-text-input',
  templateUrl: 'text-input.component.html',
  styleUrls: ['text-input.component.scss']
})
export class TextInputComponent {
  isTouched: boolean = false;
  hasShownFormControlError: boolean = false;

  showError = showError;

  /**
   * @description Changes the dom element from a text input to a pseudo text-area
   */
  @Input()
  multiline: boolean = false;

  /**
   * @description FormGroup that the target field belongs to
   */
  @Input()
  form: UntypedFormGroup;

  /** 
   * @description Name/Key of the target field in the form group
   */
  @Input()
  field: string;

  /**
   * @description Label for the input
   */
  @Input()
  label: string;

  /**
   * @description {key:string} pairs for the error messages to display for each type of error
   */
  @Input()
  errors: { [key: string]: unknown; } = {};

  /**
   * @description Allows the input type to be changed
   */
  @Input()
  type: string = "text";

  @Input()
  id: string;

  @Input()
  disabled: boolean = false;

  get fieldId() {
    return this.id || ("text_" + this.field);
  }

  get fieldLabel() {
    return this.label || this.field;
  }

  get errorKeys() {
    return Object.keys(this.errors);
  }

  get hasContent() {
    if (!this.hasControl()) return false;

    const val = this.getControl().value;

    if (this.type === 'number') {
      return typeof val !== 'undefined' && val !== null;
    }

    return val && val.length;
  }

  get errorsToDisplay() {
    // const signature = "textInput.errorsToDisplay: ";
    const result = Object.keys(this.errors)
      .filter(errorName => showError(this.form, this.field, errorName))
      .map(errorName => this.errors[errorName]);

    // logger.silly(signature, result);

    return result;
  }

  constructor(
    private readonly applicationRef: ApplicationRef
  ) { }

  private hasControl(): boolean {
    return !!(this.form && this.form.controls && this.form.controls[this.field]);
  }

  public getControl(): AbstractControl {
    const signature = className + ".getConrol: ";

    if (typeof this.form === 'undefined') {
      if (!this.hasShownFormControlError) {
        logger.warn(signature + `Form does not exist when searching for Field[${this.field}]`);
        this.hasShownFormControlError = true;
      }
      return new UntypedFormControl();
    }

    if (typeof this.form.controls[this.field] === 'undefined') {
      if (!this.hasShownFormControlError) {
        logger.warn(signature + `Control does not exist when searching for Field[${this.field}]`);
        this.hasShownFormControlError = true;
      }
      return new UntypedFormControl();
    }
    
    this.hasShownFormControlError = false;
    return this.form.controls[this.field];
  }

  onEditableClick() {
    this.markAsTouched();
  }

  markAsTouched() {
    this.getControl().markAsTouched();
    this.isTouched = true;
  }

  onEditableChange(val: string) {
    const control = this.getControl();

    if (control.value !== val) {
      control.markAsDirty();
      control.setValue(this.type === 'number' ? parseInt(val) : val);
    }

    this.clearServerValidation();
  }

  onClick() {
    this.markAsTouched();
  }

  onKeyDown() {
    this.clearServerValidation();
  }

  onKeyUp(evt: KeyboardEvent) {
    const target = evt.target;

    if (target && 'value' in target) {
      this.onEditableChange(String(target['value']));
    }
  }

  clearServerValidation() {
    const controlErrors = this.getControl().errors;
    if( controlErrors ) {
      delete controlErrors['serverValidation'];
      this.getControl().setErrors(controlErrors);
    }
  }
}