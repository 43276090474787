import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ErrorMessage, FieldRejection } from '../model/api.model';
import { logger } from './Logger';
import { isString } from './utils';

const className = "FormUtils";

export const showError = (form: UntypedFormGroup | undefined, fieldName: string, errorName: string | string[]): boolean => {
	// const signature = "formUtils.showError: ";
	if (!form || form.disabled || !form.controls[fieldName] || (isString(errorName) && (form.controls[fieldName].disabled && !form.controls[fieldName].touched)))
		return false;

	if (Array.isArray(errorName)) {
		for (let i = 0; i < errorName.length; i++) {
			const errorNameActual = errorName[i];

			if (!form.controls[fieldName].disabled && form.controls[fieldName].hasError(errorNameActual))
				return true;
		}

		return false;
	}

	const result = form.controls[fieldName].hasError(errorName);

	// logger.silly(signature+`form field[${fieldName}] errorName[${errorName}] hasError[${result}]`);

	return form.controls[fieldName].hasError(errorName);
}

export const getError = (form: UntypedFormGroup, fieldName: string, errorName: string) => {
	if (!showError(form, fieldName, errorName)) return "";

	if (errorName)
		return form.controls[fieldName].getError(errorName);
	else
		return form.controls[fieldName].valid;
}



export function getAllFormErrors(form: UntypedFormGroup) {
	const signature = className + ".getAllFormErrors: ";
	const allFields = Object.keys(form.controls);
	const errors: ValidationErrors[] = [];

	for (let i = 0; i < allFields.length; i++) {
		let fieldErrors = form.controls[allFields[i]].errors;
		if (fieldErrors) {
			logger.silly(signature + `Error in Field[${allFields[i]}]: ${JSON.stringify(fieldErrors)}`);
			errors.push(fieldErrors);
		}
	}

	return errors;
}

export const canSubmit = (form: UntypedFormGroup | undefined) => {
	if (!form || form.pending) return false;

	const allFields = Object.keys(form.controls);

	let errors: ValidationErrors, errorKeys: string[];
	for (let i = 0; i < allFields.length; i++) {
		errors = form.controls[allFields[i]].errors || [];
		if (errors) {
			errorKeys = Object.keys(errors).filter(key => !['_subscribe'].includes(key));
			if (errorKeys.length === 0) {
				continue;
			}

			if (errorKeys.length > 1 || errorKeys[0] !== 'serverValidation') {
				return false;
			}
		}
	}

	return true;
}

/**
 * @description Display helper for outputting server-side validation errors for any given form
 *
 * @param {FieldRejection[]} targetArray The array that should contain the field rejections (if any)
 * @returns {string|undefined}
 */
export const displayValidationErrors = (rejections?: FieldRejection[]): string | undefined => {
	if (!rejections || !rejections.length) return undefined;

	const errorStrs = rejections.map(rejection => {
		let result = `<strong>Invalid ${rejection.field}. Expected Value(s): </strong><br />`;

		if (Array.isArray(rejection.expected)) {
			return result + rejection.expected.join("<br />")
		} else {
			return result + rejection.expected;
		}
	});

	if (errorStrs.length) {
		return errorStrs.join("<br /><br />");
	}

	return undefined;
}

/**
 *
 * Modifies the target array and attaches field rejections from the error message. If the error message is
 * blank, undefined, or contains no rejections, the target array will be cleared
 *
 * @param {ErrorMessage} err
 * @param {FieldRejection[]} targetArray The array that should contain the field rejections (if any)
 */
export const handleValidationErrors = (err: ErrorMessage, targetForm: UntypedFormGroup, targetArray: FieldRejection[]): void => {
	const signature = "handleValidationErrors: ";
	logger.silly("Handling Validation Errors");
	logger.silly(err);

	// Always clean the validation error array when processing a new error set
	targetArray.splice(0, targetArray.length);

	if (err.rejectedFields && err.rejectedFields.length) {
		/** We must be careful to not change the array as the validation relies on it */
		targetArray.push(...err.rejectedFields);
	}

	// Refresh the field validation
	Object.keys(targetForm.controls).forEach(field => targetForm.controls[field].updateValueAndValidity());
}
