import { Component, Renderer2, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from '../../services/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { hasKey } from '../../util/object.util';
import { isString, sortByCreatedAt } from '../../util/utils';
import { ErrorMessage } from 'aws-sdk/clients/cloudformation';
import { LocationService } from '../../services/location.service';
import { ILocation } from '../../model/location.model';
import { NotificationsService } from 'angular2-notifications';
import { BEHAVIOUR_DATA, CLIENT_ACCESS, SYMPTOMS_DATA } from '../../util/drop_down.helper';
import { DiversionCentreService } from '../../services/diversion-centre.service';
import { IContact } from '../../model/contact.model';
import { ContactService } from '../../services/contact.service';
import { logger } from '../../util/Logger';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { isGoogleMapAvailable } from '../../util/google.util';
import { getAllFormErrors } from '../../util/formUtils';

declare var google;
declare var $;

const className = "CommunityPatrolEditComponent";

@Component({
	selector: 'app-diversion-centre-edit',
	templateUrl: './diversion-centre-edit.component.html',
	styleUrls: ['./diversion-centre-edit.component.scss']
})
export class DiversionCentreEditComponent implements OnInit {
	public tZone: string = environment.timeZone;

	public inD: boolean = false;
	public isFormSubmit: boolean = false;
	public canViolence: boolean = false;
	public isCheckout: boolean = false;
	public isBulkCheckin: boolean = false;
	id: string | null = null;
	diversionId: string | null = null;
	BulkDiversionId: string[] | null = null;
	diversionForm: FormGroup;
	currentClient: { firstName: string, lastName: string, phone: string, dob: string, address: string };
	locationList: ILocation[] = [];
	contactList: IContact[] = [];
	accessCommentList: { label: string, value: string, name: string }[] = CLIENT_ACCESS;
	symptomsList: ({ label: string; value: string; selected: boolean; disabled: boolean; } | { label: string; value: number; selected?: undefined; disabled?: undefined; })[] = SYMPTOMS_DATA;
	behaviourList: ({
		label: string;
		value: string;
		selected: boolean;
		disabled: boolean;
	} | {
		label: string;
		value: number;
		selected?: undefined;
		disabled?: undefined;
	})[] = BEHAVIOUR_DATA;
	@ViewChild('Domestic') div: ElementRef;
	@ViewChild('ReferralModal') ReferralModal: ElementRef;
	@ViewChild('addresstext') addresstext: ElementRef;

	constructor(
		private renderer: Renderer2,
		private formBuilder: FormBuilder,
		private clientService: ClientService,
		private __locationService: LocationService,
		private __contactService: ContactService,
		private __diversionCentreService: DiversionCentreService,
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private _notifications: NotificationsService,
	) {
		this.diversionForm = this.formBuilder.group({
			locationId: [null, [Validators.required]],
			timeIn: [new Date(), []],
			accessComment: [null, [Validators.required]],
			isAlter: ['false', []],
			alterComment: [{ value: null, disabled: true }, []],
			isBelonging: ['false', []],
			belongingComment: [{ value: null, disabled: true }, []],
			isViolence: ['false', []],
			domesiticViolances: this.formBuilder.array([
				this.formBuilder.group({
					violancerName: [{ value: null, disabled: true }, []],
				})
			]),
			isMedical: ['false', []],
			isAmbulance: ['false', []],
			isOnMedication: ['false', []],
			medicationName: [{ value: null, disabled: true }, []],
			isArrangements: ['0', []],
			medicationDate: [null, []],
			medicationTime: [null, []],
			isAggression: ['false', []],
			medicalObservations: this.formBuilder.array([]),
			wellBeingObservations: this.formBuilder.array([]),
			clientObservationForms: this.formBuilder.array([
				this.formBuilder.group({
					clientCheck: [{
						value: new Date().toISOString(),
						disabled: false,
					}, []],
					clientAwake: ['1', []],
					clientStatusChange: ['1', []],
					statusComments: ['', []],
					id: ['', []]
				})
			]),
			clientBackgroundInformations: this.formBuilder.array([
				this.formBuilder.group({
					information: [null, []],
				})
			]),
			isReferral: ['false', []],
			estimatedTime: [null, []],
			isBelongingTaken: ['1', []],
			isLeave: ['1', []],
			leaveComment1: [null, []],
			leaveComment2: [null, []],
			thingsToConsider: ['false', []],
			thingsToConsider2: ['false', []],
			thingsToConsider3: ['false', []],
			agreeFor: ['false', []],
			isReferralSupport: ['false', []],
			referrelId: [null, []],
			agreeForDetail3: [null, []],
			checkoutAt: [null, []],
			isCheckout: ['false', []],
		});

		this.id = this.route.snapshot.paramMap.get('id');
		this.diversionId = this.route.snapshot.queryParamMap.get('diversionId') || '';
		this.BulkDiversionId = this.route.snapshot.queryParamMap.get('diversionnId')?.split('/') || null;

		if (this.BulkDiversionId) {
			this.isCheckout = true;
			this.isBulkCheckin = true;

			this.diversionForm.controls['locationId'].setValidators([]);
			this.diversionForm.controls['accessComment'].setValidators([]);
		} else {
			this.route.params.subscribe({
				next: params => {
					if (hasKey(params, 'id') && isString(params.id)) {
						this.id = params.id;

						//#region for edit diversion data
						this.clientService.clientSearchListing({ page: 1, limit: 10, search: '', listType: 'diversion', mainId: this.diversionId, clientId: params.id }).subscribe({
							next: divData => {
								if (divData.data.rows.length > 0) {
									// TODO: Remove typecast
									let currentDiversion = divData.data.rows.shift() as Record<string, unknown>;
									// TODO: Remove typecast
									this.currentClient = currentDiversion as typeof this["currentClient"];

									if (currentDiversion.diversionCenters && Array.isArray(currentDiversion.diversionCenters) && currentDiversion.diversionCenters.length) {
										setTimeout(() => {
											this.getPlaceAutocomplete();
										}, 1000);
										let diversionCenters = currentDiversion.diversionCenters.shift();
										this.isCheckout = true;
										this.diversionId = diversionCenters.id || '';

										for (let index = 1; index < diversionCenters.medicalObservations.length; index++) {
											this.addMedicalObservations();
										}

										for (let index = 0; index < diversionCenters.wellBeingObservations.length; index++) {
											this.addWellBeingObservations();
										}

										sortByCreatedAt(diversionCenters.clientObservationForms);

										for (let index = 0; index < diversionCenters.clientObservationForms.length; index++) {
											if (index == 0) {
												diversionCenters.clientObservationForms[0].clientCheck = new Date(diversionCenters.clientObservationForms[0].clientCheck).toISOString();
											} else {
												diversionCenters.clientObservationForms[index].clientCheck = new Date(diversionCenters.clientObservationForms[index].clientCheck).toISOString();
												this.addclientObservationForms();
											}
										}
										for (let index = 1; index < diversionCenters.clientBackgroundInformations.length; index++) {
											this.addClientBackgroundInformations();
										}

										this.diversionForm.patchValue({
											isViolence: String(diversionCenters.isViolence),
										});
										for (let index = 1; index < diversionCenters.domesiticViolances.length; index++) {
											this.addDomesiticViolance();
										}

										this.diversionForm.reset({
											locationId: diversionCenters.locationId,
											timeIn: diversionCenters.timeIn || new Date(),
											accessComment: diversionCenters.accessComment,
											isAlter: String(diversionCenters.isAlter),
											alterComment: { value: diversionCenters.alterComment, disabled: !diversionCenters.isAlter },
											isBelonging: String(diversionCenters.isBelonging),
											belongingComment: { value: diversionCenters.belongingComment, disabled: !diversionCenters.isBelonging },
											isViolence: String(diversionCenters.isViolence),
											isMedical: String(diversionCenters.isMedical),
											isAmbulance: String(diversionCenters.isAmbulance || 'false'),
											isOnMedication: String(diversionCenters.isOnMedication || 'false'),
											medicationName: { value: diversionCenters.medicationName, disabled: !diversionCenters.isOnMedication },
											isArrangements: String(diversionCenters.isArrangements || '0'),
											medicationDate: diversionCenters.medicationDate,
											medicationTime: diversionCenters.medicationTime,
											isAggression: String(diversionCenters.isAggression),
											medicalObservations: diversionCenters.medicalObservations,
											wellBeingObservations: diversionCenters.wellBeingObservations,
											clientObservationForms: diversionCenters.clientObservationForms,
											clientBackgroundInformations: diversionCenters.clientBackgroundInformations,
											domesiticViolances: diversionCenters.domesiticViolances,
											isReferral: String(diversionCenters.isReferral),
											estimatedTime: diversionCenters.estimatedTime,
											isBelongingTaken: String(diversionCenters.isBelongingTaken),
											isLeave: String(diversionCenters.isLeave),
											leaveComment1: { value: diversionCenters.leaveComment1, disabled: diversionCenters.isLeave != 1 },
											leaveComment2: { value: diversionCenters.leaveComment2, disabled: diversionCenters.isLeave != 4 },
											thingsToConsider: String(diversionCenters.thingsToConsider),
											thingsToConsider2: String(diversionCenters.thingsToConsider2),
											thingsToConsider3: String(diversionCenters.thingsToConsider3),
											agreeFor: String(diversionCenters.agreeFor),
											isReferralSupport: String(diversionCenters.isReferralSupport),
											referrelId: diversionCenters.referrelId,
											agreeForDetail3: diversionCenters.agreeForDetail3,
											checkoutAt: null,
											isCheckout: 'false'
										});
										this.diversionForm.updateValueAndValidity();
									}
									// this._notifications.success("Done", divData.message || "", {});
								} else {
									this._notifications.warn("ERROR", divData.message || "", {});
								}
							}
						})
						//#endregion

					}
				}
			});
		}
	}

	ngOnInit(): void {
		this.getLocations();
		this.getContactList();
	}

	getLocations() {
		this.__locationService.list({ filter: { isDiversion: true }, limit: 1000 }).subscribe(async (response: any) => {
			if (response) {
				this.locationList = response.rows;
			} else {
				this._notifications.warn("ERROR", response.message || "", {});
			}
		}, err => {
			console.error(err);
			this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
		})
	}

	getContactList() {
		const signature = className + ".getContactList: ";
		logger.silly(signature + 'Started');

		this.__contactService.list({ filter: {} }).subscribe(async (response: any) => {
			if (response) {
				this.contactList = response.rows;
			} else {
				this._notifications.warn("ERROR", response.message || "", {});
			}
		}, err => {
			console.error(err);
			this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
		})
	}

	//#region alterComment
	changeIsAlter(evt: Event | null) {
		this.diversionForm.controls['alterComment'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.diversionForm.controls['alterComment'].enable();
			this.diversionForm.controls['alterComment'].setValidators([Validators.required]);
		} else {
			this.diversionForm.controls['alterComment'].disable();
			this.diversionForm.controls['alterComment'].setValidators([]);
		}
		this.diversionForm.controls['alterComment'].updateValueAndValidity();
		this.diversionForm.updateValueAndValidity();
	}

	get altercommentRequired() {
		return this.diversionForm.controls['alterComment'].errors && this.diversionForm.controls['alterComment'].errors.required;
	}
	//#endregion

	//#region belongingComment
	changeIsBelonging(evt: Event | null) {
		this.diversionForm.controls['belongingComment'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.diversionForm.controls['belongingComment'].enable();
			this.diversionForm.controls['belongingComment'].setValidators([Validators.required]);
		} else {
			this.diversionForm.controls['belongingComment'].disable();
			this.diversionForm.controls['belongingComment'].setValidators([]);
		}
		this.diversionForm.controls['belongingComment'].updateValueAndValidity();
		this.diversionForm.updateValueAndValidity();
	}

	get belongingCommentRequired() {
		return this.diversionForm.controls['belongingComment'].errors && this.diversionForm.controls['belongingComment'].errors.required;
	}
	//#endregion

	//#region domesiticViolancesGroup
	domesiticViolances(): FormArray {
		return this.diversionForm.get("domesiticViolances") as FormArray
	}

	newDomesiticViolances(): FormGroup {
		return this.formBuilder.group({
			violancerName: ['', []],
		})
	}

	addDomesiticViolance() {
		if (this.diversionForm.controls['isViolence'].value == 'true') {
			this.domesiticViolances().push(this.newDomesiticViolances());
		}
	}

	removeDomesiticViolance(i: number) {
		if (this.diversionForm.controls['isViolence'].value == 'true') {
			this.domesiticViolances().removeAt(i);
		}
	}
	//#endregion

	//#region violancerName
	changeIsViolence(evt: Event | null) {
		let dd: any = this.diversionForm.controls['domesiticViolances'];
		if (!dd.controls.length) {
			this.diversionForm.updateValueAndValidity();
			return;
		}
		let firstViolancerName = dd.controls[0].controls['violancerName'];

		firstViolancerName.setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			// this.canViolence = true;
			firstViolancerName.enable();
			firstViolancerName.setValidators([Validators.required]);
		} else {
			let i = (this.domesiticViolances().controls.length - 1);
			while (this.domesiticViolances().controls.length != 1) {
				this.removeDomesiticViolance(i);
				i--;
			}
			firstViolancerName.disable();
			firstViolancerName.setValidators([]);
			// this.canViolence = false;
		}
		firstViolancerName.updateValueAndValidity();
		this.diversionForm.updateValueAndValidity();
	}

	get violancerNameRequired() {
		let dd: any = this.diversionForm.controls['domesiticViolances'];
		let firstViolancerName = dd.controls[0].controls['violancerName'];

		return firstViolancerName.errors && firstViolancerName.errors.required;
	}
	//#endregion

	//#region medicationName
	changeIsOnMedication(evt: Event | null) {
		this.diversionForm.controls['medicationName'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.diversionForm.controls['medicationName'].enable();
			this.diversionForm.controls['medicationName'].setValidators([Validators.required]);
		} else {
			this.diversionForm.controls['medicationName'].disable();
			this.diversionForm.controls['medicationName'].setValidators([]);
		}
		this.diversionForm.controls['medicationName'].updateValueAndValidity();
		this.diversionForm.updateValueAndValidity();
	}

	get medicationNameRequired() {
		return this.diversionForm.controls['medicationName'].errors && this.diversionForm.controls['medicationName'].errors.required;
	}
	//#endregion

	//#region medicalObservationsGroup
	medicalObservations(): FormArray {
		return this.diversionForm.get("medicalObservations") as FormArray
	}

	newMedicalObservations(): FormGroup {
		return this.formBuilder.group({
			symptoms: ['', []],
		})
	}

	addMedicalObservations() {
		this.medicalObservations().push(this.newMedicalObservations());
	}

	removeMedicalObservations(i: number) {
		this.medicalObservations().removeAt(i);
	}
	symptomsSelected(itm: unknown) {
		/* let cunt = itm.target.value;
		for (let itm of this.symptomsList) {
			if (itm.value == cunt) {
				itm.visible = false;
			}
		} */
	}
	//#endregion

	//#region wellBeingObservationsGroup
	wellBeingObservations(): FormArray {
		return this.diversionForm.get("wellBeingObservations") as FormArray
	}

	newWellBeingObservations(): FormGroup {
		return this.formBuilder.group({
			behaviour: ['', []],
		})
	}

	addWellBeingObservations() {
		this.wellBeingObservations().push(this.newWellBeingObservations());
	}

	removeWellBeingObservations(i: number) {
		this.wellBeingObservations().removeAt(i);
	}
	//#endregion

	//#region clientObservationFormsGroup
	clientObservationForms() {
		return this.diversionForm.get("clientObservationForms") as FormArray<ReturnType<DiversionCentreEditComponent["newclientObservationForms"]>>;
	}

	newclientObservationForms() {
		return this.formBuilder.group({
			clientCheck: [{ value: new Date().toISOString(), disabled: false }, []],
			clientAwake: ['1', []],
			clientStatusChange: ['1', []],
			statusComments: ['', []],
			id: ['', []]
		})
	}

	addclientObservationForms() {
		this.clientObservationForms().push(this.newclientObservationForms());
	}

	removeclientObservationForms(i: number) {
		this.clientObservationForms().removeAt(i);
	}
	//#endregion

	//#region clientBackgroundInformationsGroup
	clientBackgroundInformations(): FormArray {
		return this.diversionForm.get("clientBackgroundInformations") as FormArray
	}

	newClientBackgroundInformations(): FormGroup {
		return this.formBuilder.group({
			information: [null, []],
		})
	}

	addClientBackgroundInformations() {
		this.clientBackgroundInformations().push(this.newClientBackgroundInformations());
	}

	removeClientBackgroundInformations(i: number) {
		this.clientBackgroundInformations().removeAt(i);
	}
	//#endregion

	changeReferral(evt: Event | null) {
		this.diversionForm.controls['referrelId'].setValue(null);
		this.diversionForm.controls['agreeForDetail3'].setValue(null);
		this.diversionForm.controls['thingsToConsider'].setValue('false');
		this.diversionForm.controls['thingsToConsider2'].setValue('false');
		this.diversionForm.controls['thingsToConsider3'].setValue('false');
		this.diversionForm.controls['agreeFor'].setValue('false');
		this.diversionForm.controls['isReferralSupport'].setValue('false');

		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.diversionForm.controls['thingsToConsider'].setValidators([Validators.required]);
			this.diversionForm.controls['thingsToConsider2'].setValidators([Validators.required]);
			this.diversionForm.controls['thingsToConsider3'].setValidators([Validators.required]);
			this.modalService.open(this.ReferralModal, { scrollable: true, centered: true, size: 'lg' });
		} else {

		}
		this.diversionForm.updateValueAndValidity();
	}

	changeLeave() {
		this.diversionForm.controls['leaveComment1'].setValue(null);
		this.diversionForm.controls['leaveComment2'].setValue(null);
		const leaveValue = this.diversionForm.controls['isLeave'].value;
		if (leaveValue === "1") {
			this.diversionForm.controls['leaveComment1'].enable();
			this.diversionForm.controls['leaveComment2'].disable();
		} else if (leaveValue === "4") {
			this.diversionForm.controls['leaveComment1'].disable();
			this.diversionForm.controls['leaveComment2'].enable();
		} else {
			this.diversionForm.controls['leaveComment1'].disable();
			this.diversionForm.controls['leaveComment2'].disable();
		}
		this.diversionForm.updateValueAndValidity();
	}

	saveDiversionCentre(formData: FormGroup) {
		this.isFormSubmit = true;

		if (!this.diversionForm.valid) {
			logger.error(getAllFormErrors(this.diversionForm));
			return;
		}

		let saveMethod: Observable<object>, formValue: object;
		if (this.BulkDiversionId) {
			formValue = Object.assign(formData.value, { clientId: this.id?.split('/') });

			// Ensure we're only saving checkout, otherwise this would override other data the diversion
			const bulkCheckoutData = [
				'clientId',
				'checkoutAt',
				'isCheckout',

				'isReferral',
				'estimatedTime',
				'isBelongingTaken',
				'isLeave',
				'leaveComment1',
				'leaveComment2',

				// Referral Details
				'thingsToConsider',
				'thingsToConsider2',
				'thingsToConsider3',
				'agreeFor',
				'isReferralSupport',
				'referrelId',
				'agreeForDetail3'
			].reduce((payload, key) => {
				payload[key] = formValue[key];
				return payload
			}, {} as Record<string, unknown>);

			saveMethod = this.__diversionCentreService.diversionCentreApi.updateBulkDiversionCentre(this.BulkDiversionId, bulkCheckoutData)
		} else {
			formValue = Object.assign(formData.value, { clientId: this.id });
			saveMethod = this.diversionId ?
				this.__diversionCentreService.diversionCentreApi.updateDiversionCentre(this.diversionId, formValue) : this.__diversionCentreService.diversionCentreApi.createDiversionCentre(formValue);
		}
		saveMethod.subscribe({
			next: () => {
				this.router.navigate(['/diversion-centre']);
			},
			error: (err: ErrorMessage) => { },
			complete: () => this.diversionForm.markAsUntouched()
		});
	}

	diversionCheckout() {
		this.diversionForm.controls['checkoutAt'].setValue(new Date());
		this.diversionForm.controls['isCheckout'].setValue('true');
		this.saveDiversionCentre(this.diversionForm);
	}

	private getPlaceAutocomplete() {
		const signature = className + '.getPlaceAutocomplete:';

		if (!isGoogleMapAvailable()) {
			logger.error(signature + `API for GoogleAutoComplete not currently available`);
			return;
		}

		const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
			{
				componentRestrictions: { country: 'AU' }
			});
		google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const place = autocomplete.getPlace();
			this.diversionForm.controls['leaveComment1'].setValue(`${place.name}, ${place.formatted_address}`);
		});
	}

	thingsToConsiderRequired(name: string) {
		return this.diversionForm.controls[name].errors && this.diversionForm.controls[name].errors?.required;
	}

	addDomestic() {
		const div: HTMLParagraphElement = this.renderer.createElement('div');
		div.innerHTML = "<div class='form-check mb-2'><div class='row g-3 align-items-center'><div class='col-xxl-6 col-xl-5 col-lg-7 col-md-6'><input type='text' class='form-control' placeholder='Contact Name'></div><div class='col-xxl-6 col-xl-7 col-lg-5 col-md-6 text-end'><a  (click)='domesticCloseFunction()'> <svg class='close-svg ms-2' focusable='false' viewBox='0 0 24 24' aria-hidden='true'><path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'></path></svg></a></div></div> </div>"
		this.renderer.appendChild(this.div.nativeElement, div)
	}
	domesticCloseFunction() { }

	@ViewChild('Notice') div1: ElementRef;

	addNotice() {
		const div: HTMLParagraphElement = this.renderer.createElement('div');
		div.innerHTML = "<div class='row g-3 g-xl-4 align-items-center justify-content-between mb-2'><div class='col-md-6'><select aria-label='Default select example' class='form-select'><option>Male</option><option>Female</option></select></div><div class='col-md-auto'><a> <svg class='close-svg ms-2' focusable='false' viewBox='0 0 24 24' aria-hidden='true'><path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'></path></svg></a></div></div>"
		this.renderer.appendChild(this.div1.nativeElement, div)
	}

	@ViewChild('BackgroundInfo') div2: ElementRef;

	addBackgroundInfo() {
		const div: HTMLParagraphElement = this.renderer.createElement('div');
		div.innerHTML = "<div class='row g-3 g-xl-4 align-items-center mb-2'><div class='col-lg-8 col-md-7'><textarea class='form-control' rows='2'></textarea></div><div class='col-md-auto ms-auto'><a href='#'> <svg class='close-svg ms-2' focusable='false' viewBox='0 0 24 24' aria-hidden='true'> <path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'></path></svg></a></div></div>"
		this.renderer.appendChild(this.div2.nativeElement, div)
	}

	@ViewChild('Observation') tr: ElementRef;

	addObservation() {
		const tr: HTMLParagraphElement = this.renderer.createElement('tr');
		tr.innerHTML = "<td> 23/04/23 17:13 </td><td><select class='form-select'><option>Awake</option><option>Sleep</option></select></td><td><select class='form-select'><option>Yes</option><option>No</option></select></td><td><input type='text' class='form-control' placeholder='Status Comments Here...'></td><td><a href='#' style='opacity: 0;'> <svg class='close-svg ms-2' focusable='false' viewBox='0 0 24 24' aria-hidden='true'><path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'></path></svg></a></td>"
		this.renderer.appendChild(this.tr.nativeElement, tr)
	}

	closeResult: string;

	// constructor(private modalService: NgbModal) { }

	openReferral(content) {
		this.modalService.open(content, { scrollable: true, centered: true, size: 'lg' });
	}
}
