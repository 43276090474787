import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { NotificationsService } from "angular2-notifications";
import { AdminApi } from "../api/admin.api";
import { IUser } from "../model/user.model";
import { BehaviorSubject, Observable, of, switchMap, tap } from "rxjs";
import { JwtService } from "./jwt.service";
import * as Sentry from "@sentry/angular-ivy";
import { logger } from "../util/Logger";
import { apiCallWrapper } from "../api/api.util";

const className = "AdminService";

@Injectable()
export class AdminService extends GenericService<IUser> {
  private userSubject: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);
  user$: Observable<IUser | null> = this.userSubject.asObservable();

  constructor(
    public adminApi: AdminApi,
    public notifications: NotificationsService,
    public jwtService: JwtService
  ) {
    super(adminApi, notifications);
    this.monitorJwtData();
  }

  setUser(user: IUser | null): void {
    this.userSubject.next(user);
  }

  /**
    * Monitors JWT data.
    * @description Subscribes to `knownJWTData$` observable and sets user information in Sentry scope when new data is available.
    */
  private monitorJwtData(): void {
    const signature = className + '.monitorJwtData: ';

    logger.silly(signature + 'Start');
    this.jwtService.currentJwtPayload$.pipe(
      switchMap(data => {
        if (!data) {
          logger.silly(signature + 'Clearing user cache');
          Sentry.configureScope(scope => {
            scope.setUser(null);
          });

          return of(null);
        }

        logger.silly(signature + 'Setting current user');
        Sentry.configureScope(scope => {
          scope.setUser({
            username: data.user.email,
            email: data.user.email,
            id: data.user.id
          });
        });

        return this.getAuthenticatedUser(false);
      })
    )
      .subscribe();
  }

  public getAuthenticatedUser(enableCache: boolean = true): Observable<IUser | null> {
    const signature = className + `.getAuthenticatedUser: enableCache[${enableCache}] `;
    logger.silly(signature + 'Start');
    if (enableCache) {
      const currentUser = this.userSubject.value;

      if (currentUser) {
        logger.silly(signature + 'Returning cached user');
        return of(currentUser);
      }
    }

    const token = this.jwtService.currentJwtPayload$.getValue();

    if (!token) {
      logger.silly(signature + `No Authentication Token Available`);
      return of(null);
    }

    return this.get(token.user.id).pipe(
      tap(usr => {
        if (usr) {
          this.setUser(usr);
        }
      })
    );
  }

  public restoreUser(id: string) {
    return apiCallWrapper(
      this.adminApi.restoreUser({ id }),
      {
        notificationsService: this.notifications,
        action: `User Restore`
      }
    );
  }

  public prepareNextviewSSO() {
    const signature = className + `.prepareNextviewSSO: `;
    logger.silly(signature + 'Start');
    return this.adminApi.prepareNextviewSSO();

  }
}
