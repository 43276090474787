<div class="form-content-wrapper">
    <div class="theme-box mb-4 mb-md-5 pb-4">
    <div class="mb-4">
        <h4 *ngIf="id">Edit Contact</h4>
        <h4 *ngIf="!id">Add New Contact</h4>
    </div>

    <form [formGroup]="contactForm">
        <div class="form-theme pb-4">
            <div class="row g-2 g-xl-3 align-items-start">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label>First Name</label>
                        <div class="textarea-wrapper">
                            <input #firstNameTb type="text" class="form-control" formControlName="firstName"
                                [ngClass]="{ 'is-invalid': contactForm.controls.firstName.errors }" maxlength="250"
                                (input)="changeVal($event)">
                                
                            <div *ngIf="contactForm.controls.firstName.errors" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.firstName.errors.required">
                                    Firstname is required
                                </div>
                            </div>
                            <span class="remaning">{{firstNameTb.value.length}}/250</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                       <label>Last Name</label>
                        <div class="textarea-wrapper">
                            <input #lastNameTb type="text" class="form-control" formControlName="lastName"
                                [ngClass]="{ 'is-invalid': contactForm.controls.lastName.errors }" maxlength="250">  
                            <div *ngIf="contactForm.controls.lastName.errors" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.lastName.errors.maxlength">
                                    Lastname not greater then 250 words
                                </div>
                            </div>
                            <span class="remaning">{{firstNameTb.value.length}}/250</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label>Organization Name</label>
                        <div class="textarea-wrapper">
                            <input #organizationNameTb type="text" class="form-control"
                                formControlName="organizationName"
                                [ngClass]="{ 'is-invalid': contactForm.controls.organizationName.errors }"
                                maxlength="250" (input)="changeVal($event)">
                          
                            <div *ngIf="contactForm.controls.organizationName.errors" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.organizationName.errors.required">
                                    Organization name is required
                                </div>
                            </div>
                            <span class="remaning">{{firstNameTb.value.length}}/250</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label>Location</label>
                        <div class="textarea-wrapper">
                            <select #locationDd class="form-select" aria-label="Default select example"
                                formControlName="location"
                                [ngClass]="{ 'is-invalid': contactForm.controls.location.errors }" maxlength="250">
                                <option value="" disabled selected>Select Location</option>
                                <option *ngFor="let loc of locationList" value="{{loc.name}}">{{loc.name}}</option>
                            </select>
                            
                            <div *ngIf="contactForm.controls.location.errors" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.location.errors.required">
                                    Location is required
                                </div>
                            </div>
                            <span class="remaning">{{firstNameTb.value.length}}/250</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label>Address</label>
                        <div class="textarea-wrapper">
                            <input #addressTb type="text" class="form-control" formControlName="address"
                                [ngClass]="{ 'is-invalid': contactForm.controls.address.errors }" maxlength="250">
                              
                            <div *ngIf="contactForm.controls.address.errors" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.address.errors.maxlength">
                                    Address not greater then 250 words
                                </div>
                            </div>
                            <span class="remaning">{{firstNameTb.value.length}}/250</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label>Phone No</label>
                        <div class="textarea-wrapper">
                            <input #phoneTb type="text" class="form-control" formControlName="phone"
                                [ngClass]="{ 'is-invalid': contactForm.controls.phone.errors }" maxlength="250"
                                (input)="formatContactNumber(phoneTb.value)">
                            
                            <div *ngIf="contactForm.controls.phone.errors" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.phone.errors.maxlength">
                                    Phoneno not greater then 250 words
                                </div>
                            </div>
                            <span class="remaning">{{phoneTb.value.length}}/250</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label>Category</label>
                        <div class="textarea-wrapper">
                            <select #categoryIdDd class="form-select" aria-label="Default select example"
                                formControlName="categoryId"
                                [ngClass]="{ 'is-invalid': contactForm.controls.categoryId.errors }" maxlength="250">
                                <option value="" selected disabled>Select Category</option>
                                <option *ngFor="let cat of categoryList" value="{{cat.id}}">{{cat.name}}</option>
                            </select>
                          
                            <div *ngIf="contactForm.controls.categoryId.errors" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.categoryId.errors.required">
                                    Category is required
                                </div>
                            </div>
                            <span class="remaning">{{phoneTb.value.length}}/250</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label>Mobile</label>
                        <div class="textarea-wrapper">
                            <input #mobileTb type="text" class="form-control" formControlName="mobile"
                                [ngClass]="{ 'is-invalid': contactForm.controls.mobile.errors }" maxlength="250">
                                                          
                                <div *ngIf="contactForm.controls.mobile.errors" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.mobile.errors.maxlength">
                                    Mobile not greater then 250 words
                                </div>
                            </div>
                            <span class="remaning">{{firstNameTb.value.length}}/250</span> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div *ngIf="id" class="row justify-content-end g-2 pe-5">
        <button class="btn btn-danger col-auto" (click)="deleteContact()">Archive</button>
        <button [disabled]="contactForm.invalid" class="btn btn-success col-auto ms-4" (click)="updateContact()">Update</button>
    </div>

<div *ngIf="!id" class="row justify-content-end g-2 pe-5">
    <button [disabled]="contactForm.invalid" class="btn btn-theme ms-auto col-auto" (click)="updateContact()">Save</button>
</div>

</div>
</div>