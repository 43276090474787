<!--component html goes here -->
<div class="container">
  <div class="row gy-5 gx-md-5 h-100">
    <div class="col-lg-6 col-md-5">
      <!--component html goes here -->
      <div class="d-flex align-items-center  h-100">
        <a [routerLink]="'/'">
          <img src="../../../../assets/images/logo.main.svg" class="logo" />
        </a>
      </div>
    </div>
    <div class="col-lg-6 col-md-7  d-flex align-items-center">
      <div class="box w-100 h-vh">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
  </div>
</div>