import { Component } from '@angular/core';
import { CellVisitService } from '../../services/cell-visit.service';
import { ConnectionServiceService } from '../../services/connection-service.service';
import { ClientService } from '../../services/client.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cell-visit-synced-list',
  templateUrl: './cell-visit-synced-list.component.html',
  styleUrls: []
})
export class CellVisitSyncedListComponent {
  public cellList = <any>[];
  public date = new Date();
  
  public modelData = <any>{};
  isOffline: boolean;
  private onlineOfflineSubscription: Subscription;

  constructor(
    public __cellVisitService: CellVisitService,
    public __conectionService: ConnectionServiceService,
    public __clientService: ClientService,
  ) {
    this.onlineOfflineSubscription = this.__conectionService
    .getOnlineOfflineStatus()
    .subscribe((status: boolean) => {
      this.isOffline = status;
      if(status == true){
        this.cellList =  __conectionService.getDataFromLocalStorage(true);
      }
    });

  }

  selectItemActive(id: string){
    const data = this.__conectionService.getDataFromLocalStorage(true);
    const index = data.findIndex(item => item.tempId === id);
    if (index !== -1) {
      data.splice(index, 1);
      localStorage.setItem('cellVisits', JSON.stringify(data));
      this.cellList =  data
    }
  }

}
