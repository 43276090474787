import { Component } from '@angular/core';
import { Router } from '@angular/router';

type ServiceTabState = 'list' | 'edit';

@Component({
  selector: 'app-diversion-centre',
  templateUrl: 'diversion-centre.component.html',
  styleUrls: ['diversion-centre.component.scss']
})
export class DiversionCentreComponent {
  public tabState: ServiceTabState = 'list';

  constructor(
    private __router: Router,
  ) { }

  selectClient(itm: any) {
    let { data } = itm;
    let diversionCentreId = (data.diversionCenters && data.diversionCenters.length > 0) ? data.diversionCenters.shift() : '';
    if (diversionCentreId) {
      diversionCentreId = diversionCentreId.id;
    }
    this.__router.navigate([`/diversion-centre/edit`, data.id], { queryParams: { diversionId: diversionCentreId } });
  }

}
