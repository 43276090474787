<div>
  <div class="theme-box p-0">
    <div class="table-responsive mb-30">
      <table
        class="table table-hover table-theme table-stripedTheme table-dashboard table-diversion-centre text-center">
        <thead class="thead-dark">
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Mobile</th>
            <th>DOB</th>
            <th>Date</th>
            <th>Time In</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dvs of cellList">
            <td>{{dvs.firstName}}</td>
            <td>{{dvs.lastName}}</td>
            <td>{{dvs.phone ? dvs.phone : 'N/A'}}</td>
            <td>{{dvs.dob ? dvs.dob : 'N/A'}}</td>
            <td>{{date | date:'YYYY-MM-dd'}}</td>
            <td>{{dvs.timeIn | date:'HH:mm'}}</td>
            <td (click)="selectItemActive(dvs.tempId)">
              <i class="fa-solid fa-trash text-danger"></i></td>
          </tr>
        </tbody>
      </table>
      <div class="has-text-centered">

      </div>
    </div>
  </div>
</div>
