<!--component html goes here -->
<div class="sidebar-scrollbar">
  <div class="sidebar-menu" [ngClass]="{ 'icon-only': menuState === 'close'}">
    <div *ngFor="let item of menuItems" class="d-flex justify-items-stretch flex-column">
      <a class="d-flex" [ngClass]="{ 'active': isActiveMenuItem(item['url'] || item['prefix'], true) }" role="button"
        [routerLink]="item.url" (click)="clickMenuItem(item)">
        <div class="d-flex align-items-center ">
          <svg class="icon" focusable="false" viewBox="0 0 24 24">
            <ng-container *ngFor="let item of item.icon">
              <path *ngIf="item.type === 'path'" [attr.d]="item.d"></path>
              <circle *ngIf="item.type === 'circle'" [attr.cx]="item.cx" [attr.cy]="item.cy" [attr.r]="item.r"></circle>
            </ng-container>
          </svg>
        </div>
        <div class="d-flex flex-grow-1 align-items-center">
          <span>{{item.label}}</span>
          <span class="sub-menu-indicator ms-auto" *ngIf="item['prefix']">
            <i class="fa fa-caret-down" [ngClass]="{ 'fa-rotate-180': item['open'] === true }"></i>
          </span>
        </div>
      </a>

      <div *ngIf="item.children" class="sub-menu d-flex flex-column justify-items-stretch"
        [ngClass]="{ expand: item['open'] === true }">
        <a *ngFor="let child of item.children" class="d-flex" [ngClass]="{ 'active': isActiveMenuItem(child['url']) }"
          role="button" [routerLink]="child.url">
          <div class=" d-flex align-items-center ">
            <svg class="icon" focusable="false" viewBox="0 0 24 24"></svg>
          </div>
          <div class="d-flex flex-grow-1 align-items-center ">
            {{child.label}}
          </div>
        </a>
      </div>
    </div>
  </div>
</div>