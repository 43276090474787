<div class="tab-theme">
  <app-tab-set #tabSet [tabs]="['Categories','Add New']" (newItemEvent)="clickedTab($event)"></app-tab-set>

  <div class="tab-content">
    <!--component html goes here -->
    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0 }">
      <div class="table-responsive">
        <table class="table table-hover table-theme table-stripedTheme table-dashboard text-center">
          <thead class="thead-dark">
            <tr>
              <th>Name</th>
              <th>Created at</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cat of categoryList | paginate: { 
              itemsPerPage: pageLimit, 
              currentPage: currentPage,
              totalItems :totalCount
          }">
              <td (click)="selectCat(cat)">
                {{cat.name}}</td>
              <td>
                {{cat.createdAt | date}}</td>
            </tr>
          </tbody>
        </table>
        <div class="has-text-centered">
          <app-pagination [currentPage]="currentPage" [pageSize]="pageLimit"
          [totalRows]="totalCount"
          (pageChange)="pageChanged($event)" 
          ></app-pagination>
        </div>
      </div>
    </div>

    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1 }">
      <div class="animated fadeIn">
        <div class="row">
          <div class="col-md-12">

            <form [formGroup]="categoryForm" class="form-inline">
              <div class="form-group">
                <input type="email" class="form-control mb-2 mr-sm-2" id="exampleInputEmail1"
                  placeholder="Types Category" autocomplete="Types Category" formControlName="name"
                  [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" required>
                <div *ngIf="submitted && f['name'].errors" class="invalid-feedback mb-2 mr-sm-2">
                  <div *ngIf="f['name'].errors.required">Category is required
                  </div>
                </div>
              </div>
             <div class="row justify-content-end mt-4">
              <button type="button" class="btn btn-danger col-auto ms-3" *ngIf="selectedCategory"
                (click)="deleteCategory()">Archive</button>
             </div>
              <button type="button" class="btn btn-success col-auto" (click)="createCategory()">Submit</button> 
            </form>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>