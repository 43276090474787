import { slideInAnimation } from "../util/animations";
import { Component } from "@angular/core";

const className = "ConsoleComponent";

@Component({
	template: `
		<section class="features-section-5 relative" id="contact">
			<div class="container">
				<div class="row new-row" style="margin-bottom:45px;">
					<div class="col-12 col-md-8 offset-md-2">
						<div class="form-outer background-light">
							<h2 class="text-center">NOT FOUND</h2>
							<h1 class="text-center font-weight-bold py-0">404</h1>
						</div>
					</div>
				</div>
			</div>
		</section>
	  `,
	animations: [slideInAnimation],
	styles: [`
	`]
})
export class Error404Component {
}
