<!--- ViewPort -->
<div class="vh-100 d-flex flex-column overflow-hidden">
	<div class="header">
		<app-header (menuStateChanged)="setMenuOpenState($event)"></app-header>
	</div>
	<!--- Body -->
	<div class="flex-grow-1 d-flex overflow-hidden">
		<!--- Menu -->
		<!-- <div class="overflow-y-auto sidebar-main"> -->
		<app-menu [menuState]="menuState" class="sidebar-main"></app-menu>
		<!-- </div> -->
		<!--- Content -->
		<div class="flex-grow-1 overflow-auto" #viewPort>
			<div class=" p-md-4 p-3 w-100">
				<router-outlet #outlet="outlet"></router-outlet>
			</div>
		</div>
	</div>
</div>
