import { environment } from "../../../environments/environment";
import { IQueryFilter } from '../model/query.filter.class';
import { HttpParams, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NotificationsService, Notification } from 'angular2-notifications';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorMessage } from '../model/api.model';
import { HeaderTokenEnum } from '../model/auth.model';
import { hasKey } from "../util/object.util";
import { logger } from "../util/Logger";

const className = "apiCallWrapper";

export const apiHost = environment.endpoint;
export const apiPrefix = '';

export const createAppUrl = (host: string, prefix: string) => (...params: Array<string | number>): string =>
	`${host}${prefix.length ? prefix + '/' : ''}/${params.join('/')}`;

export const createUrl = createAppUrl(apiHost, apiPrefix);

export const httpParams = () => new HttpParams();

export const queryToParams = (query: IQueryFilter) => (Object).entries(query).reduce(
	(params, [key, value]) =>
		typeof value === 'function' ? params : params.set(key, typeof value === 'object' ? JSON.stringify(value) : value),
	httpParams()
);

export const getPublicRoutesHeaders = () => {
	const headers = new HttpHeaders();

	return headers.append('tokenType', HeaderTokenEnum.NoToken);
};

let offlineNotification: Notification | null = null;
const enableSuccessNotifications = false;

export const apiCallWrapper = <D, N = unknown>(observable: Observable<N>, opts: {
	notificationsService: NotificationsService,
	action: string,
	title?: string,
	message?: string,
	successTitle?: string,
	failTitle?: string,
	successMessage?: string,
	defaultValue?: D
}): typeof observable => {
	const signature = className + ".apiCallWrapper: ";
	const options = Object.assign({},
		opts,
		{
			title: opts.action,
			successTitle: `${opts.action} complete`,
			failTitle: `${opts.action} failed`,
			message: '',
			successMessage: opts.message ? `${opts.message} completed` : ''
		}
	);

	let notification: Notification | null = null;
	if (enableSuccessNotifications) {
		notification = options.notificationsService.warn(options.title, options.message);
	}

	const removeExistingNotification = () => {
		if (notification) {
			options.notificationsService.remove(notification.id);
		}
	};

	// Sent to true when there was a gracefully handled error and the default value was returned.
	let didNotSucceed = false;

	// Ensure no offline notifications are being displayed if an offline state is not detected
	if (offlineNotification && window.navigator.onLine) {
		options.notificationsService.remove(offlineNotification.id);
		offlineNotification = null;
	}

	if (!window.navigator.onLine) {
		removeExistingNotification();

		// Set an offline notification if one doesn't already exist

		if (!offlineNotification || offlineNotification.destroyedOn) {
			offlineNotification = options.notificationsService.error(options.failTitle, "No internet connection available.", {
				timeOut: 10000,
				showProgressBar: true,
				pauseOnHover: true,
				clickToClose: true
			});

			if (offlineNotification.click) {
				offlineNotification.click.subscribe(() => {
					options.notificationsService.remove(offlineNotification!.id);
					offlineNotification = null;
				});
			}

			if (offlineNotification.timeoutEnd) {
				offlineNotification.timeoutEnd.subscribe(() => {
					options.notificationsService.remove(offlineNotification!.id);
					offlineNotification = null;
				});
			}
		}

		logger.silly(signature + `Ignoring API Request for offline connection`);

		const handledError = new ErrorMessage({ message: "Browser is offline", handled: true });
		return throwError(handledError);
	}

	return observable.pipe(
		catchError(err => {
			logger.silly(signature + 'Handling Error');

			removeExistingNotification();

			/**
			 * Despite the documentation, it is observed at this moment that the following returns false
			 * when true was expected
			 * has(err, ['error.message','error.code'])
			 *
			 * For this reason, _.has will be used twice, as this gives the expected outcome
			 */

			// Prevent duplicate handling of the error
			if (err instanceof ErrorMessage) {
				if (err.handled) {
					logger.silly(signature + 'Error has already been handled');
					return NEVER;
				}

				return throwError(err);
			}

			if (hasKey(err, 'error') && hasKey(err.error, 'message') && hasKey(err.error, 'statusCode')) {
				const error = new ErrorMessage().deserialize(err.error as Partial<ErrorMessage>);

				logger.silly(signature + `Encountered well defined Error[${err.error.message}] with StatusCode[${err.error.statusCode}]`);
				options.notificationsService.error(options.failTitle, error.message, {
					timeOut: 6000,
					showProgressBar: true,
					pauseOnHover: true,
					clickToClose: true
				});

				if (error.statusCode === 404 && opts.defaultValue) {
					logger.warn(signature + `Gracefully 404 Handled Error`);
					didNotSucceed = true;
					return of(options.defaultValue);
				}

				return throwError(error);
			}

			if (err instanceof HttpErrorResponse && err.status === 0) {
				logger.silly(signature + 'Server Communication Error');
				options.notificationsService.error(options.failTitle, "Error communicating with server", {
					timeOut: 6000,
					showProgressBar: true,
					pauseOnHover: true,
					clickToClose: true
				});

				const handledError = new ErrorMessage({ handled: true });

				return throwError(handledError);
			}

			logger.silly(signature + 'Unknown Error');
			options.notificationsService.error(options.failTitle, "Unknown Error has Occurred", {
				timeOut: 6000,
				showProgressBar: true,
				pauseOnHover: true,
				clickToClose: true
			});

			return throwError(new ErrorMessage());
		}),
		map(
			result => {
				removeExistingNotification();

				if (enableSuccessNotifications && !didNotSucceed) {
					options.notificationsService.success(options.successTitle, options.successMessage, {
						timeOut: 6000,
						showProgressBar: true,
						pauseOnHover: true,
						clickToClose: true
					});
				}

				return result as N;
			}
		)
	);
}
