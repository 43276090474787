// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { DataGridComponent } from './data-grid.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared.module'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    DataGridComponent
  ],
  exports: [
    DataGridComponent,
  ]
})
export class DataGridModule {}