<div class="bg-gray form-theme p-xl-4 p-md-3 p-2">
	<div class="row  g-2 d-md-3 g-xl-4">
		<div class="col-lg-3 col-sm-6"><label for="" class="m-0">Check In</label></div>
		<div class=" col-lg-3 col-sm-6">
			<p class="m-0">{{createdAt | date:'dd/MM/yy hh:mm a'}}</p>
		</div>
		<div class="col-lg-3 col-sm-6"><label for="" class="m-0">Check In By</label></div>
		<div class=" col-lg-3 col-sm-6">
			<p class="m-0" *ngIf="createdBy">{{createdBy}}</p>
			<p class="m-0" *ngIf="!createdBy">Unknown</p>
		</div>
	</div>
</div>
<div class="form-theme p-xl-4 p-md-3 p-2">
	<div class="row  g-2 d-md-3 g-xl-4">
		<div class="col-lg-3 col-sm-6"><label for="" class="m-0">Check out</label></div>
		<div class=" col-lg-3 col-sm-6">
			<p class="m-0" *ngIf="!checkoutAt">Still Checked In</p>
			<p class="m-0" *ngIf="checkoutAt">{{checkoutAt | date:'dd/MM/yy hh:mm a'}}</p>
		</div>
		<div class="col-lg-3 col-sm-6"><label for="">Check Out By</label></div>
		<div class=" col-lg-3 col-sm-6">
			<p class="m-0" *ngIf="checkoutAt && checkoutBy">{{checkoutBy}}</p>
			<p class="m-0" *ngIf="checkoutAt && !checkoutBy">Unknown</p>
		</div>
	</div>
</div>
