import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SimpleNotificationsModule } from "angular2-notifications";
import { ServiceWorkerModule } from "@angular/service-worker";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./auth/login/login.component";
import { MainTemplateComponent } from "./template/main-template/main-template.component";
import { AuthTemplateComponent } from "./template/auth-template/auth-template.component";
import { AuthService } from "./services/auth.service";
import { UtilService } from "./services/util.service";
import { ReportService } from "./services/report.service";
import { ReportApi } from "./api/report.api";
import { AuthApi } from "./api/auth.api";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonControllerComponent } from "./util/common.controller";
import { ResetComponent } from "./auth/reset.component";
import { JwtService } from "./services/jwt.service";
import { StorageService } from "./services/storage.service";
import { AuthGuard } from "./guard/auth.guard";
import { AdminGuard } from "./guard/admin.guard"
import { UnAuthGuard } from "./guard/unauth.guard";
import { ForgotComponent } from "./auth/forgot.component";
import { UiModule } from "../ui/ui.module";
import { JwtInterceptor } from "./interceptor/jwt.interceptor";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import * as Sentry from "@sentry/angular-ivy";
import { Angulartics2Module } from "angulartics2";
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppTemplateComponent } from "./template/app-template/app-template.component";
import { AppHeaderComponent } from "./template/app-header/app-header.component";
import { AppMenuComponent } from "./template/app-menu/app-menu.component";
import { DiversionCentreComponent } from "./diversion-centre/diversion-centre.component";
import { CellVisitComponent } from "./cell-visit/cell-visit.component";
import { CommunityPatrolComponent } from "./community-patrol/community-patrol.component";
import { LocationComponent } from "./location/location.component";
import { CategoriesComponent } from "./categories/categories.component";
import { PwaService } from "./services/pwa.service";
import { ReportComponent } from "./report/report.component";
import { DiversionCentreDashboardComponent } from "./diversion-centre-dashboard/diversion-centre-dashboard.component";
import { CellVisitDashboardComponent } from "./cell-visit-dashboard/cell-visit-dashboard.component";
import { CommunityPatrolDashboardComponent } from "./community-patrol-dashboard/community-patrol-dashboard.component";
import { DiversionCentreService } from "./services/diversion-centre.service";
import { DiversionCentreApi } from "./api/diversion-centre.api";
import { CellVisitService } from "./services/cell-visit.service";
import { CellVisitApi } from "./api/cell-visit.api";
import { CommunityPatrolService } from "./services/community-patrol.service";
import { CommunityPatrolApi } from "./api/community-patrol.api";
import { ClientService } from "./services/client.service";
import { ClientApi } from "./api/client.api";
import { ContactService } from "./services/contact.service";
import { ContactApi } from "./api/contact.api";
import { LocationService } from "./services/location.service";
import { LocationApi } from "./api/location.api";
import { CategoryService } from "./services/category.service";
import { CategoryApi } from "./api/category.api";
import { ClientListComponent } from "./client/client-list/client-list.component";
import { ClientEditComponent } from "./client/client-edit/client-edit.component";
import { NgxPaginationModule } from 'ngx-pagination';
import { AdminService } from "./services/admin.service";
import { AdminApi } from "./api/admin.api";
import { UserListComponent } from "./admin/user/user-list/user-list.component";
import { UserEditComponent } from "./admin/user/user-edit/user-edit.component";
import { RegisterComponent } from "./auth/register/register.component";
import { ContactComponent } from "./contact/contact.component";
import { ContactEditComponent } from "./contact/contact-edit/contact-edit.component";
import { ClientSearchComponent } from "./client/client-search/client-search.component";
import { ClientDiversionComponent } from "./client/client-diversion/client-diversion.component";
import { DummyComponent } from './dummy/dummy.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DiversionCentreEditComponent } from './diversion-centre/diversion-centre-edit/diversion-centre-edit.component';
import { DiversionCentreListComponent } from './diversion-centre/diversion-centre-list/diversion-centre-list.component';
import { CellVisitListComponent } from './cell-visit/cell-visit-list/cell-visit-list.component';
import { CellVisitEditComponent } from './cell-visit/cell-visit-edit/cell-visit-edit.component';
import { CommunityPatrolListComponent } from './community-patrol/community-patrol-list/community-patrol-list.component';
import { CommunityPatrolEditComponent } from './community-patrol/community-patrol-edit/community-patrol-edit.component';
import { ClientNoteService } from './services/client-note.service';
import { ClientNoteApi } from "./api/client-note.api";
import { MaxLengthIndicatorDirective } from "../ui/directive/MaxLengthIndicator";
import { FieldErrorsComponentComponent } from './field-errors-component/field-errors-component.component';
import { TwoFactorAuthComponent } from './auth/two-factor-auth/two-factor-auth.component';
import { ConnectionServiceService } from './services/connection-service.service';
import { CellVisitSyncListComponent } from './cell-visit/cell-visit-sync-list/cell-visit-sync-list.component';
import { CellVisitSyncedListComponent } from './cell-visit/cell-visit-synced-list/cell-visit-synced-list.component'
import { SharedModule } from "../shared.module";
import { FlagKeyComponent } from './template/flag-key/flag-key.component';
import { AdminToolsComponent } from './admin/admin-tools/admin-tools.component';
import { InactivityService } from './services/inactivity.service';
import { PinComponent } from './autoLock/pin/pin.component';
import { ServiceDataRowComponent } from './template/service-data-row/service-data-row.component';
import { ServiceCheckInOutRowComponent } from './template/service-check-in-out-row/service-check-in-out-row.component';
// import { MatDialogModule } from ""

@NgModule({
	declarations: [
		HomeComponent,
		LoginComponent,
		ResetComponent,
		ForgotComponent,
		DashboardComponent,
		DiversionCentreComponent,
		CellVisitComponent,
		CommunityPatrolComponent,
		LocationComponent,
		CategoriesComponent,
		ReportComponent,
		DiversionCentreDashboardComponent,
		CellVisitDashboardComponent,
		CommunityPatrolDashboardComponent,
		ClientListComponent,
		ClientEditComponent,
		ClientSearchComponent,
		ClientDiversionComponent,
		DummyComponent,
		AdminToolsComponent,

		/** Home Component Includes */

		//Templates
		CommonControllerComponent,
		MainTemplateComponent,
		AppTemplateComponent,
		AuthTemplateComponent,
		AppHeaderComponent,
		AppMenuComponent,
		UserListComponent,
		UserEditComponent,
		RegisterComponent,
		ContactComponent,
		ContactEditComponent,
		DiversionCentreEditComponent,
		DiversionCentreListComponent,
		CellVisitListComponent,
		CellVisitEditComponent,
		CommunityPatrolListComponent,
		CommunityPatrolEditComponent,
		MaxLengthIndicatorDirective,
		FieldErrorsComponentComponent,
		TwoFactorAuthComponent,
		CellVisitSyncListComponent,
		CellVisitSyncedListComponent,
		FlagKeyComponent,
		PinComponent,
		ServiceDataRowComponent,
		ServiceCheckInOutRowComponent,
	],
	imports: [
		BrowserModule,

		AppRoutingModule,
		HttpClientModule,
		FontAwesomeModule,

		/* Alerts - https://www.npmjs.com/package/angular2-notifications */
		BrowserAnimationsModule,
		SimpleNotificationsModule.forRoot(),

		// Google Analytics Support
		Angulartics2Module.forRoot(),

		/** App Modules */
		UiModule,
		SharedModule,

		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),

		/**  for pagination */
		NgxPaginationModule,
		NgbModule,
	],
	providers: [
		//Vendor
		CookieService,

		// Guards
		AuthGuard,
		AdminGuard,
		UnAuthGuard,

		// Spark Services
		AuthService,
		UtilService,
		JwtService,
		StorageService,
		PwaService,
		ConnectionServiceService,

		DiversionCentreService,
		DiversionCentreApi,

		CellVisitService,
		CellVisitApi,

		CommunityPatrolService,
		CommunityPatrolApi,

		ContactService,
		ContactApi,

		ClientService,
		ClientApi,


		ClientNoteService,
		ClientNoteApi,

		LocationService,
		LocationApi,

		CategoryService,
		CategoryApi,

		AdminService,
		AdminApi,

		ReportService,
		ReportApi,

		InactivityService,

		// Spark Apis
		AuthApi,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},

		/** Sentry */
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: true,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => { },
			deps: [Sentry.TraceService],
			multi: true,
		},
	],
	bootstrap: [MainTemplateComponent],
})
export class AppModule { }
