<!--component html goes here -->
<div class="d-flex flex-row align-items-center w-100 border-bottom header px-3 px-md-4">

  <div class="">
    <a [routerLink]="'/dashboard'">
      <img src="../../../assets/images/logo.main.svg" />
    </a>
  </div>
  <div class=" d-flex flex-column justify-content-center ms-5">
    <div role="button" class=" menu-button" (click)="openOrCloseMenu()">
      <i class="fa fa-bars" [ngClass]="{ 'fa-close': menuState === 'close' }"></i>
      <!-- <i class=" fa fa-bars" [ngClass]="{ 'fa-rotate-90': menuState === 'close' }"></i> -->
    </div>
  </div>
  <div class="ms-auto">
    <div class="btn-group">

      <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
        aria-expanded="false">
        <i class="fa-solid fa-circle-user fa-2x me-2"></i><span *ngIf="user">{{user.firstName+' '+user.lastName}}</span>
      </button>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
        <li><a class="dropdown-item" href="javascript:void(0)" (click)=selectUser(user.id)>Profile</a></li>
        <li><a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a></li>
      </ul>
    </div>
  </div>
