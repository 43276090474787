// CellVisits.isArrangements
export function getArrangementsValue(isArrangements: string | number) {
	const arrangementsInt = Number(isArrangements);

	if (isNaN(arrangementsInt)) {
		throw new Error(`ArrangementsData[${isArrangements}] must be a number`);
	}

	switch (arrangementsInt) {
		case 0:
			return 'No';
		case 1:
			return 'Unknown';
		case 2:
			return 'Yes';
		default:
			throw new Error(`ArrangementsData[${isArrangements}] is not known`);
	}
}

export function getLeaveCellText(isLeaveDiversion: string | number) {
	const leaveInt = Number(isLeaveDiversion);

	if (isNaN(leaveInt)) {
		throw new Error(`LeaveCellData[${leaveInt}] must be a number`);
	}

	switch (leaveInt) {
		case 1:
			return 'Transported to a safe place (please advise the location)';
		case 2:
			return 'Transported via Ambulance';
		case 3:
			return 'Released from custody';
		case 4:
			return 'Other (please provide details)';
		case 5:
			return 'Picked up by QPS';
		case 6:
			return 'Remanded in custody';
		default:
			throw new Error(`LeaveCellData[${isLeaveDiversion}] is not known`);
	}
}

export function getServiceRefusedByText(serviceRefusedBy: string | number) {
	if (serviceRefusedBy === null) {
		return 'By Client';
	}

	const refusedByInt = Number(serviceRefusedBy);

	if (isNaN(refusedByInt)) {
		throw new Error(`ServiceRefusedBy[${serviceRefusedBy}] must be a number`);
	}

	switch (serviceRefusedBy) {
		case 0:
			return 'By Client';
		case 1:
			return 'By MurriWatch';
		default:
			throw new Error(`ServiceRefusedBy[${serviceRefusedBy}] is not known`);
	}
}

export function yesOrNo(value: boolean) {
	return value ? 'Yes' : 'No';
}

export function getProperty(object: any, key: string, defaultVal: unknown = null) {
	return object[key] || defaultVal;
}
