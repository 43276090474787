import { AbstractControl, Validators } from "@angular/forms";

export function dobValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }

  if (formControl.parent.controls['isDob'].value || formControl.parent.controls['dob'].value) {
    return null;
  } else {
    return Validators.required(formControl);
  }
}