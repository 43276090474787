import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { environment } from 'src/environments/environment';
import { CellVisitService } from '../../services/cell-visit.service';
import Swal from 'sweetalert2';
import { logger } from '../../util/Logger';
import { ConnectionServiceService } from '../../services/connection-service.service';
import { Subscription } from 'rxjs';

const className = "CellVisitListComponent";


@Component({
  selector: 'app-cell-visit-list',
  templateUrl: './cell-visit-list.component.html',
  styleUrls: ['./cell-visit-list.component.sass']
})
export class CellVisitListComponent {
  public cellList = <any>[];
  isOffline: boolean;
  private onlineOfflineSubscription: Subscription;
  public date = new Date();

  constructor(
    private _notifications: NotificationsService,
    private __router: Router,
    public __cellVisitService: CellVisitService,
    public __conectionService: ConnectionServiceService,
    public route: ActivatedRoute
  ) {
    this.onlineOfflineSubscription = this.__conectionService
      .getOnlineOfflineStatus()
      .subscribe((status: boolean) => {
        this.isOffline = status;
        if (status == false) {
          this.cellList = this.__conectionService.getDataFromLocalStorage(false);
        } else {
          this.getCellCollection();
        }
      });
  }

  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  public pageLimitActive: number = environment.defaultCollectionLimit || 10;
  public searchActive: string = "";
  public clientIdArray: string[] = [];
  public cellListArray: string[] = [];

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.getCellCollection();
  }

  getCellCollection() {
    this.__cellVisitService.listingCellVisit({ page: this.currentPageActive, limit: this.pageLimitActive, search: this.searchActive, filter: {} }).subscribe({
      next: response => {
        this.cellList = response.data.rows;
        this.totalCountActive = response.data.count;
      },
      error: err => {
        console.error(err);
        this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
      }
    });
  }

  searching(searchText: string) {
    this.searchActive = searchText;
    this.getCellCollection();
  }

  /**
   * selectItemActive
   */
  public selectItemActive(data: { clientId: string, id: string }): void {
    this.__router.navigate([`/cell-visits/edit`, data.clientId], { queryParams: { cellId: data.id } });
  }

  addNew() {
    if (this.clientIdArray.length == 0) {
      Swal.fire('checkOut', 'please select minimum one client for checkOut !', 'error')
    } else {
      this.__router.navigate([`/cell-visits/edit`, this.clientIdArray.join('/')], { queryParams: { celllId: this.cellListArray.join('/') } });
    }
  }

  isClientSelected(clientId: string): boolean {
    return this.clientIdArray.includes(clientId);
  }

  /**
   * Adds or removes a clientId from the clientIdArray based on the provided parameters.
   *
   * @param {string} clientId - The ID of the client to add or remove.
   * @param {string} cellId - The ID of the cell to add or remove.
   * @param {boolean} include - Specifies whether to include or remove the clientId.
   * @returns {undefined} - This function does not return a value.
   *
   */

  selectedClient(clientId: string, include: boolean, cellId: string) {
    const signature = className + `.bulkCheckOut: ClientId[${clientId}] Include[${include}]] CellId[${cellId}] Include[${include}]]`;

    const clientIdIndex = this.clientIdArray.indexOf(clientId);
    const clientIdExists = clientIdIndex !== -1;
    const cellIdIndex = this.cellListArray.indexOf(cellId);
    const cellIdExist = cellIdIndex !== -1

    logger.silly(signature + 'Started');
    if (include && !clientIdExists && !cellIdExist) {
      this.clientIdArray.push(clientId);
      this.cellListArray.push(cellId);
    } else if (!include && clientIdExists && cellIdExist) {
      this.clientIdArray.splice(clientIdIndex, 1);
      this.cellListArray.splice(cellIdIndex, 1)
    }
  }

}
