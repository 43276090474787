import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectionServiceService } from '../services/connection-service.service';
import { Subscription } from 'rxjs';

type CellTabState = 'list' | 'edit' | 'sync' | 'synced';

@Component({
  selector: 'app-cell-visit',
  templateUrl: 'cell-visit.component.html',
  styleUrls: ['cell-visit.component.scss']
})
export class CellVisitComponent {
  public tabState: CellTabState = 'list';
  isOffline: boolean;
  private onlineOfflineSubscription: Subscription;

  constructor(
    private __router: Router,
    private __modalService: NgbModal,
    public __conectionService: ConnectionServiceService
  ) {
    this.onlineOfflineSubscription = this.__conectionService
    .getOnlineOfflineStatus()
    .subscribe((status: boolean) => {
      this.isOffline = status;
      // Call any function or perform any action based on online/offline status
    });
  }

  getTabs(): string[] {
    // Return different tabs based on the isOffline value
    return !this.isOffline ? ['Active', 'Add New'] : ['Active', 'Add New', 'Sync', 'Synced'];
  }

  selectClient(itm: any) {
    let { data } = itm;
    let cellId = (data.cellVisits && data.cellVisits.length > 0) ? data.cellVisits.shift() : '';
    if (cellId) {
      cellId = cellId.id;
    }
    this.__router.navigate([`/cell-visits/edit`, data.id], { queryParams: { cellId: cellId } });
  }

  openReferral(content) {
    this.__modalService.open(content, { scrollable: true, centered: true, size: 'lg' });
  }

}
