import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { NotificationsService } from "angular2-notifications";
import { DiversionCentreApi } from "../api/diversion-centre.api";
import { IDiversionCentre } from "../model/diversion-centre.model";
import { BehaviorSubject, Observable } from "rxjs";
import { apiCallWrapper } from "../api/api.util";

@Injectable()
export class DiversionCentreService extends GenericService<IDiversionCentre> {
  private applyFilter = new BehaviorSubject<string>('dashboard');
  constructor(
    public diversionCentreApi: DiversionCentreApi,
    public notifications: NotificationsService
  ) {
    super(diversionCentreApi, notifications);
  }


  setFilterCriteria(applyFilter: string) {
    this.applyFilter.next(applyFilter);
  }

  getApplyFilter(): Observable<string> {
    return this.applyFilter.asObservable();
  }

  listingDiversionCentre(query: {
    page: number;
    limit: number;
    search: string;
    filter: Record<string, unknown>
  }) {
    return apiCallWrapper(
      this.diversionCentreApi.listingDiversionCentre(query),
      {
        notificationsService: this.notifications,
        action: `Fetching Diversion Centre Records`
      }
    );
  }
}