import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { NotificationsService } from "angular2-notifications";
import { LocationApi } from "../api/location.api";
import { ILocation } from "../model/location.model";

@Injectable()
export class LocationService extends GenericService<ILocation> {

  public entityName: string = "Location";

  constructor(
    public locationApi: LocationApi,
    public notifications: NotificationsService
  ) {
    super(locationApi, notifications);
  }
}
