<!--component html goes here -->
<section class="features-section-5 relative" id="contact">

  <div class="form-outer login-box">

    <div class="app-section-header text-center mb-4 mb-md-5">
      <app-section-title [text]="text"></app-section-title>
    </div>

    <div class=" alert alert-danger" *ngIf="displayValidationErrors(fieldRejections)"
      [innerHTML]="displayValidationErrors(fieldRejections)"></div>

    <div class="clearfix"></div>

    <form [formGroup]="form" (ngSubmit)="authenticate()" class="">
      <div class="form-floating mb-30">
        <app-text-input field="email" label="Email Address" [form]="form" [errors]='{
            required: "Email Address Required",
            email: "Invalid Email Address",
            serverValidation: "Rejected by server"
          }'></app-text-input>
      </div>
      <div class="form-floating mb-30">
        <app-text-input field="password" label="Password" [form]="form" type="password" [errors]='{
            required: "Password required",
            serverValidation: "Rejected by server"
          }'></app-text-input>
      </div>

      <div class="mb-2">
        <button class="btn btn-theme send_mess w-100">Login</button>
      </div>
    </form>

    <div class="mb-4 mb-md-5">
      <button class="btn btn-sm btn-theme send_mess w-100" (click)="sendEmail()">Login with email (One Time
        Password)</button>
    </div>

    <div class="text-center">
      <a class="btn-link" routerLink="/forgot">Forget Password?</a>
    </div>
  </div>
</section>
