<!--component html goes here -->
<div class="form-theme p-xl-4 p-md-3 p-2" [class.bg-gray]="bg === 1">
	<div class="row g-2 d-md-3 g-xl-4" *ngIf="!wide">
		<div class="col-10 col-lg-6 text-xs-center text-md-left text-bold">{{question}}</div>
		<div class="col-2 col-lg-6 text-xs-center text-md-left text-lg-center">{{response}}</div>
	</div>
	<div class="row g-2 d-md-3 g-xl-4" *ngIf="wide">
		<div class="col-12 col-sm-10 offset-sm-1 col-lg-6 offset-lg-3 text-center">{{question}}</div>
		<div class="col-12 col-sm-10 offset-sm-1 col-lg-6 offset-lg-3 text-center" *ngIf="response">{{response}}</div>
	</div>
	<div class="row" *ngIf="showDetail">
		<div class="col-12 offset-0 col-sm-10 offset-sm-1 col-lg-6 offset-lg-3">
			<div class="p-2 my-2 border rounded" *ngIf="detail">{{detail}}</div>
			<ng-content></ng-content>
		</div>
	</div>
</div>
