import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-cell-visit-dashboard',
    templateUrl: 'cell-visit-dashboard.component.html',
    styleUrls: ['cell-visit-dashboard.component.scss']
})
export class CellVisitDashboardComponent implements OnInit{
    constructor(private router: Router){}
    ngOnInit(){
        this.router.navigate(['/cell-visits']);
    }
}
