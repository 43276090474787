import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from '../../services/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { hasKey } from '../../util/object.util';
import { isString } from '../../util/utils';
import { ErrorMessage } from 'aws-sdk/clients/cloudformation';
import { LocationService } from '../../services/location.service';
import { ILocation } from '../../model/location.model';
import { NotificationsService } from 'angular2-notifications';
import { BEHAVIOUR_DATA, CLIENT_ACCESS, SYMPTOMS_DATA } from '../../util/drop_down.helper';
import { IContact } from '../../model/contact.model';
import { ContactService } from '../../services/contact.service';
import { CellVisitService } from '../../services/cell-visit.service';
import { logger } from '../../util/Logger';
import { IClient } from '../../model/client.model';
import { environment } from 'src/environments/environment';
import { getAllFormErrors } from '../../util/formUtils';

const className = "CellVisitEditComponent";

@Component({
	selector: 'app-cell-visit-edit',
	templateUrl: './cell-visit-edit.component.html',
	styleUrls: ['./cell-visit-edit.component.scss']
})
export class CellVisitEditComponent implements OnInit {
	public tZone: string = environment.timeZone;

	public isFormSubmit: boolean = false;
	public canViolence: boolean = false;
	public isCheckout: boolean = false;
	public isBulkCheckin: boolean = false;
	id: string | null = null;
	cellId: string | null = null;
	bulkCellId: string[] | null = null;
	cellForm: FormGroup;
	currentClient: { firstName: string, lastName: string, address: string, dob: string, phone: string };
	locationList: ILocation[] = [];
	contactList: IContact[] = [];
	accessCommentList: { label: string, value: string, name: string }[] = CLIENT_ACCESS;
	symptomsList: ({
		label: string;
		value: string;
		selected: boolean;
		disabled: boolean;
	} | {
		label: string;
		value: number;
		selected?: undefined;
		disabled?: undefined;
	})[] = SYMPTOMS_DATA;
	behaviourList: ({
		label: string;
		value: string;
		selected: boolean;
		disabled: boolean;
	} | {
		label: string;
		value: number;
		selected?: undefined;
		disabled?: undefined;
	})[] = BEHAVIOUR_DATA;
	@ViewChild('ReferralModal') ReferralModal: ElementRef;
	@ViewChild('addresstext') addresstext: ElementRef;

	constructor(
		private formBuilder: FormBuilder,
		private clientService: ClientService,
		private __locationService: LocationService,
		private __contactService: ContactService,
		private __cellVisitService: CellVisitService,
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private _notifications: NotificationsService,
	) {
		this.cellForm = this.formBuilder.group({
			isCellvisit: ['false', []],
			inCharge: [null, []],
			isServerRefused: ['false', []],
			serverRefusedBy: [{ value: null, disabled: true }, []],
			actionTaken: [null, []],
			clientComments: [null, []],
			isLeaveDiversion: ['3', []],
			leaveDiversionComment2: [null, []],

			locationId: [null, [Validators.required]],
			timeIn: [new Date(), []],
			accessComment: [null, [Validators.required]],
			isBelonging: ['false', []],
			belongingComment: [{ value: null, disabled: true }, []],
			isViolence: ['false', []],
			domesiticViolances: this.formBuilder.array([
				this.formBuilder.group({
					violancerName: [{ value: null, disabled: true }, []],
				})
			]),
			isMedicalCondition: ['false', []],
			isAmbulance: ['false', []],
			isOnMedication: ['false', []],
			medicationName: [{ value: null, disabled: true }, []],
			isArrangements: ['0', []],
			medicationDate: [null, []],
			medicationTime: [null, []],
			isAggression: ['false', []],
			medicalObservations: this.formBuilder.array([]),
			clientBackgroundInformations: this.formBuilder.array([
				this.formBuilder.group({
					information: [null, []],
				})
			]),
			isReferral: ['false', []],
			estimatedTime: [null, []],
			thingsToConsider: ['false', []],
			thingsToConsider2: ['false', []],
			thingsToConsider3: ['false', []],
			agreeFor: ['false', []],
			isReferralSupport: ['false', []],
			referrelId: [null, []],
			agreeForDetail3: [null, []],
			checkoutAt: [null, []],
			isCheckout: ['false', []],
		});

		this.id = this.route.snapshot.paramMap.get('id');
		this.cellId = this.route.snapshot.queryParamMap.get('cellId') || '';
		this.bulkCellId = this.route.snapshot.queryParamMap.get('celllId')?.split('/') || null;
		if (this.bulkCellId) {
			this.isCheckout = true;
			this.isBulkCheckin = true;

			this.cellForm.controls['locationId'].setValidators([]);
			this.cellForm.controls['accessComment'].setValidators([]);
		} else {
			this.route.params.subscribe({
				next: params => {
					if (hasKey(params, 'id') && isString(params.id)) {
						this.id = params.id;

						//#region for edit cell data
						this.clientService.clientSearchListing({ page: 1, limit: 10, search: '', listType: 'cell', mainId: this.cellId, clientId: params.id }).subscribe({
							next: divData => {
								if (divData.data.rows.length > 0) {
									// TODO: Remove Typecast
									let currentCell = divData.data.rows.shift() as Record<string, unknown>;
									// TODO: Remove Typecast
									this.currentClient = currentCell as typeof this["currentClient"];
									if (currentCell.cellVisits && Array.isArray(currentCell.cellVisits) && currentCell.cellVisits.length) {
										let cellVisits = currentCell.cellVisits.shift();
										this.isCheckout = true;
										this.cellId = cellVisits.id || '';

										for (let index = 1; index < cellVisits.medicalObservations.length; index++) {
											this.addMedicalObservations();
										}

										this.cellForm.patchValue({
											isViolence: String(cellVisits.isViolence),
										});
										for (let index = 1; index < cellVisits.domesiticViolances.length; index++) {
											this.addDomesiticViolance();
										}

										this.cellForm.reset({
											isCellvisit: String(cellVisits.isCellvisit),
											inCharge: cellVisits.inCharge,
											isServerRefused: String(cellVisits.isServerRefused),
											serverRefusedBy: {
												value: String(cellVisits.serverRefusedBy),
												disabled: !cellVisits.isServerRefused,
											},
											actionTaken: cellVisits.actionTaken,
											clientComments: cellVisits.clientComments,
											isLeaveDiversion: String(cellVisits.isLeaveDiversion),
											leaveDiversionComment2: cellVisits.leaveDiversionComment2,

											locationId: cellVisits.locationId,
											timeIn: cellVisits.timeIn || new Date(),
											accessComment: cellVisits.accessComment,
											isAlter: String(cellVisits.isAlter),
											isBelonging: String(cellVisits.isBelonging),
											belongingComment: { value: cellVisits.belongingComment, disabled: !cellVisits.isBelonging },
											isViolence: String(cellVisits.isViolence),
											isMedicalCondition: String(cellVisits.isMedicalCondition),
											isAmbulance: String(cellVisits.isAmbulance),
											isOnMedication: String(cellVisits.isOnMedication),
											medicationName: { value: cellVisits.medicationName, disabled: !cellVisits.isOnMedication },
											isArrangements: String(cellVisits.isArrangements),
											medicationDate: cellVisits.medicationDate,
											medicationTime: cellVisits.medicationTime,
											isAggression: String(cellVisits.isAggression),
											medicalObservations: cellVisits.medicalObservations,
											clientBackgroundInformations: cellVisits.clientBackgroundInformations,
											domesiticViolances: cellVisits.domesiticViolances,
											isReferral: String(cellVisits.isReferral),
											estimatedTime: cellVisits.estimatedTime,
											thingsToConsider: String(cellVisits.thingsToConsider),
											thingsToConsider2: String(cellVisits.thingsToConsider2),
											thingsToConsider3: String(cellVisits.thingsToConsider3),
											agreeFor: String(cellVisits.agreeFor),
											isReferralSupport: String(cellVisits.isReferralSupport),
											referrelId: cellVisits.referrelId,
											agreeForDetail3: cellVisits.agreeForDetail3,
											checkoutAt: null,
											isCheckout: 'false',
										});
										this.cellForm.updateValueAndValidity();
									}
									// this._notifications.success("Done", divData.message || "", {});
								} else {
									this._notifications.warn("ERROR", divData.message || "", {});
								}
							}
						})
						//#endregion

					}
				}
			});
		}
	}

	ngOnInit(): void {
		this.getLocations();
		this.getContactList();
	}


	getLocations() {
		this.__locationService.list({ filter: { isCellVisit: true }, limit: 1000 }).subscribe(async (response: any) => {
			if (response) {
				this.locationList = response.rows;
			} else {
				this._notifications.warn("ERROR", response.message || "", {});
			}
		}, err => {
			console.error(err);
			this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
		})
	}

	getContactList() {
		const signature = className + ".getContactList: ";
		logger.silly(signature + 'Started');

		this.__contactService.list({ filter: {} }).subscribe(async (response: any) => {
			if (response) {
				this.contactList = response.rows;
			} else {
				this._notifications.warn("ERROR", response.message || "", {});
			}
		}, err => {
			console.error(err);
			this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
		})
	}

	//#region belongingComment
	changeIsBelonging(evt: Event | null) {
		this.cellForm.controls['belongingComment'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.cellForm.controls['belongingComment'].enable();
			this.cellForm.controls['belongingComment'].setValidators([Validators.required]);
		} else {
			this.cellForm.controls['belongingComment'].disable();
			this.cellForm.controls['belongingComment'].setValidators([]);
		}
		this.cellForm.controls['belongingComment'].updateValueAndValidity();
		this.cellForm.updateValueAndValidity();
	}

	get belongingCommentRequired() {
		return this.cellForm.controls['belongingComment'].errors && this.cellForm.controls['belongingComment'].errors.required;
	}
	//#endregion

	//#region domesiticViolancesGroup
	domesiticViolances(): FormArray {
		return this.cellForm.get("domesiticViolances") as FormArray
	}

	newDomesiticViolances(): FormGroup {
		return this.formBuilder.group({
			violancerName: ['', []],
		})
	}

	addDomesiticViolance() {
		if (this.cellForm.controls['isViolence'].value == 'true') {
			this.domesiticViolances().push(this.newDomesiticViolances());
		}
	}

	removeDomesiticViolance(i: number) {
		if (this.cellForm.controls['isViolence'].value == 'true') {
			this.domesiticViolances().removeAt(i);
		}
	}
	//#endregion

	//#region violancerName
	changeIsViolence(evt: Event | null) {
		let dd: any = this.cellForm.controls['domesiticViolances'];
		if (!dd.controls.length) {
			this.cellForm.updateValueAndValidity();
			return;
		}

		let firstViolancerName = dd.controls[0].controls['violancerName'];

		firstViolancerName.setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			// this.canViolence = true;
			firstViolancerName.enable();
			firstViolancerName.setValidators([Validators.required]);
		} else {
			let i = (this.domesiticViolances().controls.length - 1);
			while (this.domesiticViolances().controls.length != 1) {
				this.removeDomesiticViolance(i);
				i--;
			}
			firstViolancerName.disable();
			firstViolancerName.setValidators([]);
			// this.canViolence = false;
		}
		firstViolancerName.updateValueAndValidity();
		this.cellForm.updateValueAndValidity();
	}

	get violancerNameRequired() {
		let dd: any = this.cellForm.controls['domesiticViolances'];
		let firstViolancerName = dd.controls[0].controls['violancerName'];

		return firstViolancerName.errors && firstViolancerName.errors.required;
	}
	//#endregion

	//#region medicationName
	changeIsOnMedication(evt: Event | null) {
		this.cellForm.controls['medicationName'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.cellForm.controls['medicationName'].enable();
			this.cellForm.controls['medicationName'].setValidators([Validators.required]);
		} else {
			this.cellForm.controls['medicationName'].disable();
			this.cellForm.controls['medicationName'].setValidators([]);
		}
		this.cellForm.controls['medicationName'].updateValueAndValidity();
		this.cellForm.updateValueAndValidity();
	}

	get medicationNameRequired() {
		return this.cellForm.controls['medicationName'].errors && this.cellForm.controls['medicationName'].errors.required;
	}
	//#endregion

	//#region medicalObservationsGroup
	medicalObservations(): FormArray {
		return this.cellForm.get("medicalObservations") as FormArray
	}

	newMedicalObservations(): FormGroup {
		return this.formBuilder.group({
			symptoms: ['', []],
		})
	}

	addMedicalObservations() {
		this.medicalObservations().push(this.newMedicalObservations());
	}

	removeMedicalObservations(i: number) {
		this.medicalObservations().removeAt(i);
	}

	symptomsSelected(itm: unknown) {
		/* let cunt = itm.target.value;
		for (let itm of this.symptomsList) {
			if (itm.value == cunt) {
				itm.visible = false;
			}
		} */
	}
	//#endregion

	//#region clientBackgroundInformationsGroup
	clientBackgroundInformations(): FormArray {
		return this.cellForm.get("clientBackgroundInformations") as FormArray
	}

	newClientBackgroundInformations(): FormGroup {
		return this.formBuilder.group({
			information: [null, []],
		})
	}

	addClientBackgroundInformations() {
		this.clientBackgroundInformations().push(this.newClientBackgroundInformations());
	}

	removeClientBackgroundInformations(i: number) {
		this.clientBackgroundInformations().removeAt(i);
	}
	//#endregion

	changeReferral(evt: Event | null) {
		this.cellForm.controls['referrelId'].setValue(null);
		this.cellForm.controls['agreeForDetail3'].setValue(null);
		this.cellForm.controls['thingsToConsider'].setValue('false');
		this.cellForm.controls['thingsToConsider2'].setValue('false');
		this.cellForm.controls['thingsToConsider3'].setValue('false');
		this.cellForm.controls['agreeFor'].setValue('false');
		this.cellForm.controls['isReferralSupport'].setValue('false');

		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.cellForm.controls['thingsToConsider'].setValidators([Validators.required]);
			this.cellForm.controls['thingsToConsider2'].setValidators([Validators.required]);
			this.cellForm.controls['thingsToConsider3'].setValidators([Validators.required]);
			this.modalService.open(this.ReferralModal, { scrollable: true, centered: true, size: 'lg' });
		} else {
			console.log(this.cellForm.value);
		}
		this.cellForm.updateValueAndValidity();
	}

	saveCellVisit(formData: FormGroup) {
		this.isFormSubmit = true;

		if (!this.cellForm.valid) {
			logger.error(getAllFormErrors(this.cellForm));
			return;
		}

		let saveMethod, formValue;
		if (this.bulkCellId) {
			formValue = Object.assign(formData.value, { clientId: this.id?.split('/') });

			// Ensure we're only saving checkout, otherwise this would override other data the diversion
			const bulkCheckoutData = [
				'clientId',
				'checkoutAt',
				'isCheckout',

				'isReferral',
				'estimatedTime',
				'isLeaveDiversion',
				'leaveDiversionComment2',

				// Referral Details
				'thingsToConsider',
				'thingsToConsider2',
				'thingsToConsider3',
				'agreeFor',
				'isReferralSupport',
				'referrelId',
				'agreeForDetail3'
			].reduce((payload, key) => {
				payload[key] = formValue[key];
				return payload
			}, {} as Record<string, unknown>);

			saveMethod = this.__cellVisitService.cellVisitApi.updateBulkCellVisit(this.bulkCellId, bulkCheckoutData)
		} else {
			formValue = Object.assign(formData.value, { clientId: this.id });
			saveMethod = this.cellId ?
				this.__cellVisitService.cellVisitApi.updateCellVisit(this.cellId, formValue) : this.__cellVisitService.cellVisitApi.createCellVisit(formValue);
		}
		saveMethod.subscribe({
			next: () => {
				this.router.navigate(['/cell-visits']);
			},
			error: (err: ErrorMessage) => { },
			complete: () => this.cellForm.markAsUntouched()
		});
	}

	cellCheckout() {
		this.cellForm.controls['checkoutAt'].setValue(new Date());
		this.cellForm.controls['isCheckout'].setValue('true');
		this.saveCellVisit(this.cellForm);
	}

	thingsToConsiderRequired(name: string) {
		return this.cellForm.controls[name].errors && this.cellForm.controls[name].errors?.required;
	}

	openReferral(content) {
		this.modalService.open(content, { scrollable: true, centered: true, size: 'lg' });
	}

	changeServerRefusedBy(evt: Event | null) {
		this.cellForm.controls['serverRefusedBy'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.cellForm.controls['serverRefusedBy'].enable();
		} else {
			this.cellForm.controls['serverRefusedBy'].disable();
		}
	}

}
