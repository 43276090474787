import { AbstractControl, Validators } from "@angular/forms";

export function nameValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }


  if (formControl.parent.controls['firstName'].value || formControl.parent.controls['organizationName'].value) {
    return null;
  } else {
    return Validators.required(formControl);
  }
}