import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { DiversionCentreService } from '../../services/diversion-centre.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { logger } from '../../util/Logger';


const className = "DiversionCenterListComponent";

@Component({
  selector: 'app-diversion-centre-list',
  templateUrl: './diversion-centre-list.component.html',
  styleUrls: ['./diversion-centre-list.component.scss']
})
export class DiversionCentreListComponent {
  public tZone: string = 'Australia/Brisbane';
  constructor(
    private _notifications: NotificationsService,
    private __router: Router,
    private route: ActivatedRoute,
    public __diversionCentreService: DiversionCentreService
  ) {
    this.getDiversionCollection();
  }

  public diversionList = <any>[];
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  public pageLimitActive: number = environment.defaultCollectionLimit || 10;
  public searchActive: string = "";
  public clientIdArray: string[] = [];
  public diversionIdArray: string[] = [];

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.getDiversionCollection();
  }

  getDiversionCollection() {
    let query;
    this.route.url.subscribe(urlSegments => {
      const filter: Record<string, unknown> = {};

      if (urlSegments[0].path === 'dashboard') {
        filter.isCheckout = 1;
      }

      this.__diversionCentreService.listingDiversionCentre({ page: this.currentPageActive, limit: this.pageLimitActive, search: this.searchActive, filter }).subscribe({
        next: response => {
          this.diversionList = response.data.rows;
          this.totalCountActive = response.data.count;
        },
        error: err => {
          console.error(err);
          this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
        }
      })
    });
  }

  searching(searchText: string) {
    this.searchActive = searchText;
    this.getDiversionCollection();
  }

  /**
   * selectItemActive
   */
  public selectItemActive(data: { clientId: string, id: string }): void {
    this.__router.navigate([`/diversion-centre/edit`, data.clientId], { queryParams: { diversionId: data.id } });
  }

  addNew() {
    if (this.clientIdArray.length == 0) {
      Swal.fire('checkOut', 'please select minimum one client for checkOut !', 'error')
    } else {
      this.__router.navigate([`/diversion-centre/edit`, this.clientIdArray.join('/')], { queryParams: { diversionnId: this.diversionIdArray.join('/') } });
    }
  }

  isClientSelected(clientId: string): boolean {
    return this.clientIdArray.includes(clientId);
  }

  /**
   * Adds or removes a clientId from the clientIdArray based on the provided parameters.
   *
   * @param {string} clientId - The ID of the client to add or remove.
   * @param {string} diversionId - The ID of the cell to add or remove.
   * @param {boolean} include - Specifies whether to include or remove the clientId.
   * @returns {undefined} - This function does not return a value.
   *
   */

  selectedClient(clientId: string, include: boolean, diversionId: string) {
    const signature = className + `.bulkCheckOut: ClientId[${clientId}] Include[${include}]] DiversionId[${diversionId}] Include[${include}]]`;

    const clientIdIndex = this.clientIdArray.indexOf(clientId);
    const clientIdExists = clientIdIndex !== -1;
    const diversionIdIndex = this.diversionIdArray.indexOf(diversionId);
    const diversionIdExist = diversionIdIndex !== -1

    logger.silly(signature + 'Started');
    if (include && !clientIdExists && !diversionIdExist) {
      this.clientIdArray.push(clientId);
      this.diversionIdArray.push(diversionId);
    } else if (!include && clientIdExists && diversionIdExist) {
      this.clientIdArray.splice(clientIdIndex, 1);
      this.diversionIdArray.splice(diversionIdIndex, 1);
    }
  }

  shouldDisplayClockIcon(record: { createdAt: string, isCheckout: boolean, clientObservationForms: { clientCheck: string }[] }): boolean {
    const currentTimestamp: number | string = new Date().toISOString();
    const createdTimestamp: string = record.createdAt;

    if (!record.clientObservationForms.length) {
      return true;
    }

    const maxClientCheckObj = record.clientObservationForms.reduce((maxObj, currentObj) => {
      if (new Date(currentObj.clientCheck).getTime() > new Date(maxObj.clientCheck).getTime()) {
        return currentObj;
      }
      return maxObj;
    }, record.clientObservationForms[0]);

    const msSinceCreation = new Date(currentTimestamp).getTime() - new Date(createdTimestamp).getTime();
    if (!record.isCheckout) {
      const lastObservationTimestamp = new Date(maxClientCheckObj.clientCheck);
      const msSinceLastObservation = new Date(currentTimestamp).getTime() - lastObservationTimestamp.getTime();
      // 4hrs
      if (msSinceCreation <= 4 * 60 * 60 * 1000) {
        // 15mins
        if (msSinceLastObservation >= 15 * 60 * 1000) {
          return true;
        }
      } else {
        // 30mins
        if (msSinceLastObservation >= 30 * 60 * 1000) {
          return true;
        }
      }
    }

    return false;
  }

  clockIconDetail(record: { createdAt: string, isCheckout: boolean, clientObservationForms: { clientCheck: string }[] }): string {
    const currentTimestamp: number | string = new Date().toISOString();
    const createdTimestamp: string = record.createdAt;

    if (!record.clientObservationForms.length) {
      return 'No Observations Recorded';
    }

    const maxClientCheckObj = record.clientObservationForms.reduce((maxObj, currentObj) => {
      if (new Date(currentObj.clientCheck).getTime() > new Date(maxObj.clientCheck).getTime()) {
        return currentObj;
      }
      return maxObj;
    }, record.clientObservationForms[0]);

    const msSinceCreation = new Date(currentTimestamp).getTime() - new Date(createdTimestamp).getTime();
    if (!record.isCheckout) {
      const lastObservationTimestamp = new Date(maxClientCheckObj.clientCheck);
      const msSinceLastObservation = new Date(currentTimestamp).getTime() - lastObservationTimestamp.getTime();
      // 4hrs
      if (msSinceCreation <= 4 * 60 * 60 * 1000) {
        // 15mins
        if (msSinceLastObservation >= 15 * 60 * 1000) {
          return this.formatMilliseconds(msSinceLastObservation);
        }
      } else {
        // 30mins
        if (msSinceLastObservation >= 30 * 60 * 1000) {
          return this.formatMilliseconds(msSinceLastObservation);
        }
      }
    }

    return '';
  }

  private formatMilliseconds(ms: number): string {
    const totalSeconds = Math.floor(ms / 1000);
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const totalHours = Math.floor(totalMinutes / 60);
    const hours = totalHours % 24;
    const days = Math.floor(totalHours / 24);

    const result: string[] = [];

    if (days > 0) {
      result.push(`${days}D`);
    }

    if (hours > 0) {
      result.push(`${hours}H`);
    }

    if (minutes > 0) {
      result.push(`${minutes}M`);
    }

    result.push(`${seconds}S`);

    return result.join('');
  }
}
