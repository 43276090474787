import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICategory } from '../model/category.model';

@Injectable()
export class CategoryApi extends GenericApi<ICategory> {
  public path = "category";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}