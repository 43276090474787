import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from '../../services/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { hasKey } from '../../util/object.util';
import { isString } from '../../util/utils';
import { ErrorMessage } from 'aws-sdk/clients/cloudformation';
import { LocationService } from '../../services/location.service';
import { ILocation } from '../../model/location.model';
import { NotificationsService } from 'angular2-notifications';
import { BEHAVIOUR_DATA, CLIENT_ACCESS, SYMPTOMS_DATA } from '../../util/drop_down.helper';
import { IContact } from '../../model/contact.model';
import { ContactService } from '../../services/contact.service';
import { CommunityPatrolService } from '../../services/community-patrol.service';
import { logger } from '../../util/Logger';
import { environment } from 'src/environments/environment';
import { isGoogleMapAvailable } from '../../util/google.util';
import { getAllFormErrors } from '../../util/formUtils';

declare var google;
declare var $;

const className = "CommunityPatrolEditComponent";


@Component({
	selector: 'app-community-patrol-edit',
	templateUrl: './community-patrol-edit.component.html',
	styleUrls: ['./community-patrol-edit.component.sass']
})
export class CommunityPatrolEditComponent implements OnInit {
	public tZone: string = environment.timeZone;

	public isFormSubmit: boolean = false;
	public canViolence: boolean = false;
	public isCheckout: boolean = false;
	public isBulkCheckin: boolean = false;
	id: string | null = null;
	communityId: string | null = null;
	bulkCommunityId: string[] | null = null;
	communityForm: FormGroup;
	currentClient: any;
	locationList: ILocation[] = [];
	contactList: IContact[] = [];
	accessCommentList: { label: string, value: string, name: string }[] = CLIENT_ACCESS;
	symptomsList: ({
		label: string;
		value: string;
		selected: boolean;
		disabled: boolean;
	} | {
		label: string;
		value: number;
		selected?: undefined;
		disabled?: undefined;
	})[] = SYMPTOMS_DATA;
	behaviourList: ({
		label: string;
		value: string;
		selected: boolean;
		disabled: boolean;
	} | {
		label: string;
		value: number;
		selected?: undefined;
		disabled?: undefined;
	})[] = BEHAVIOUR_DATA;
	@ViewChild('ReferralModal') ReferralModal: ElementRef;
	@ViewChild('addresstext') addresstext: ElementRef;

	constructor(
		private formBuilder: FormBuilder,
		private clientService: ClientService,
		private __locationService: LocationService,
		private __contactService: ContactService,
		private __communityPatrolService: CommunityPatrolService,
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private _notifications: NotificationsService,
	) {
		this.communityForm = this.formBuilder.group({
			footPrint: ['false', []],
			isServerRefused: ['false', []],
			serverRefusedBy: [{ value: null, disabled: true }, []],
			clientComments: [null, []],
			isLeaveDiversion: ['1', []],
			leaveDiversionComment2: [null, []],
			leaveDiversionComment1: [null, []],
			isBelongingTaken: ['1', []],

			locationId: [null, [Validators.required]],
			timeIn: [new Date(), []],
			accessComment: [null, [Validators.required]],
			isBelonging: ['false', []],
			belongingComment: [{ value: null, disabled: true }, []],
			isViolence: ['false', []],
			domesiticViolances: this.formBuilder.array([
				this.formBuilder.group({
					violancerName: [{ value: null, disabled: true }, []],
				})
			]),
			isMedicalCondition: ['false', []],
			isAmbulance: ['false', []],
			isOnMedication: ['false', []],
			medicationName: [{ value: null, disabled: true }, []],
			isArrangements: ['0', []],
			medicationDate: [null, []],
			medicationTime: [null, []],
			isAggression: ['false', []],
			medicalObservations: this.formBuilder.array([]),
			clientBackgroundInformations: this.formBuilder.array([
				this.formBuilder.group({
					information: [null, []],
				})
			]),
			isReferral: ['false', []],
			estimatedTime: [null, []],
			thingsToConsider: ['false', []],
			thingsToConsider2: ['false', []],
			thingsToConsider3: ['false', []],
			agreeFor: ['false', []],
			isReferralSupport: ['false', []],
			referrelId: [null, []],
			agreeForDetail3: [null, []],
			checkoutAt: [null, []],
			isCheckout: ['false', []],
		});

		this.id = this.route.snapshot.paramMap.get('id');
		this.communityId = this.route.snapshot.queryParamMap.get('communityId') || null;
		this.bulkCommunityId = this.route.snapshot.queryParamMap.get('communityyId')?.split('/') || null;

		if (this.bulkCommunityId) {
			this.isCheckout = true;
			this.isBulkCheckin = true;

			this.communityForm.controls['locationId'].setValidators([]);
			this.communityForm.controls['accessComment'].setValidators([]);
		} else {
			this.route.params.subscribe({
				next: params => {
					if (hasKey(params, 'id') && isString(params.id)) {
						this.id = params.id;

						//#region for edit community data
						this.clientService.clientSearchListing({ page: 1, limit: 10, search: '', listType: 'community', mainId: this.communityId, clientId: params.id }).subscribe({
							next: divData => {
								if (divData && divData.data.rows.length > 0) {
									// TODO: Remove Typecast
									let currentCommunity = divData.data.rows.shift() as Record<string, unknown>;
									// TODO: Remove Typecast
									this.currentClient = currentCommunity as typeof this["currentClient"];
									if (currentCommunity.communityPatrols && Array.isArray(currentCommunity.communityPatrols) && currentCommunity.communityPatrols.length) {
										let communityPatrols = currentCommunity.communityPatrols.shift();

										setTimeout(() => {
											this.getPlaceAutocomplete();
										}, 1000);

										this.isCheckout = true;
										this.communityId = communityPatrols.id || '';

										for (let index = 1; index < communityPatrols.medicalObservations.length; index++) {
											this.addMedicalObservations();
										}

										this.communityForm.patchValue({
											isViolence: String(communityPatrols.isViolence),
										});
										for (let index = 1; index < communityPatrols.domesiticViolances.length; index++) {
											this.addDomesiticViolance();
										}

										this.communityForm.reset({
											footPrint: String(communityPatrols.footPrint),
											isServerRefused: String(communityPatrols.isServerRefused),
											serverRefusedBy: {
												value: String(communityPatrols.serverRefusedBy),
												disabled: !communityPatrols.isServerRefused,
											},
											clientComments: communityPatrols.clientComments,
											isLeaveDiversion: String(communityPatrols.isLeaveDiversion),
											leaveDiversionComment1: { value: communityPatrols.leaveDiversionComment1, disabled: communityPatrols.isLeaveDiversion != 1 },
											leaveDiversionComment2: { value: communityPatrols.leaveDiversionComment2, disabled: communityPatrols.isLeaveDiversion != 4 },
											isBelongingTaken: String(communityPatrols.isBelongingTaken || ''),

											locationId: communityPatrols.locationId,
											timeIn: communityPatrols.timeIn || new Date(),
											accessComment: communityPatrols.accessComment,
											isAlter: String(communityPatrols.isAlter),
											isBelonging: String(communityPatrols.isBelonging),
											belongingComment: { value: communityPatrols.belongingComment, disabled: !communityPatrols.isBelonging },
											isViolence: String(communityPatrols.isViolence),
											isMedicalCondition: String(communityPatrols.isMedicalCondition),
											isAmbulance: String(communityPatrols.isAmbulance),
											isOnMedication: String(communityPatrols.isOnMedication),
											medicationName: { value: communityPatrols.medicationName, disabled: !communityPatrols.isOnMedication },
											isArrangements: String(communityPatrols.isArrangements),
											medicationDate: communityPatrols.medicationDate,
											medicationTime: communityPatrols.medicationTime,
											isAggression: String(communityPatrols.isAggression),
											medicalObservations: communityPatrols.medicalObservations,
											clientBackgroundInformations: communityPatrols.clientBackgroundInformations,
											domesiticViolances: communityPatrols.domesiticViolances,
											isReferral: String(communityPatrols.isReferral),
											estimatedTime: communityPatrols.estimatedTime,
											thingsToConsider: String(communityPatrols.thingsToConsider),
											thingsToConsider2: String(communityPatrols.thingsToConsider2),
											thingsToConsider3: String(communityPatrols.thingsToConsider3),
											agreeFor: String(communityPatrols.agreeFor),
											isReferralSupport: String(communityPatrols.isReferralSupport),
											referrelId: communityPatrols.referrelId,
											agreeForDetail3: communityPatrols.agreeForDetail3,
											checkoutAt: null,
											isCheckout: 'false',
										});
										this.communityForm.updateValueAndValidity();
									}
									// this._notifications.success("Done", divData.message || "", {});
								} else {
									this._notifications.warn("ERROR", divData.message || "", {});
								}
							}
						})
						//#endregion

					}
				}
			});
		}
	}

	ngOnInit(): void {
		this.getLocations();
		this.getContactList();
	}

	getLocations() {
		this.__locationService.list({ filter: { isCommunityPatrol: true }, limit: 1000 }).subscribe(async (response: any) => {
			if (response) {
				this.locationList = response.rows;
			} else {
				this._notifications.warn("ERROR", response.message || "", {});
			}
		}, err => {
			console.error(err);
			this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
		})
	}

	getContactList() {
		const signature = className + ".getContactList: ";
		logger.silly(signature + 'Started');

		this.__contactService.list({ filter: {} }).subscribe(async (response: any) => {
			if (response) {
				this.contactList = response.rows;
			} else {
				this._notifications.warn("ERROR", response.message || "", {});
			}
		}, err => {
			console.error(err);
			this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
		})
	}

	//#region belongingComment
	changeIsBelonging(evt: Event | null) {
		this.communityForm.controls['belongingComment'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.communityForm.controls['belongingComment'].enable();
			this.communityForm.controls['belongingComment'].setValidators([Validators.required]);
		} else {
			this.communityForm.controls['belongingComment'].disable();
			this.communityForm.controls['belongingComment'].setValidators([]);
		}
		this.communityForm.controls['belongingComment'].updateValueAndValidity();
		this.communityForm.updateValueAndValidity();
	}

	get belongingCommentRequired() {
		return this.communityForm.controls['belongingComment'].errors && this.communityForm.controls['belongingComment'].errors.required;
	}
	//#endregion

	//#region domesiticViolancesGroup
	domesiticViolances(): FormArray {
		return this.communityForm.get("domesiticViolances") as FormArray
	}

	newDomesiticViolances(): FormGroup {
		return this.formBuilder.group({
			violancerName: ['', []],
		})
	}

	addDomesiticViolance() {
		if (this.communityForm.controls['isViolence'].value == 'true') {
			this.domesiticViolances().push(this.newDomesiticViolances());
		}
	}

	removeDomesiticViolance(i: number) {
		if (this.communityForm.controls['isViolence'].value == 'true') {
			this.domesiticViolances().removeAt(i);
		}
	}
	//#endregion

	//#region violancerName
	changeIsViolence(evt: Event | null) {
		let dd: any = this.communityForm.controls['domesiticViolances'];
		if (!dd.controls.length) {
			this.communityForm.updateValueAndValidity();
			return;
		}
		let firstViolancerName = dd.controls[0].controls['violancerName'];

		firstViolancerName.setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			// this.canViolence = true;
			firstViolancerName.enable();
			firstViolancerName.setValidators([Validators.required]);
		} else {
			let i = (this.domesiticViolances().controls.length - 1);
			while (this.domesiticViolances().controls.length != 1) {
				this.removeDomesiticViolance(i);
				i--;
			}
			firstViolancerName.disable();
			firstViolancerName.setValidators([]);
			// this.canViolence = false;
		}
		firstViolancerName.updateValueAndValidity();
		this.communityForm.updateValueAndValidity();
	}

	get violancerNameRequired() {
		let dd: any = this.communityForm.controls['domesiticViolances'];
		let firstViolancerName = dd.controls[0].controls['violancerName'];

		return firstViolancerName.errors && firstViolancerName.errors.required;
	}
	//#endregion

	//#region medicationName
	changeIsOnMedication(evt: Event | null) {
		this.communityForm.controls['medicationName'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.communityForm.controls['medicationName'].enable();
			this.communityForm.controls['medicationName'].setValidators([Validators.required]);
		} else {
			this.communityForm.controls['medicationName'].disable();
			this.communityForm.controls['medicationName'].setValidators([]);
		}
		this.communityForm.controls['medicationName'].updateValueAndValidity();
		this.communityForm.updateValueAndValidity();
	}

	get medicationNameRequired() {
		return this.communityForm.controls['medicationName'].errors && this.communityForm.controls['medicationName'].errors.required;
	}
	//#endregion

	//#region medicalObservationsGroup
	medicalObservations(): FormArray {
		return this.communityForm.get("medicalObservations") as FormArray
	}

	newMedicalObservations(): FormGroup {
		return this.formBuilder.group({
			symptoms: ['', []],
		})
	}

	addMedicalObservations() {
		this.medicalObservations().push(this.newMedicalObservations());
	}

	removeMedicalObservations(i: number) {
		this.medicalObservations().removeAt(i);
	}
	symptomsSelected(itm: unknown) {
		/* let cunt = itm.target.value;
		for (let itm of this.symptomsList) {
			if (itm.value == cunt) {
				itm.visible = false;
			}
		} */
	}
	//#endregion

	//#region clientBackgroundInformationsGroup
	clientBackgroundInformations(): FormArray {
		return this.communityForm.get("clientBackgroundInformations") as FormArray
	}

	newClientBackgroundInformations(): FormGroup {
		return this.formBuilder.group({
			information: [null, []],
		})
	}

	addClientBackgroundInformations() {
		this.clientBackgroundInformations().push(this.newClientBackgroundInformations());
	}

	removeClientBackgroundInformations(i: number) {
		this.clientBackgroundInformations().removeAt(i);
	}
	//#endregion

	changeReferral(evt: Event | null) {
		this.communityForm.controls['referrelId'].setValue(null);
		this.communityForm.controls['agreeForDetail3'].setValue(null);
		this.communityForm.controls['thingsToConsider'].setValue('false');
		this.communityForm.controls['thingsToConsider2'].setValue('false');
		this.communityForm.controls['thingsToConsider3'].setValue('false');
		this.communityForm.controls['agreeFor'].setValue('false');
		this.communityForm.controls['isReferralSupport'].setValue('false');

		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.communityForm.controls['thingsToConsider'].setValidators([Validators.required]);
			this.communityForm.controls['thingsToConsider2'].setValidators([Validators.required]);
			this.communityForm.controls['thingsToConsider3'].setValidators([Validators.required]);
			this.modalService.open(this.ReferralModal, { scrollable: true, centered: true, size: 'lg' });
		} else {

		}
		this.communityForm.updateValueAndValidity();
	}

	saveCommunityPatrol(formData: FormGroup) {
		let saveMethod, formValue;
		this.isFormSubmit = true;

		if (!this.communityForm.valid) {
			logger.error(getAllFormErrors(this.communityForm));
			return;
		}

		if (!formData.value.leaveDiversionComment1) {
			formData.value.leaveDiversionComment1 = "";
		}
		if (!formData.value.leaveDiversionComment2) {
			formData.value.leaveDiversionComment2 = "";
		}
		if (this.bulkCommunityId) {
			formValue = Object.assign(formData.value, { clientId: this.id?.split('/') });

			// Ensure we're only saving checkout, otherwise this would override other data the diversion
			const bulkCheckoutData = [
				'clientId',
				'checkoutAt',
				'isCheckout',

				'isReferral',
				'estimatedTime',
				'isBelongingTaken',
				'isLeaveDiversion',
				'leaveDiversionComment1',
				'leaveDiversionComment2',

				// Referral Details
				'thingsToConsider',
				'thingsToConsider2',
				'thingsToConsider3',
				'agreeFor',
				'isReferralSupport',
				'referrelId',
				'agreeForDetail3'
			].reduce((payload, key) => {
				payload[key] = formValue[key];
				return payload
			}, {} as Record<string, unknown>);

			saveMethod = this.__communityPatrolService.communityPatrolApi.updateBulkCommunityPatrol(this.bulkCommunityId, bulkCheckoutData)
		} else {
			formValue = Object.assign(formData.value, { clientId: this.id });
			saveMethod = this.communityId ?
				this.__communityPatrolService.communityPatrolApi.updateCommunityPatrol(this.communityId, formValue) : this.__communityPatrolService.communityPatrolApi.createCommunityPatrol(formValue);
		}
		saveMethod.subscribe({
			next: () => {
				this.router.navigate(['/community-patrol']);
			},
			error: (err: ErrorMessage) => { },
			complete: () => this.communityForm.markAsUntouched()
		});
	}

	communityCheckout() {
		this.communityForm.controls['checkoutAt'].setValue(new Date());
		this.communityForm.controls['isCheckout'].setValue('true');
		this.saveCommunityPatrol(this.communityForm);
	}

	thingsToConsiderRequired(name: string) {
		return this.communityForm.controls[name].errors && this.communityForm.controls[name].errors?.required;
	}

	openReferral(content) {
		this.modalService.open(content, { scrollable: true, centered: true, size: 'lg' });
	}

	changeLeave() {
		this.communityForm.controls['leaveDiversionComment1'].setValue(null);
		this.communityForm.controls['leaveDiversionComment2'].setValue(null);
		const leaveValue = this.communityForm.controls['isLeaveDiversion'].value;
		if (leaveValue === '1') {
			this.communityForm.controls['leaveDiversionComment1'].enable();
			this.communityForm.controls['leaveDiversionComment2'].disable();
		} else {
			this.communityForm.controls['leaveDiversionComment1'].disable();
			this.communityForm.controls['leaveDiversionComment2'].enable();
		}
		this.communityForm.updateValueAndValidity();
	}

	private getPlaceAutocomplete() {
		const signature = className + '.getPlaceAutocomplete:';

		if (!isGoogleMapAvailable()) {
			logger.error(signature + `API for GoogleAutoComplete not currently available`);
			return;
		}

		const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
			{
				componentRestrictions: { country: 'AU' }
			});
		google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const place = autocomplete.getPlace();
			this.communityForm.controls['leaveDiversionComment1'].setValue(`${place.name}, ${place.formatted_address}`);
		});
	}

	changeServerRefusedBy(evt: Event | null) {
		this.communityForm.controls['serverRefusedBy'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.communityForm.controls['serverRefusedBy'].enable();
		} else {
			this.communityForm.controls['serverRefusedBy'].disable();
		}
	}

}
