import { NotificationsService } from "angular2-notifications";
import { slideInAnimation } from "../util/animations";
import { AuthService } from "../services/auth.service";
import { CommonControllerComponent } from '../util/common.controller';
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ErrorMessage, FieldRejection } from '../model/api.model';
import { serverSide } from '../util/utils';
import { getAllFormErrors } from "../util/formUtils";

@Component({
	template: `
	<section class="features-section-5 relative login-box" id="contact">

					<div class="form-outer background-light">
						<div class="app-section-header text-center mb-4 mb-md-5 ">
							<div class="mb-30">
								<app-section-title  text="Forget Password"></app-section-title>
							</div>
							<p>Just fill in your email and we’ll send you a link to reset your password.</p>
						</div>

						<div class="alert alert-danger" *ngIf="displayValidationErrors(fieldRejections)" [innerHTML]="displayValidationErrors(fieldRejections)"></div>

						<div class="clearfix"></div>

						<form [formGroup]="form">
							<div class="pb-4 pb-md-5">
								<div class="form-floating mb-30">
									<input type="email" class="form-control" id="floatingInput" placeholder="email" formControlName="email">
									<label for="floatingInput">Email </label>
								</div>
							</div>

							<div class="mb-4 mb-md-5">
								<button class="btn btn-theme w-100 send_mess"  (click)="reset()">Reset Password</button>
							</div>
							<div class="text-center">
								<a class="btn-link " routerLink="/login">Login</a>
							</div>
						</form>
					</div>

	</section>

	<p id="back-top"> <a (click)="scrollTop()"><span></span></a> </p>
	`,
	animations: [slideInAnimation]
})
export class ForgotComponent extends CommonControllerComponent {
	form: UntypedFormGroup;
	fieldRejections: FieldRejection[] = [];
	text = "Password Reset was sent to your inbox"
	constructor(
		private formBuilder: UntypedFormBuilder,
		private authService: AuthService,
		private router: Router
	) {
		super();

		this.form = this.formBuilder.group({
			email: [null, [Validators.required, Validators.email], serverSide(this.fieldRejections, 'email')],
		});
	}

	reset() {
		if ( !this.canSubmit(this.form) ) {
      getAllFormErrors(this.form);
      return;
    }

		this.form.markAsPending();

		this.authService.forgot(this.form.value)
			.subscribe(
				() => {
					this.authService.setText(this.text);
					this.router.navigate(['/login'])
				},
				(err: ErrorMessage) => this.handleValidationErrors(err, this.form, this.fieldRejections),
				() => this.form.markAsUntouched()
			);
	}
}
