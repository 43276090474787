import { createUrl, getPublicRoutesHeaders } from "./api.util";
import { Observable, of, pipe, switchMap } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UtilService } from '../services/util.service';
import { IJWTPayload, IJWTPayloadCode } from "../model/auth.model";

export type resetInput = {
  resetToken: string;
  email: string;
  password: string;
}
export type resetContent = IJWTPayload;
type resetOutput = Observable<resetContent>;

export type authenticateInput = {
  email: string;
  password: string;
  code: string | null;
}
export type authenticateContent = IJWTPayload | { status: true };
export type authenticateOutput = Observable<authenticateContent>;


export type authenticateContentCode = IJWTPayloadCode;
type authenticateOutputCode = Observable<authenticateContentCode>;

export type forgotInput = {
  email: string;
  password: string;
}
export type forgotContent = IJWTPayload;
type forgotOutput = Observable<forgotContent>;

@Injectable()
export class AuthApi extends UtilService {

  constructor(
    private readonly httpClient: HttpClient
  ) {
    super();
  }

  /**
   * @param  {resetInput} opts
   * @returns resetOutput
   */
  readonly reset = (opts: resetInput): resetOutput => {
    return this.httpClient.post<resetContent>(
      createUrl('auth', 'reset'),
      opts,
      { headers: getPublicRoutesHeaders() }
    )
  };

  /**
   * @param  {authenticateInput} opts
   * @returns authenticateOutput
   */
  readonly authenticate = (opts: authenticateInput): authenticateOutput => {
    return this.httpClient.post<authenticateContent>(
      createUrl('auth'),
      opts,
      { headers: getPublicRoutesHeaders() }
    )
  }

  /**
   * @param  {forgotInput} opts
   * @returns forgotOutput
   */
  readonly forgot = (opts: forgotInput): forgotOutput => {
    return this.httpClient.post<forgotContent>(
      createUrl('auth', 'forgot'),
      opts,
      { headers: getPublicRoutesHeaders() }
    )
  }

  /**
   * @description Fetches a new auth token from the server using the supplied refresh token
   * @param {Omit<IJWTPayload, 'Bearer'>} opts
   * @returns {Observable<IJWTPayload>>}
   */
  readonly refresh = (opts: Omit<IJWTPayload, 'tokenType'>): Observable<IJWTPayload> => {
    return this.httpClient.post<IJWTPayload>(
      createUrl('auth', 'refresh'),
      opts,
      { headers: getPublicRoutesHeaders() }
    )
  }

  /**
     * archivedOneTimePassword
     */
  public oneTimeLogin(credentials: {}) {
    return this.httpClient.post<{
      success: boolean,
    }>(createUrl('auth', 'oneTimeLogin'), credentials, { headers: getPublicRoutesHeaders() });
  }

  /**
     * checkLinkExpiry
     */
  public checkLinkExpiry(credentials: {}) {
    return this.httpClient.post<{
      success: boolean, require2fa: boolean
    }>(createUrl('auth', 'linkExpire'), credentials, { headers: getPublicRoutesHeaders() });
  }
}
