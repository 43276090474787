import { Injectable } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { apiCallWrapper } from "src/app/main/api/api.util";
import { GenericApi } from "src/app/main/api/generic.api";
import { HasId } from "src/app/main/model/generics";
import { IQueryFilter, QueryResult } from "src/app/main/model/query.filter.class";

@Injectable()
export abstract class GenericService<T = unknown, I = string> {

  /**
   * @description Allows for hooking into things occurring within the service
   *  however this should not be used as a cache of any sort (EG: Do not attempt
   *  to read the content of listResponse$.value to filter for a value) else
   *  this service will become very vulnerable to defects due to race conditions
   */
  public getResponse$ = new BehaviorSubject<(T & HasId<I>) | null>(null);
  public createResponse$ = new BehaviorSubject<(T & HasId<I>) | null>(null);
  public updateResponse$ = new BehaviorSubject<(T & HasId<I>) | null>(null);
  public deleteResponse$ = new BehaviorSubject<({ success: boolean, deleted: number }) | null>(null);
  public listResponse$ = new BehaviorSubject<(QueryResult<T & HasId<I>>) | null>(null);

  public entityName: string = "Item";

  constructor(
    public api: GenericApi<T, I>,
    public notifications: NotificationsService
  ) { }

  get = (id: number | string): Observable<T & HasId<I>> => {
    return apiCallWrapper(
      this.api.get(id).pipe(
        tap(resp => this.getResponse$.next(resp))
      ),
      {
        notificationsService: this.notifications,
        action: `Fetching ${this.entityName.toLowerCase()} ${id}`
      }
    )
  };

  create = (model: Partial<T>): Observable<T & HasId<I>> => {
    return apiCallWrapper(
      this.api.create(model).pipe(
        tap(resp => this.createResponse$.next(resp))
      ),
      {
        notificationsService: this.notifications,
        action: `Saving ${this.entityName.toLowerCase()}`
      }
    );
  };

  update = (id: number | string, model: Partial<T>): Observable<T & HasId<I>> => {
    return apiCallWrapper(
      this.api.update(id, model).pipe(
        tap(resp => this.updateResponse$.next(resp))
      ),
      {
        notificationsService: this.notifications,
        action: `Updating ${this.entityName.toLowerCase()} ${id}`
      }
    )
  };

  delete = (id: number | string): Observable<{ success: boolean, deleted: number }> => {
    return apiCallWrapper(
      this.api.delete(id).pipe(
        tap(resp => this.deleteResponse$.next(resp))
      ),
      {
        notificationsService: this.notifications,
        action: `Deleting ${this.entityName.toLowerCase()} ${id}`
      }
    )
  }

  list = (query?: Partial<IQueryFilter>): Observable<QueryResult<T & HasId<I>>> => {
    // Effectively rebuilds the class
    const finalQuery = new IQueryFilter<T>(query);

    const result = apiCallWrapper(
      this.api.list(finalQuery).pipe(
        tap(resp => this.listResponse$.next(resp))
      ),
      {
        notificationsService: this.notifications,
        action: `Listing ${this.entityName.toLowerCase()}s`,
        defaultValue: { count: 0, rows: [] }
      }
    ).pipe(
      map(resp => {
        const finalResp = new QueryResult<T & HasId<I>>(resp);
        finalResp.setQuery(finalQuery);
        return finalResp;
      }),
    )

    return result;
  }
}