import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { showError } from '../../main/util/formUtils';

@Component({
  selector: 'app-field-errors-component',
  template: `
    <span class="span error" *ngFor="let errorText of errorsToDisplay">{{ errorText }}</span>
  `,
  styleUrls: []
})
export class FieldErrorsComponentComponent{
  @Input() formGroup: FormGroup;
  @Input() name: string;
  @Input() errors: { [key: string]: unknown; } = {};

  get errorsToDisplay() {
    const result = Object.keys(this.errors)
      .filter(errorName => showError(this.formGroup, this.name, errorName))
      .map(errorName => this.errors[errorName]);
    return result;
  }
}
