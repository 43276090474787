import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createUrl, getPublicRoutesHeaders } from './api.util';
import { IUser } from '../model/user.model';

@Injectable()
export class AdminApi extends GenericApi<IUser> {
  public path = "admin";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }


  /**
   * archivedAdminListing
   */
  public archivedAdminListing(credentials: {}) {
    return this.httpClient.post<{
      status: boolean,
      message: string,
      data: {
        count: number,
        rows: unknown[]
      }
    }>(createUrl('admin', 'archivedAdminListing'), credentials);
  }

  /**
   * inviteAdmin
   */
  public inviteAdmin(credentials: {}) {
    return this.httpClient.post(createUrl('admin', 'invite'), credentials);
  }

  /**
   * inviteAgainUser
   */
  public inviteAgainUser(credentials: {}) {
    return this.httpClient.post(createUrl('admin', 'invite_again'), credentials);
  }

  public prepareNextviewSSO() {
    return this.httpClient.get<{
      data: {
        token: string
      }
    }>(createUrl('admin', 'prepare_nextview_sso'));
  }

  /**
   * @param  {getTokenedUser} opts
   * @returns getTokenedUser
   */
  readonly getTokenedUser = (opts) => {
    return this.httpClient.post(
      createUrl('admin', 'getTokenedUser'),
      opts,
      { headers: getPublicRoutesHeaders() }
    )
  };

  /**
   * @param  {updateUser} opts
   * @returns updateUser
   */
  readonly updateUser = (opts) => {
    return this.httpClient.post(
      createUrl('admin', 'updateUser'),
      opts,
      { headers: getPublicRoutesHeaders() }
    )
  };

  /**
   * restoreUser
   */
  public restoreUser(credentials: { id: string }) {
    return this.httpClient.post(createUrl('admin', 'restoreUser'), credentials);
  }
}
