import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, Router } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';
import { Observable, from, map, of, switchMap, tap } from 'rxjs';
import { logger } from '../util/Logger';

const className = "AdminGuard";

@Injectable()
export class AdminGuard implements CanActivate, CanLoad {
  public userRole: string;
  constructor(
    private __adminUserService: AdminService,
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(): Observable<boolean> {
    const signature = className + '.canActivate: ';
    return from(of(this.authService.isAuthenticated()))
      .pipe(
        tap(isAuthenticated => {
          logger.silly(signature + `isAuthenticated[${isAuthenticated}]`);
          if (!isAuthenticated)
            this.router.navigate(['/login']);
        }),
        switchMap(isAuthenticated => {
          return this.__adminUserService.getAuthenticatedUser().pipe(
            map(usr => !!usr && usr.role === 'Admin'),
            tap(result => logger.silly(signature + `canActivate[${result}]`))
          );
        })
      );
  }

  canLoad(route: Route): Observable<boolean> {
    return this.canActivate();
  }
}
