<!--component html goes here -->
<div class="form-theme theme-box">
  <b>Admin Tools</b>

  <div class="row flex-row">
    <div class="col-4">

    </div>
  </div>
</div>
