import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { NotificationsService } from "angular2-notifications";
import { IClientNote } from "../model/client-note.model";
import { ClientNoteApi } from "../api/client-note.api";

@Injectable()
export class ClientNoteService extends GenericService<IClientNote> {
  constructor(
    public clientApi: ClientNoteApi,
    public notifications: NotificationsService
  ) {
    super(clientApi, notifications);
  }
}