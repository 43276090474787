import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'app-auth-template',
    templateUrl: 'auth-template.component.html',
    styleUrls: ['auth-template.component.scss']
})
export class AuthTemplateComponent {

}
