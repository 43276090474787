import { environment } from "../../../environments/environment";
import * as Sentry from "@sentry/angular-ivy";

/**
 * @description Rudimentary logger
 */

const logLevels = {
  none: 0,
  silly: 1,
  debug: 2,
  info: 3,
  warn: 4,
  error: 5,
  all: 6
};

const logLevelName = environment.logLevel || "debug";
const logLevel = logLevels[logLevelName] || logLevels.debug;

export namespace logger {
  function isPlainMessage(...args: Array<unknown>) {
    if (args.length === 1 && typeof args[0] === 'string') {
      return true;
    }
    return false;
  }

  export function none(...args: Array<unknown>) {
    // do nothing
  }
  export function silly(...args: Array<unknown>) {
    if (logLevel <= logLevels.silly) {
      if (isPlainMessage(...args)) {
        console.log.call({}, 'silly: ' + args[0]);
      } else {
        console.log.call({}, 'silly:', ...args);
      }
    }
  }
  export function debug(...args: Array<unknown>) {
    if (logLevel <= logLevels.debug) {
      if (isPlainMessage(...args)) {
        console.log.call({}, 'debug: ' + args[0]);
      } else {
        console.log.call({}, 'debug:', ...args);
      }
    }
  }
  export function info(...args: Array<unknown>) {
    if (logLevel <= logLevels.info) {
      if (isPlainMessage(...args)) {
        console.log.call({}, 'info: ' + args[0]);
      } else {
        console.log.call({}, 'info:', ...args);
      }
    }
  }

  /**
   * @description Warn has been mapped to log due to the nature
   * of warn in the browser, which is often hidden by default.
   *
   * @param {any[]} args
   */
  export function warn(...args: Array<unknown>) {
    if (logLevel <= logLevels.warn) {
      if (isPlainMessage(...args)) {
        console.log.call({}, 'warn: ' + args[0]);
      } else {
        console.log.call({}, 'warn:', ...args);
      }
    }
  }

  export function error(...args: Array<unknown>) {
    if (logLevel <= logLevels.error) {
      if (isPlainMessage(...args)) {
        console.log.call({}, 'error: ' + args[0]);
      } else {
        console.log.call({}, 'error:', ...args);
      }

      if (environment.enableSentry) {
        Sentry.captureException(args);
      }
    }
  }

  export function all(...args: Array<unknown>) {
    if (logLevel <= logLevels.all) {
      if (isPlainMessage(...args)) {
        console.log.call({}, 'all: ' + args[0]);
      } else {
        console.log.call({}, 'all:', ...args);
      }
    }
  }
}

logger.info(`Initalized Log at ${logLevelName} : ${logLevel}`);
