
<div class="mb-30">
  <div class="row">
    <div class="col">
      <div class="input-group">
        <div class="form-floating">
          <input #searchText type="text" class="form-control" id="search_text" placeholder="Search"
            (keyup)="updateSearchTerm(searchText.value)" [value]="search">
          <label for="search_text">Client Search</label>
        </div>
        <span class="input-group-text">
          <svg class="" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
            </path>
          </svg></span>
      </div>
    </div>
    <div class="col-md-auto">
      <button class="btn btn-large btn-primary" [routerLink]="['/clients/edit']">Add New
        Client</button>
      </div>
    <div class="col-md-auto">
      <button class="btn btn-large btn-primary" (click)="mergeModel(content)">Client
        Merge</button>
      </div>
  </div>
</div>

<div class="theme-box p-0">
  <div class="d-flex justify-content-end">
    <flag-key [showFootPatrol]="false"></flag-key>
  </div>
  <div class="table-responsive mb-30">
    <table class="table table-hover table-theme table-stripedTheme table-dashboard text-center">
      <thead class="thead-dark">
        <tr>
          <th></th>
          <th></th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Mobile</th>
          <th>DOB</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cnt of clientList | paginate: {
            itemsPerPage: pageLimit,
            currentPage: currentPage,
            totalItems :totalCount
        }; let i = index"
        #tooltipTarget
        [ngbTooltip]="cnt.tooltipText"
        placement="top">
          <td>
            <i *ngIf="cnt.highRisk" class="fa-solid fa-flag text-primary mx-1"></i>
            <i *ngIf="cnt.isMaster" class="fa-solid fa-flag text-warning mx-1"></i>
            <i *ngIf="getLastService(cnt)?.isViolence" class="fa-solid fa-flag text-danger mx-1"></i>
          </td>
          <td>
            <input #mergeSelect class="form-check-input" type="checkbox" value="" [checked]="isClientSelected(cnt.id)"
              id="isMasterRecord" name="master_option" (click)="addMerge(cnt.id,mergeSelect.checked,cnt.isMaster)">
          </td>
          <td (click)="selectUser(cnt)">{{cnt.firstName}}</td>
          <td>{{cnt.lastName}}</td>
          <td>{{cnt.phone}}</td>
          <td *ngIf="cnt.dob">{{cnt.dob | date}}</td>
          <td *ngIf="!cnt.dob">Unknown</td>
        </tr>
      </tbody>
    </table>
    <div class="has-text-centered">
      <app-pagination [currentPage]="currentPage" [pageSize]="pageLimit"
        [totalRows]="totalCount"
        (pageChange)="pageChanged($event)"
        ></app-pagination>
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-body">
      <div class="form-check my-4 px-0 text-center">
        <h3>Merging {{clientIdArray.length}} records</h3>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <button (click)="onConfirm()" class="btn btn-primary py-2 me-4">
          confirm
        </button>

        <button (click)="onClose()" class="btn btn-danger py-2">
          cancel
        </button>
      </div>
    </div>
  </ng-template>
</div>
