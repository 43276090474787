import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-tab-set',
  templateUrl: 'tab-set.component.html',
  styleUrls: ['tab-set.component.scss']
})
export class TabSetComponent {
  public activeTabIdx = 0;

  @Input()
  tabs: string[] = [];

  @Output() newItemEvent = new EventEmitter<number>();

  constructor(){

  }

  public isActiveTab(idx: number) {
    return idx === this.activeTabIdx;
  }

  public setActiveTab(idx: number) {
    this.activeTabIdx = idx;
    this.newItemEvent.emit(this.activeTabIdx);
  }

}
