import { Component, Output, EventEmitter } from '@angular/core';
import { MenuStates } from '../app-menu/app-menu.component';
import { JwtService } from '../../services/jwt.service';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { IUser } from '../../model/user.model';
import { InactivityService } from '../../services/inactivity.service';

@Component({
  selector: 'app-header',
  templateUrl: 'app-header.component.html',
  styleUrls: ['app-header.component.scss']
})
export class AppHeaderComponent {
  public user: IUser;
  constructor(
    private jwtService: JwtService,
    private router: Router,
    private __adminUserService: AdminService,
    private inactivityService: InactivityService,
  ) {
    this.__adminUserService.user$.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }
  public menuState: MenuStates = 'open';

  @Output()
  menuStateChanged = new EventEmitter<MenuStates>();

  public openOrCloseMenu() {
    this.menuState = this.menuState === 'open' ? 'close' : 'open';

    this.menuStateChanged.emit(this.menuState);
  }

  logout() {
    this.inactivityService.resetInactivityTimer(true);
    this.jwtService.removeJWTData();
    this.router.navigate(['/login']);
  }

  /**
       * selectUser
       */
  public selectUser(cat: string): void {
    this.router.navigate([`/admin/users/edit`, btoa(cat), { profile: true }]);
  }
}
