
export const CLIENT_ACCESS = [
  { label: "1-Community Patrol has referred", value: "1-Community Patrol has referred", name: "accessComment", },
  { label: "2-Family or friends contacted", value: "2-Family or friends contacted", name: "accessComment", },
  { label: "3-Queensland Police Service contacted", value: "3-Queensland Police Service contacted", name: "accessComment", },
  { label: "4-Service User has self-referred", value: "4-Service User has self-referred", name: "accessComment", },
  { label: "99-Other", value: "99-Other", name: "accessComment", },
];

export const SYMPTOMS_DATA = [
  { label: '', value: '', selected: true, disabled: true },
  { label: "1. Cannot sit, stand or walk properly", value: 1 },
  { label: "2. Unconscious and/or unable to be woken", value: 2 },
  { label: "3. Becoming more vague and less sensible over time", value: 3 },
  { label: "4. Serious injury, including head injury, bleeding in particular from the mouth or ears", value: 4 },
  { label: "5. Other signs of injuries such as cuts or bruises", value: 5 },
  { label: "6. Cannot stop vomiting, or vomits up blood", value: 6 },
  { label: "7. Epileptic fit or fainting", value: 7 },
  { label: "8. Asthma attack", value: 8 },
  { label: "9. Difficult or noisy breathing, including cracking or wheezing", value: 9 },
  { label: "10. Chest, head, stomach, leg, arm pain", value: 10 },
  { label: "11. Diarrhea", value: 11 },
  { label: "12. Showing signs of recently taking drugs and/or inhaled fumes", value: 12 },
  { label: "13. Withdrawal symptoms – especially from alcohol (anxious, aggressive, irritable, cannot sleep, tremor’s)", value: 13 },
];

export const BEHAVIOUR_DATA = [
  { label: '', value: '', selected: true, disabled: true },
  { label: "1. Threatening or looking for ways to hurt or kill themselves", value: 1 },
  { label: "2. Talking or writing about death, dying or suicide", value: 2 },
  { label: "3. Giving away possessions or saying goodbye to family and/or friends, and/or saying they have no reason for living or have no purpose in life", value: 3 },
  { label: "4. Expressing feelings of hopelessness", value: 4 },
  { label: "5. Rage, extreme anger or expressions of revenge against a person", value: 5 },
  { label: "6. Engaging in reckless or risky bahavious", value: 6 },
  { label: "7. Anxious, agitated and/or expressing feelings of being trapped, like there’s no way out", value: 7 },
  { label: "8. Has discussed the increased use of alcohol or other drugs", value: 8 },
  { label: "9. Has discussed withdrawing from friends, family or the community", value: 9 },
  { label: "10. Has discussed abnormal sleep patterns – not sleeping or not being able to sleep without waking constantly", value: 10 },
  { label: "11. Dramatic changes in mood, such as feelings of happiness after a long period of sadness or depression", value: 11 },
];