import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-pagination",

  styles: [
    `
      .link-disabled: {
        color: gray;
        cursor: not-allowed;
        text-decoration: underline;
      }
    `,
  ],
  template: `<nav aria-label="Page navigation example">
  <ul class="pagination justify-content-end">
    <li class="page-item">
      <a
        class="page-link "
        [ngClass]="{ 'link-disabled': currentPage === 1 }"
        href="javascript:void(0)"
        aria-labe="Previous"
        (click)="setPage(currentPage - 1)"
      >
        <span aria-hidden="true"><i class="fas fa-chevron-left"></i></span
        ><span class="sr-only">Previous</span></a
      >
    </li>
    <li class="page-item" *ngIf="this.hasEarlierPages">
      <a class="page-link"
        href="javascript:void(0)"
        (click)="setPage(this.earlierPage)">...</a>
    </li>
    <li *ngFor="let page of paginationRange" class="page-item">
      <a
        class="page-link"
        href="javascript:void(0)"
        [ngClass]="{ active: page === currentPage }"
        (click)="setPage(page)"
        >{{ page }}</a
      >
    </li>
    <li class="page-item" *ngIf="this.hasLaterPages">
      <a class="page-link"
        href="javascript:void(0)"
        (click)="setPage(this.laterPage)">...</a>
    </li>
    <li class="page-item">
      <a
        class="page-link"
        href="javascript:void(0)"
        aria-label="Next"
        [ngClass]="{ 'link-disabled': currentPage === totalPages }"
        (click)="setPage(currentPage + 1)"
        ><span aria-hidden="true"><i class="fas fa-chevron-right"></i></span
        ><span class="sr-only">Next</span></a
      >
    </li>
  </ul>
</nav>`,
})
export class PaginationComponent {

  // The current page : 1 indexed
  @Input() currentPage: number;
  // The total size of a page
  @Input() pageSize: number;
  // The total number of rows that exist in the result
  @Input() totalRows: number;
  @Output() pageChange = new EventEmitter<number>();
  // The total number of pages to display in pagination
  @Input() maxPagesToDisplay = 10;

  get totalPages(): number {
    return Math.ceil(this.totalRows / this.pageSize);
  }

  get showAllPages(): boolean {
    return this.totalPages <= this.maxPagesToDisplay;
  }

  get hasEarlierPages(): boolean {
    const range = this.paginationRange;
    if (range.length > 0 && range[0] > 1) {
      return true;
    }
    return false;
  }

  get earlierPage(): number {
    const page = this.currentPage - this.maxPagesToDisplay;

    // Ensure that the startPage is within bounds
    if (page < 1) {
      return 1;
    }
    return page;
  }

  get laterPage(): number {
    const page = this.currentPage + this.maxPagesToDisplay;

    // Ensure that the startPage is within bounds
    if (page > this.totalPages) {
      return this.totalPages;
    }

    return page;
  }

  get hasLaterPages(): boolean {
    const range = this.paginationRange;
    if (range.length > 0 && range[range.length - 1] < this.totalPages) {
      return true;
    }
    return false;
  }

  get paginationRange(): number[] {
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const maxPagesToDisplay = this.maxPagesToDisplay;

    // Calculate the starting page
    let startPage = currentPage - Math.floor(maxPagesToDisplay / 2);

    // Ensure that the startPage is within bounds
    if (startPage < 1) {
      startPage = 1;
    }

    // Calculate the ending page
    let endPage = startPage + maxPagesToDisplay - 1;

    // Ensure that the endPage does not exceed the total number of pages
    if (endPage > totalPages) {
      endPage = totalPages;
    }

    // Create an array of page numbers within the calculated range
    const pages: number[] = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  get startRow(): number {
    return (this.currentPage - 1) * this.pageSize + 1;
  }

  get endRow(): number {
    const endRow = this.currentPage * this.pageSize;
    return endRow > this.totalRows ? this.totalRows : endRow;
  }

  get totalPagesDisplay(): string {
    return `of ${this.totalPages}`;
  }

  constructor() { }

  public handlePageChange() {
    this.pageChange.emit(this.currentPage);
  }

  setPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.handlePageChange();
    }
  }
}
