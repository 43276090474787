import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ILocation } from 'src/app/main/model/location.model';
import { IUser } from 'src/app/main/model/user.model';
import { AdminService } from 'src/app/main/services/admin.service';
import { JwtService } from 'src/app/main/services/jwt.service';
import { LocationService } from 'src/app/main/services/location.service';

@Component({
	selector: 'app-user-edit',
	templateUrl: 'user-edit.component.html',
	styleUrls: ['user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

	public id: string = "";
	userForm: FormGroup;
	public submitted = false;
	public currentUser: IUser;
	public isConfirmSignup: string | null = "";
	public locationList: ILocation[] = [];
	public userRole: string;
	public currentUserId: string;
	public isArchived: boolean = false;
	public isProfile: boolean = false;

	constructor(
		private fb: FormBuilder,
		private __adminUserService: AdminService,
		private __route: ActivatedRoute,
		private _notifications: NotificationsService,
		private __router: Router,
		private __locationService: LocationService,
		private cd: ChangeDetectorRef,
		private jwtService: JwtService
	) {

		this.getLocations();

		this.userForm = this.fb.group({
			firstName: ['', [Validators.required, Validators.maxLength(250)]],
			lastName: ['', [Validators.required, Validators.maxLength(250)]],
			email: ['', [Validators.required, Validators.email, Validators.maxLength(250)]],
			locationId: ['', [Validators.required, Validators.maxLength(250)]],
			phone: ['', [Validators.required, Validators.maxLength(250)]],
			role: ['', [Validators.required, Validators.maxLength(250)]],
			pin: ['', [Validators.maxLength(4)]],
		});
		this.__adminUserService.user$.subscribe(user => {
			if (user) {
				this.currentUserId = user.id;
				this.userRole = user.role;
			}
		});
	}

	ngOnInit(): void {
		this.__route.paramMap.subscribe(params => {
			this.id = atob(params.get('id') || '');
			if (this.id) {
				this.__adminUserService.get(this.id).subscribe({
					next: usr => {

						this.currentUser = usr;
						this.isConfirmSignup = usr.confirmSignup;

						this.userForm.controls['firstName'].setValue(usr.firstName);
						this.userForm.controls['lastName'].setValue(usr.lastName);
						this.userForm.controls['email'].setValue(usr.email);
						this.userForm.controls['locationId'].setValue(usr.locationId);
						this.userForm.controls['phone'].setValue(usr.phone);
						this.userForm.controls['role'].setValue(usr.role);
						this.userForm.controls['pin'].setValue(usr.pin);

						this.isArchived = usr.deletedAt ? true : false
						this.cd.detectChanges();
					},
					error: err => {
						console.error(err);
						this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
					}
				})
			}

			if (params.get('profile')) {
				this.isProfile = true;
			}
		})

	}

	/**
		 * updateUser
		 */
	public updateUser(): void {
		this.submitted = true;
		if (!this.userForm.valid) {
			return;
		}

		if (this.id) {
			this.__adminUserService.update(this.id, this.userForm.value).subscribe((res) => {
				if (this.isProfile || this.currentUserId == this.id) {
					this.__adminUserService.setUser(res);
				}
				if (this.userRole == 'Admin') {
					this.__router.navigate([`/admin/users`]);
				} else {
					this.__router.navigate([`/dashboard`]);
				}
			})
		} else {
			this.__adminUserService.adminApi.inviteAdmin(this.userForm.value).subscribe((response: { status: boolean, message: string }) => {
				if (response.status) {
					this.__router.navigate([`/admin/users`]);
				} else {
					this._notifications.warn("ERROR", response.message || "", {});
				}
			})
		}
	}

	/**
		 * deleteUser
		 */
	public deleteUser(): void {
		if (this.id) {
			this.__adminUserService.delete(this.id).subscribe(() => {
				this.__router.navigate([`/admin/users`]);
			});
		}
	}

	/**
		 * restoreUser
		 */
	public restoreUser(): void {
		if (this.id) {
			this.__adminUserService.restoreUser(this.id).subscribe(() => {
				this.__router.navigate([`/admin/users`]);
			});
		}
	}


	/**
		 * inviteAgainUser
		 */
	public inviteAgainUser(): void {
		if (this.id) {
			let postData = {
				id: this.id,
				firstName: this.currentUser.firstName,
				email: this.currentUser.email,
				token: this.currentUser.confirmSignup,
			}
			this.__adminUserService.adminApi.inviteAgainUser(postData).subscribe(() => {
				this._notifications.success("Invite mail sending successfully.")
			})
		}
	}

	formatContactNumber(inputValue: String): String {
		const value = inputValue.replace(/[^0-9]/g, ''); // remove except digits
		let format = '** **** ****'; // You can change format

		for (let i = 0; i < value.length; i++) {
			format = format.replace('*', value.charAt(i));
		}

		if (format.indexOf('*') >= 0) {
			format = format.substring(0, format.indexOf('*'));
		}

		this.userForm.controls['phone'].setValue(format.trim());
		return format.trim();
	}

	getLocations() {
		this.__locationService.list({ limit: 1000 }).subscribe({
			next: (response: any) => {
				if (response) {
					this.locationList = response.rows;
				} else if (response.message) {
					this._notifications.warn("ERROR", response.message || "", {});
				}
			},
			error: err => {
				console.error(err);
				this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
			}
		})
	}

}
