import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { NotificationsService } from "angular2-notifications";
import { CategoryApi } from "../api/category.api";
import { ICategory } from "../model/category.model";

@Injectable()
export class CategoryService extends GenericService<ICategory> {
  constructor(
    public categoryApi: CategoryApi,
    public notifications: NotificationsService
  ) {
    super(categoryApi, notifications);
  }
}