/**
 * Since angular2 still doesn't handle inputs very well this component does some pretty hacky things
 * The following Scenarios have been accounted for:
 * Template Content + Async Template Content Changes
 *
 * Un AccountedFor Scenarios
 * Default Value + Async Default Value (Existing Data)
 */

import {
	Component,
	Input,
	ApplicationRef,
	ElementRef,
	ViewChild,
	OnInit,
	AfterViewInit
} from "@angular/core";
import { AbstractControl, FormArray, FormControl, UntypedFormGroup } from "@angular/forms";
import { showError } from "../../../main/util/formUtils";
import {
	Options as Select2Options,
	DataFormat,
	GroupedDataFormat,
} from "select2";
import { logger } from "src/app/main/util/Logger";
import { GenericService } from "src/app/main/services/generic.service";
import {
	IQueryFilter,
	QueryResult,
} from "src/app/main/model/query.filter.class";

const className = "SelectInputComponent";

type Select2OptionData = DataFormat[];

@Component({
	selector: 'app-select-input',
	templateUrl: 'select-input.component.html',
	styleUrls: ['select-input.component.scss']
})
export class SelectInputComponent implements OnInit, AfterViewInit {
	protected jqElement!: JQuery<HTMLElement>;

	@ViewChild('selectElement') selectElement!: ElementRef;

	// Used to fetch HTML Options and OptionGroups that may have been added in the template
	@ViewChild("contentWrapper", { static: true })
	content!: ElementRef<HTMLElement>;

	isTouched: boolean = false;
	isOpen: boolean = false;
	showError = showError;

	data: Select2OptionData = [];

	ajaxServiceResult = new QueryResult<unknown>();

	/* Selection and storage before appending to the product */
	public selectOptions: Select2Options = {
		width: "100%",
		closeOnSelect: true,
		multiple: false,
		ajax: {
			dataType: "json",
			delay: 250,
			transport: (
				settings: { data: { page?: number; term?: string } },
				success,
				failure
			) => {
				this.clearServerValidation();
				this.ajaxServiceQuery.setPageNumber(settings.data.page || 1);

				if (this.ajaxService) {
					if (settings.data.term) {
						this.ajaxServiceQuery.filter = {
							name: { $like: "%" + settings.data.term + "%" },
						};
					}

					this.ajaxService.list(this.ajaxServiceQuery).subscribe({
						next: (data) => {
							this.ajaxServiceResult = data;
							const result: Select2OptionData = [];
							let hasMore =
								data.count >
								this.ajaxServiceQuery.skip + this.ajaxServiceQuery.limit;

							if (data.rows && data.rows.length) {
								const rowOpts = data.rows.map((row) => ({
									id: row.id,
									text: this.textResolver(
										row as unknown as Record<string, unknown>
									),
								}));
								result.push(...rowOpts);
							}

							success({
								results: result,
								pagination: {
									more: hasMore,
								},
							});
						},
						error: () => failure(),
					});
				} else {
					let results: Select2OptionData = JSON.parse(JSON.stringify(this.data));

					if (results.length === 1 && results[0].id === '' && results[0].text === '') {
						results.splice(0, 1);
					}

					if (settings.data.term) {
						const lCaseTerm = String(settings.data.term).toLowerCase();
						results = results.filter(result => {
							return String(result.id).toLowerCase().includes(lCaseTerm) || String(result.text).toLowerCase().includes(lCaseTerm);;
						});
					}

					success({ results });
				}
			},
		},
	} as Select2Options;

	@Input()
	allowClear = false;

	@Input()
	ajaxServiceQuery = new IQueryFilter();

	/**
	 * @description FormGroup that the target field belongs to
	 */
	@Input()
	form: UntypedFormGroup;

	/**
	 * @description Used to fetch list items asynchronously
	 */
	@Input()
	ajaxService: GenericService;

	/**
	 * @description Name/Key of the target field in the form group
	 */
	@Input()
	field: string;

	@Input()
	disabled: boolean = false;

	/**
	 * @description Label/Placeholder for the input
	 */
	@Input()
	label: string;

	/**
	 * @description {key:string} pairs for the error messages to display for each type of error
	 */
	@Input()
	errors: { [key: string]: unknown } = {};

	@Input()
	id: string;

	/**
	 * @description When an ajax result is found, this function is used to resolve the "text" property
	 *  for any given item
	 */
	@Input()
	textResolver: (val: Record<string, unknown>) => string = (val) =>
		String(val["name"]);

	/**
	 * @description Cannot be changed after init
	 */
	@Input()
	multiple: boolean = false;

	/**
	 * @description Cannot be changed after init
	 */
	@Input()
	tags: boolean = false;

	private _ignoreSelectChanges: boolean = false;

	get fieldId() {
		return this.id || "select_" + this.field;
	}

	get fieldLabel() {
		return this.label || this.field;
	}

	get errorKeys() {
		return Object.keys(this.errors);
	}

	get hasContent() {
		if (!this.hasControl()) return false;

		const val = this.getControl().value;
		return val && val.length;
	}

	get errorsToDisplay() {
		const result = Object.keys(this.errors)
			.filter((errorName) => showError(this.form, this.field, errorName))
			.map((errorName) => this.errors[errorName]);

		return result;
	}

	/**
	 * @description Fetches the value directly off the controller
	 */
	get value() {
		if (!this.jqElement) {
			return null;
		}

		return this.jqElement.val();
	}

	get controlValue() {
		return this.getControl().value;
	}

	constructor(private readonly applicationRef: ApplicationRef) { }

	ngOnInit() {
		this.selectOptions.multiple = this.multiple;
		this.selectOptions.tags = this.tags;
	}

	ngOnDestroy() {
		this.jqElement.off("select2:open");
		this.jqElement.off("select2:close");
		this.jqElement.off("change");
	}

	ngAfterViewInit() {
		const signature = className + '.afterViewInput: ';
		logger.silly(signature + 'Start');
		const control = this.getControl();
		control.updateValueAndValidity();
		this.monitorControlValueChanges();
		this.initSelect2();
	}

	private monitorControlValueChanges() {
		const signature = className + '.monitorControlValueChanges: ';
		if (!this.hasControl()) {
			logger.silly(signature + 'Could not locate control');
			return;
		}

		const control = this.getControl();

		control.valueChanges.subscribe(newVal => {
			const internalVal = this.value;
			if (JSON.stringify(newVal) === JSON.stringify(internalVal)) {
				logger.silly(signature + 'Ignoring cyclic change');
				return;
			}

			logger.silly(signature + `Control Value[${newVal}] Changed. Internal Value[${internalVal}]`);

			this._ignoreSelectChanges = true;

			this.selectElement.nativeElement.innerHTML = '';

			if (control instanceof FormArray) {

				if (newVal instanceof Array) {
					newVal.forEach(valItem => {
						var option = new Option(valItem, valItem, true, true);
						this.selectElement.nativeElement.append(option);
					});
				} else {
					var option = new Option(newVal, newVal, true, true);
					this.selectElement.nativeElement.append(option);
				}
			} else {
				var option = new Option(newVal, newVal, true, true);
				this.selectElement.nativeElement.append(option);
			}

			this.defer(() => this._ignoreSelectChanges = false);
		});

	}

	private initSelect2() {
		const signature = className + '.initSelect2: ';

		this.parseTemplateOptions();

		if (!('jQuery' in window)) {
			logger.warn(signature + `jQuery not available. Element cannot be configured.`);
		}

		this.jqElement = window['jQuery'](this.selectElement.nativeElement);

		this.selectOptions.allowClear = this.allowClear;

		if (this.allowClear) {
			this.selectOptions.placeholder = {
				id: '',
				text: this.label
			}
		};

		this.jqElement.off("select2:open");
		this.jqElement.off("select2:close");
		this.jqElement.off("change");

		this.jqElement.on("select2:open", () => {
			this.isOpen = true;
		});

		this.jqElement.on("select2:close", () => {
			this.isOpen = false;
		});

		this.jqElement.on("change", () => {
			this.onChange();
		});

		this.jqElement.select2(this.selectOptions);

		this.defer(() => this.onChange());

		logger.silly(signature + `Initialized[${this.field}]`);
	}

	/**
	 * @description Used to artifically move an action to the top of the stack
	 * @param func
	 */
	private defer(func: () => void) {
		// Send it to the top of the stack, even if this is potentially dangerous
		setTimeout(func, 0);
	}

	/**
	 * @description Recursively parses HTML Elements to identify options and option groups (to a single level) as Select2Options
	 * @param {HTMLElement & ChildNode} htmlNode
	 * @returns {Select2Option | null}
	 */
	private parseHtmlNode(
		htmlNode: HTMLElement & ChildNode
	): DataFormat | GroupedDataFormat | null {
		const signature = className + ".parseHtmlNode: ";
		const uCaseName = htmlNode.nodeName.toUpperCase();
		if (uCaseName === "OPTION") {
			return this.parseDataFormat(htmlNode);
		} else if (uCaseName === "OPTGROUP") {
			const optText = htmlNode.getAttribute("label") || "";
			const nodeChildren: DataFormat[] = [];

			htmlNode.childNodes.forEach((childNode: HTMLElement & ChildNode) => {
				const option = this.parseDataFormat(childNode);
				if (option) {
					nodeChildren.push(option);
				}
			});

			return {
				text: optText,
				children: nodeChildren,
			};
		} else {
			logger.silly(signature + `Ignorting htmlNode[${uCaseName}]`);
			return null;
		}
	}

	/**
	 * @description Used to parse a HTML Node into a Select Option
	 * @param htmlNode
	 * @returns
	 */
	private parseDataFormat(
		htmlNode: HTMLElement & ChildNode
	): DataFormat | null {
		const signature = className + ".parseDataFormat: ";
		const uCaseName = htmlNode.nodeName.toUpperCase();
		if (uCaseName === "OPTION") {
			const optSelected = Boolean(htmlNode.getAttribute("selected"));
			const optVal =
				htmlNode.getAttribute("value") || htmlNode.textContent || "";
			const optText =
				htmlNode.textContent && htmlNode.textContent.length
					? htmlNode.textContent
					: optVal;

			return {
				id: optVal,
				text: optText,
				selected: optSelected,
			};
		} else {
			logger.silly(signature + `Ignorting htmlNode[${uCaseName}]`);
		}

		return null;
	}

	/**
	 * @description Fetches all the options and optGroups in the contentWrapper and ensures they are in the list of options being displayed
	 */
	private parseTemplateOptions(): void {
		const signature = className + ".parseTemplateOptions: ";
		const options: DataFormat[] = [];

		if (!this.multiple) {
			logger.silly(signature + `Allowing for Blank Selection in Input[${this.field}]`);
			options.push({
				id: "",
				text: "",
				disabled: true,
			});
		}

		if (this.content.nativeElement.hasChildNodes()) {
			this.content.nativeElement.childNodes.forEach(
				(childNode: HTMLElement & ChildNode) => {
					const option = this.parseHtmlNode(childNode);
					if (option) {
						options.push(option as unknown as DataFormat);
					}
				}
			);
		}

		if (
			options.length > 0 &&
			!options.find((opt) => opt.selected)
		) {
			options[0].selected = true;
		}

		logger.silly(signature + `Loaded options from template`);
		this.data = options;
	}

	private hasControl(): boolean {
		return !!(
			this.form &&
			this.form.controls &&
			this.form.controls[this.field]
		);
	}

	/**
	 * @description This intentionally does not attempt to validate the existence of a control and will fail as the application has not been correctly constructed. The form must be supplied
	 *  and be valid.
	 * @returns { AbstractControl }
	 */
	private getControl(): AbstractControl {
		if (!this.form) {
			throw new Error("Expected form property to not be null in SelectInputComponent");
		}

		if (!this.field) {
			throw new Error("Expected field property to not be null in SelectInputComponent");
		}

		return this.form.controls[this.field];
	}

	/**
	 * @description Detects change, emits the value to the control, and clears server validation errors
	 */
	onChange() {
		const signature = className + ".onChange: ";

		if (this._ignoreSelectChanges) {
			return logger.silly(signature + 'Ignoring');
		}

		const val = this.value;
		const control = this.getControl();

		if (control.value !== val) {
			control.markAsDirty();
		}

		if (control instanceof FormArray) {
			while (control.length > 0) {
				control.removeAt(0);
			}

			if (val instanceof Array) {
				val.forEach(valItem => {
					control.push(new FormControl(valItem));
				});
			} else {
				control.push(new FormControl(val));
			}
		} else {
			control.setValue(val);
		}

		control.updateValueAndValidity();
		this.clearServerValidation();
	}

	/**
	 * @description Handles changes in the content being supplied to the componet
	 */
	onContentChange() {
		this.parseTemplateOptions();
	}

	onEditableClick() {
		this.markAsTouched();
	}

	markAsTouched() {
		this.getControl().markAsTouched();
		this.isTouched = true;
	}

	onClick() {
		this.markAsTouched();
	}

	clearServerValidation() {
		const { serverValidation, ...errors } = this.getControl().errors || {};
		this.getControl().setErrors(errors);
	}
}
