import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextInputComponent } from './view/text-input/text-input.component';
import { SectionTitleComponent } from './view/section-title.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContenteditableModelDirective } from './directive/ContenteditableModel';
import { SelectInputComponent } from './view/select-input/select-input.component';
import { RouterModule } from '@angular/router';
import { ServiceEventsComponent } from './view/service-events.component';
import { TrimPipe } from './pipe/trim.pipe';
import { DataGridModule } from './view/data-grid/data-grid.module';
import { SectionHeaderComponent } from './view/section-header.component';
import { UserSelect2Component } from './view/user-select2.component';
import { TabSetComponent } from './view/tab-set/tab-set.component';

@NgModule({
	declarations: [
		SectionTitleComponent,
		TextInputComponent,
		ServiceEventsComponent,
		ContenteditableModelDirective,
		SectionHeaderComponent,
		TrimPipe,
		SelectInputComponent,
		UserSelect2Component,
		TabSetComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ObserversModule,

		DataGridModule,

		RouterModule
	],
	providers: [],
	exports: [
		CommonModule,
		SectionTitleComponent,
		SectionHeaderComponent,
		TextInputComponent,
		FormsModule,
		ReactiveFormsModule,
		ServiceEventsComponent,
		TrimPipe,
		SelectInputComponent,
		DataGridModule,
		UserSelect2Component,
		TabSetComponent
	]
})
export class UiModule { }
