import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorMessage, FieldRejection } from '../../model/api.model';
import { CommonControllerComponent } from '../../util/common.controller';
import { isString } from '../../util/utils';
import { ClientService } from '../../services/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { hasKey } from '../../util/object.util';
import { IClient } from '../../model/client.model';
import { dobValidator } from './client-edit.validators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientNoteService } from '../../services/client-note.service';
import { JwtService } from '../../services/jwt.service';
import { NotificationsService } from 'angular2-notifications';
import { IClientNote } from '../../model/client-note.model';
import { logger } from '../../util/Logger';
import { isGoogleMapAvailable } from '../../util/google.util';
import { getAllFormErrors } from '../../util/formUtils';

const className = "ClientEditComponent";
type NoteType = 'medical' | 'general';
declare var google;
declare var $;

@Component({
	selector: 'app-client-edit',
	templateUrl: 'client-edit.component.html',
	styleUrls: ['client-edit.component.scss']
})
export class ClientEditComponent extends CommonControllerComponent implements AfterViewInit {
	id: string | null = null;
	public today: Date = new Date();
	public todayDate: string = `${this.today.getUTCFullYear()}-${((this.today.getUTCDay() + 1)) < 10 ? '0' : ''}${(this.today.getUTCDay() + 1)}-${(this.today.getUTCDate())}`;
	clientForm: FormGroup;
	fieldRejections: FieldRejection[] = [];
	public clientNotesList: IClientNote[] = [];
	public selectedType: NoteType;
	@ViewChild('addresstext') addresstext!: ElementRef<HTMLInputElement>;
	public noteForm: FormGroup;
	public selectedNotes: string = "";
	public pastActivityData: { name: string, createdAt: string, id: string, clientId: string, linkName: string }[];
	public isDob: boolean;
	public search: string;
	public currentPage: string;

	constructor(
		private formBuilder: FormBuilder,
		private clientService: ClientService,
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private clientNoteService: ClientNoteService,
		private jwtService: JwtService,
		private notifications: NotificationsService
	) {
		super();

		let createdBy = this.jwtService.currentJwtPayload$.getValue();
		this.noteForm = this.formBuilder.group({
			createdBy: [createdBy?.user.id, [Validators.required,]],
			notes: [null, [Validators.required,]],
			notesType: [null, [Validators.required,]],
			clientId: [this.id, [Validators.required,]],
		});

		this.clientForm = this.formBuilder.group({
			firstName: [null, [Validators.required, Validators.maxLength(250)]],
			lastName: [null, [Validators.required, Validators.maxLength(250)]],
			alias1: [null, [Validators.maxLength(250)]],
			alias2: [null, [Validators.maxLength(250)]],
			isDob: [false, [dobValidator]],
			dob: [null, [dobValidator]],
			gender: [null, [Validators.required, Validators.maxLength(250)]],
			highRisk: [null, []],
			culturalIdentity: ['Aboriginal', [Validators.required, Validators.maxLength(250)]],
			phone: [null, [Validators.maxLength(250)]],
			email: [null, [Validators.email, Validators.maxLength(250)]],
			address: [null, [Validators.maxLength(250)]],
			isAddressTransitional: [false, []],
			isAddressOutOfArea: [false, []],
			isHomeless: [false, []],
			isAddressUnkown: [false, []],
			isBowmanJohnson: [false, []],
			startDate: [null, []],
			endDate: [null, []],
			roomNo: [null, [Validators.maxLength(250)]],
			isMaster: [false, []]
		});

		this.route.queryParamMap.subscribe((queryParams) => {
			const searchParam = queryParams.get('search');
			if (searchParam) {
				this.search = searchParam
			}
			const pageParam = queryParams.get('page');
			if (pageParam) {
				this.currentPage = pageParam;
			}
		});

		this.route.params.subscribe({
			next: params => {
				if (hasKey(params, 'id') && isString(params.id)) {

					this.clientService.clientSearchListing({ page: 0, limit: 10, search: "", listType: 'client', clientId: params.id }).subscribe(async (response: any) => {
						if (response && response.status) {
							let client = response.data.rows;
							if (client && client.length) {
								client = response.data.rows.shift();

								this.pastActivities(client);
								this.id = client.id;
								this.listClientNotes();

								if (client.startDate) {
									let startDate: string = client.startDate;
									client.startDate = startDate.split('T').shift() || "";
								}
								if (client.endDate) {
									let endDate: string = client.endDate;
									client.endDate = endDate.split('T').shift() || "";
								}
								if (client.dob) {
									let dob: string = client.dob;
									client.dob = dob.split('T').shift() || "";
								}
								if (!client.address) {
									this.clientForm.controls['address'].setValue("");
								}

								this.clientForm.patchValue(client);

								if (!this.clientForm.get('culturalIdentity')?.value) {
									const culturalIdentityControl = this.clientForm.get('culturalIdentity');
									culturalIdentityControl?.clearValidators();
									culturalIdentityControl?.updateValueAndValidity();
								}

								this.isDob = client.isDob || !client.dob || client.dob.length === 0;

								if (this.isDob) {
									this.clientForm.controls['dob'].disable();
								}

								this.noteForm.controls['clientId'].setValue(String(client.id));
							} else {
								this.notifications.warn("ERROR", "Eroor in page...", {});
								this.router.navigate(['/clients'])
							}
						} else {
							this.notifications.warn("ERROR", response.message || "", {});
						}
					}, err => {
						console.error(err);
						this.notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
					})
				}
			}
		});
	}

	ngAfterViewInit() {
		this.getPlaceAutocomplete();
	}

	changeDOB(val: Event) {
		this.clientForm.controls['dob'].setValue("");
		if ((val.target as HTMLInputElement).checked) {
			this.clientForm.controls['dob'].disable();
		} else {
			this.clientForm.controls['dob'].enable();
		}
		this.clientForm.controls['dob'].updateValueAndValidity();
	}

	removeAddress(val: Event) {
		this.clientForm.controls['address'].setValue("");
		if ((val.target as HTMLInputElement).checked) {
			this.clientForm.controls['address'].disable();
		} else {
			this.clientForm.controls['address'].enable();
		}
	}

	uncheckOther(val: string, event: Event) {
		let isValid = (event.target as HTMLInputElement).checked
		this.clientForm.controls['isAddressTransitional'].setValue(false);
		this.clientForm.controls['isAddressOutOfArea'].setValue(false);
		this.clientForm.controls['isHomeless'].setValue(false);
		this.clientForm.controls['isAddressUnkown'].setValue(false);
		if (isValid == true) {
			this.clientForm.controls[val].setValue(true);
			this.clientForm.updateValueAndValidity();
		}
		if (val === 'isAddressTransitional') {
			this.clientForm.controls['address'].enable();
		}
	}


	// https://medium.com/@dhormale/use-google-places-api-autocomplete-using-angular-for-resident-and-office-address-23cc33078e8
	private getPlaceAutocomplete() {
		const signature = className + '.getPlaceAutocomplete:';

		if (!isGoogleMapAvailable()) {
			logger.error(signature + `API for GoogleAutoComplete not currently available`);
			return;
		}

		const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
			{
				componentRestrictions: { country: 'AU' },
			});
		google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const place = autocomplete.getPlace();
			this.clientForm.controls['address'].setValue(`${place.name}, ${place.formatted_address}`);
			this.clientForm.controls['isAddressTransitional'].setValue(false);
			this.clientForm.controls['isAddressOutOfArea'].setValue(false);
			this.clientForm.controls['isHomeless'].setValue(false);
			this.clientForm.controls['isAddressUnkown'].setValue(false);
		});
	}

	save() {
		if (!this.clientForm.valid) {
			logger.error(getAllFormErrors(this.clientForm));
			return;
		}

		const formData = this.clientForm.value;

		[
			'address',
			'startDate',
			'endDate'
		].forEach(nullableField => {
			if (!formData[nullableField]) {
				formData[nullableField] = null;
			}
		});

		if (formData['isDob']) {
			formData['dob'] = null;
		}

		const saveMethod = this.id ? this.clientService.update(this.id, formData) : this.clientService.create(formData);

		saveMethod.subscribe({
			next: () => {
				this.router.navigate(['/clients'], {
					queryParams: { search: this.search, page: this.currentPage },
				})
			},
			error: (err: ErrorMessage) => this.handleValidationErrors(err, this.clientForm, this.fieldRejections),
			complete: () => this.clientForm.markAsUntouched()
		});
	}

	addNew(content, selectedType: NoteType) {
		this.selectedType = selectedType;
		this.modalService.open(content, {
			scrollable: true,
			centered: true,
			size: 'lg'
		});
	}

	editNote(contentEdit, selectedNotes: string) {
		this.selectedNotes = selectedNotes;
		this.modalService.open(contentEdit, { scrollable: true, centered: true, size: 'lg' });
	}

	createClientNotes() {
		this.noteForm.controls['notesType'].setValue(String(this.selectedType));
		if (!this.noteForm.valid) {
			return;
		}

		this.clientNoteService.create(this.noteForm.value).subscribe({
			next: () => {
				// this.router.navigate(['/clients']);
				this.listClientNotes();
				this.modalService.dismissAll();
			},
			error: (err: ErrorMessage) => this.handleValidationErrors(err, this.noteForm, this.fieldRejections),
			complete: () => this.noteForm.markAsUntouched()
		});
	}

	listClientNotes() {
		this.clientNoteService.clientApi.clientNoteListing({
			clientId: this.id,
		}).subscribe({
			next: (resp) => {
				if (resp.status) {
					this.clientNotesList = resp.data;
				}
			},
			error: (err: ErrorMessage) => this.handleValidationErrors(err, this.noteForm, this.fieldRejections),
			complete: () => this.noteForm.markAsUntouched()
		});
	}

	formatContactNumber(inputValue: String): String {
		const value = inputValue.replace(/[^0-9]/g, ''); // remove except digits
		let format = '** **** ****'; // You can change format

		for (let i = 0; i < value.length; i++) {
			format = format.replace('*', value.charAt(i));
		}

		if (format.indexOf('*') >= 0) {
			format = format.substring(0, format.indexOf('*'));
		}

		this.clientForm.controls['phone'].setValue(format.trim());
		return format.trim();
	}

	pastActivities(client: IClient) {
		let pastActivities: { name: string, createdAt: string, id: string, clientId: string, linkName: string }[] = []
		if (client.cellVisits && client.cellVisits.length)
			client.cellVisits.map((elem: { timeIn: string, clientId: string, id: string }) => {
				pastActivities.push({
					name: 'Cell Visit',
					createdAt: elem.timeIn,
					id: elem.id,
					clientId: elem.clientId,
					linkName: `cell`,
				})
			})
		if (client.communityPatrols && client.communityPatrols.length)
			client.communityPatrols.map((elem: { timeIn: string, clientId: string, id: string }) => {
				pastActivities.push({
					name: 'Community Patrol',
					createdAt: elem.timeIn,
					id: elem.id,
					clientId: elem.clientId,
					linkName: `community`,
				})
			})
		if (client.diversionCenters && client.diversionCenters.length)
			client.diversionCenters.map((elem: { timeIn: string, clientId: string, id: string }) => {
				pastActivities.push({
					name: 'Diversion Centre',
					createdAt: elem.timeIn,
					id: elem.id,
					clientId: elem.clientId,
					linkName: `diversion`,
				})
			})

		this.pastActivityData = pastActivities.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
	}
}
