<div class="tab-theme tab-dashboard">
  <app-tab-set #tabSet [tabs]="['Diversion Centre','Cell Visits','Community Patrol']"></app-tab-set>

  <div class="tab-content">
    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0}"> 
      <app-diversion-centre-list type='dashboard'></app-diversion-centre-list>
    </div>
    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1}">
      <app-cell-visit-list></app-cell-visit-list>
    </div>
    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 2}">
      <app-community-patrol-list></app-community-patrol-list>
    </div> 
  </div>
</div>