// shared.module.ts
import { NgModule } from '@angular/core';
import { PaginationComponent } from './main/template/pagination/pagination.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [ PaginationComponent ],
  exports: [PaginationComponent],
})

export class SharedModule {}
