import { Component } from '@angular/core';
import { Router } from '@angular/router';

type ServiceTabState = 'list' | 'edit';

@Component({
  selector: 'app-community-patrol',
  templateUrl: 'community-patrol.component.html',
  styleUrls: ['community-patrol.component.scss']
})
export class CommunityPatrolComponent {
  public tabState: ServiceTabState = 'list';

  closeResult: string;

  constructor(
    private __router: Router,
  ) {
  }

  selectClient(itm: any) {
    let { data, listType } = itm;
    let communityId = (data.communityPatrols && data.communityPatrols.length > 0) ? data.communityPatrols.shift() : '';
    if (communityId) {
      communityId = communityId.id;
    }
    this.__router.navigate([`/community-patrol/edit`, data.id], { queryParams: { communityId: communityId } });
  }

}
