export const environment = {
    production: true,
    region: 'ap-southeast-2',
    endpoint: 'https://api.ew.digitalp.com.au',
    logLevel: 'debug',
    buildId: process.env.NG_APP_BUILD_ID || 'local',
    defaultCollectionLimit: 20,
    enableSentry: true,
    nextview_url: 'https://quality.murri.wkdigital.com.au',
    timeZone: 'Australia/Brisbane'
};
