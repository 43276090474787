import { Component } from '@angular/core';
import { InactivityService } from '../../services/inactivity.service';
import { Router } from '@angular/router';
import { JwtService } from '../../services/jwt.service';

@Component({
	selector: 'app-pin',
	templateUrl: './pin.component.html',
	styleUrls: []
})
export class PinComponent {
	pin: string | null;
	userPin: string | null;
	error: string;

	constructor(
		private inactivityService: InactivityService,
		private jwtService: JwtService,
		private router: Router,
	) {
		this.userPin = inactivityService.getPin();
	}

	submitPin(): void {
		if (this.pin == this.userPin) {
			this.inactivityService.resetInactivityTimer();
			this.pin = null;
		} else {
			this.error = "Invalid Pin";
		}
	}

	logout() {
		this.inactivityService.resetInactivityTimer(true);
		this.pin = null;

		this.jwtService.removeJWTData();
		this.router.navigate(['/login']);
	}
}
