import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from '../services/location.service';
import { ILocation, ILocationTag } from '../model/location.model';
import { NotificationsService } from 'angular2-notifications';
import { FormControl, FormGroup } from '@angular/forms';
import { serverSide } from '../util/utils';
import { FieldRejection } from '../model/api.model';
import { EReportType } from '../model/report.model';
import { ReportService } from '../services/report.service';
import download from 'js-file-download';
import { logger } from '../util/Logger';

const className = "ReportComponent";

@Component({
	selector: 'app-report',
	templateUrl: 'report.component.html',
	styleUrls: ['report.component.scss']
})
export class ReportComponent implements OnInit {
	reportTypes = EReportType;
	reportTypeKeys = Object.keys(EReportType);

	fieldRejections: FieldRejection[] = [];
	locationList: ILocation[] = [];
	tagList: ILocationTag[] = [];

	form: FormGroup = new FormGroup({
		locationIds: new FormControl([], serverSide(this.fieldRejections, 'locationIds')),
		fromDate: new FormControl(null, serverSide(this.fieldRejections, 'fromDate')),
		toDate: new FormControl(null, serverSide(this.fieldRejections, 'toDate')),
		minAge: new FormControl(null, serverSide(this.fieldRejections, 'minAge')),
		maxAge: new FormControl(null, serverSide(this.fieldRejections, 'maxAge')),
		reportType: new FormControl('combined_department', serverSide(this.fieldRejections, 'reportType')),
		tags: new FormControl([], serverSide(this.fieldRejections, 'tags'))
	});

	constructor(
		private route: Router,
		private __locationService: LocationService,
		private _notifications: NotificationsService,
		private reportService: ReportService
	) { }

	ngOnInit(): void {
		this.getLocations();
	}

	getLocations() {
		this.__locationService.list({
			limit: 1000,
			sortBy: 'name'
		}).subscribe({
			next: response => {
				this.locationList = response.rows;

				const tagMap = this.locationList
					.reduce((result, location) => {
						result.push(...(location.tags || []));
						return result;
					}, [] as string[])
					.reduce((result, tag) => {
						const key = tag.toLowerCase();
						if (!(key in result)) {
							result[key] = tag;
						}

						return result;
					}, {} as Record<string, string>);
				this.tagList = Object.keys(tagMap).map(key => tagMap[key]).sort();
			},
			error: err => {
				console.error(err);
				this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
			}
		});
	}

	downloadReport() {
		const signature = className + '.downloadReport: ';
		this.reportService.getReport(this.form.value).subscribe({
			next: data => {
				const fileName = this.generateFilename();
				logger.silly(signature + `Downloading File[${fileName}]`);
				download(data, fileName);
			}
		});
	}

	private generateFilename(): string {
		const fromDate: string = this.form.get('fromDate')?.value || '';
		const toDate: string = this.form.get('toDate')?.value || '';
		const reportType: string = this.form.get('reportType')?.value || '';

		let filename: string = `${fromDate}-${toDate}-${reportType}-report.csv`;

		// Remove any undefined or empty values and trailing hyphens
		filename = filename.replace(/-+/g, '-').replace(/(^-)|(-$)/g, '');

		return filename;
	}


	updateLocationIds(val: string | number | string[] | undefined) {
		const select2ValMatcher = /^[0-9]+:\s'([^']+)'$/
		const arrVal = (Array.isArray(val) ? val : [val])
			// Get rid of blanks
			.filter(val => val !== undefined && val !== null)
			// Guarantee strings
			.map(val => String(val))
			// Get rid of the weird select2 formatting
			.map(val => val.match(select2ValMatcher) ? val.replace(select2ValMatcher, '$1') : val);

		this.form.controls['locationIds'].setValue(arrVal)
	}
}
