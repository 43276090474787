import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IClient } from '../model/client.model';
import { createUrl } from './api.util';

@Injectable()
export class ClientApi extends GenericApi<IClient> {
  public path = "client";
  public path2 = "clientmerge"

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  /**
   * clientSearchListing
   */
  public clientSearchListing(credentials: {}) {
    return this.httpClient.post<{
      status: boolean,
      message: string,
      data: {
        count: number,
        rows: unknown[]
      }
    }>(createUrl(this.path, 'clientSearchListing'), credentials);
  }

  public clientMerge(credentials: {}) {
    return this.httpClient.post<{
      status: boolean,
      message: string,
      data: {
        rows: unknown[]
      }
    }>(createUrl(this.path2), credentials);
  }
}