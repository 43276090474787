<div class="formGroup p-2" [formGroup]="form">
  <div class="form-floating mb-2">
    <input #textInput [value]="getControl().value" [id]="fieldId" [type]="type"
      (change)="onEditableChange(textInput.value)" [ngClass]="{hasContent: hasContent}" (keydown)="onKeyDown()"
      (keyup)="onKeyUp($event)" (click)="onClick()" class="form-control" [attr.disabled]="disabled ? 'disabled': null"
      placeholder={{fieldLabel}}>
    <label [for]="fieldId">{{ fieldLabel }}</label>
  </div>
  <div appContenteditableModel *ngIf="multiline" [id]="fieldId" [attr.disabled]="disabled ? 'disabled': null" [ngClass]="{
      hasContent: hasContent, 
      textEdit: true,
      'outline-none': true,
      'ng-touched': isTouched
    }" contentEditable="true" [appContenteditableModel]="getControl().value"
    (contenteditableModelChange)="onEditableChange($event)" (click)="onEditableClick()"></div>
  <div *ngIf="errorsToDisplay.length else noError">
    <span class="span error" *ngFor="let errorText of errorsToDisplay">{{ errorText }}</span>
  </div>
  <ng-template #noError>
    <div></div>
  </ng-template>
</div>