import { slideInAnimation } from "../../util/animations";
import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { logger } from "../../util/Logger";
import { Angulartics2GoogleTagManager } from "angulartics2";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { interval } from "rxjs";
import { environment } from "src/environments/environment";
import { NotificationsService } from "angular2-notifications";
import { v4 as uuidv4 } from 'uuid';
import { ConnectionServiceService } from "../../services/connection-service.service";

const className = "MainTemplateComponent";
const versionCheckInterval = 600000;
declare let ga: Function;

@Component({
	animations: [slideInAnimation],
	selector: 'app-main-template',
	templateUrl: 'main-template.component.html',
	styleUrls: ['main-template.component.scss']
})
export class MainTemplateComponent implements OnInit {

	year: string = new Date().getFullYear().toString();
	showOfflineNotification: boolean = false;

	constructor(
		private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
		private router: Router,
		public readonly httpClient: HttpClient,
		public notifications: NotificationsService,
		public readonly connectionService: ConnectionServiceService
	) {
		this.configureGoogleAnalytics();
	}

	ngOnInit() {
		this.blurNumberInputOnWheel();
		this.performVersionNumberCheck();
		this.monitorVersionNumber();

		window['offerUpgrade'] = (upgradeVersion: string) => {
			this.offerUpgrade(upgradeVersion);
		}

		this.connectionService.getOnlineOfflineStatus().subscribe({
			next: status => {
				this.showOfflineNotification = !status;
			}
		});
	}

	private configureGoogleAnalytics() {
		const signature = className + '.configureGoogleAnalytics: ';
		if (!location.protocol.match(/^https/)) {
			logger.silly(signature + 'Analytics not installed due to insecure protocol');
			return;
		}

		this.angulartics2GoogleTagManager.startTracking();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (typeof ga === 'undefined') {
					logger.warn(signature + 'Unable to find analytics function');
					return;
				}
				ga('set', 'page', event.urlAfterRedirects);
				ga('send', 'pageview');
			}
		});
	}

	prepareRoute(outlet: RouterOutlet, data: string) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData[data];
	}

	blurNumberInputOnWheel() {
		const signature = className + ".blurNumberInput: ";
		document.addEventListener("wheel", function () {
			if (
				document.activeElement
				&& document.activeElement["type"]
				&& document.activeElement["type"] === "number"
				&& document.activeElement["blur"]
			) {
				logger.silly(signature + "Prevented mWheel in Number Input");
				document.activeElement["blur"]();
			}
		});
	}

	/**
	 * @description Periodically triggers a version number check
	 * @returns {void}
	 */
	monitorVersionNumber() {
		// Every 10 minutes
		interval(versionCheckInterval).subscribe(() => this.performVersionNumberCheck());
	}

	performVersionNumberCheck() {
		const signature = className + ".performVersionNumberCheck: ";
		logger.debug(signature + `Verifying application Version[${environment.buildId}]`);

		if (String(environment.buildId).toLowerCase() === 'local') {
			logger.debug(signature + "Ignoring version check in local environment");
			return;
		}

		this.httpClient.get('/version.txt', {
			responseType: 'text',
			headers: new HttpHeaders({
				'Cache-Control': 'no-cache'
			})
		}).subscribe({
			next: resp => {
				const currentVersion = resp.trim();
				const appVersion = environment.buildId.trim();
				if (currentVersion !== appVersion) {
					logger.info(signature + `Version[${appVersion}] does not match CurrentVersion[${currentVersion}]`);
					this.offerUpgrade(currentVersion.replace(/^.*?-/, ''));
				} else {
					logger.info(signature + `Version[${appVersion}] is current`);
				}
			},
			error: err => {
				if (err instanceof HttpErrorResponse && err.status === 404) {
					logger.info(signature + 'Version verification failed due to missing version file');
					return;
				}

				throw err;
			}
		});
	}

	offerUpgrade(newVersion: string) {
		const signature = className + ".offerUpgrade: ";
		logger.info(signature + `Offering upgrade to Version[${newVersion}]`);

		const notification = this.notifications.warn(
			'Updated Version Available',
			'Version ' + newVersion + ' now is available. Click here to reload from the server.',
			{
				timeOut: (versionCheckInterval - Math.min(Math.floor(versionCheckInterval / 2), 5000)),
				pauseOnHover: true
			}
		);

		// @ts-ignore
		notification.click.subscribe(() => {
			this.httpClient.get('/index.html', {
				params: {
					v: uuidv4()
				},
				responseType: 'text',
				headers: new HttpHeaders({
					'Cache-Control': 'no-cache'
				})
			}).subscribe({
				next: () => {
					window.location.reload();
				}
			});
		});
	}
}
