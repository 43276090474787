<div *ngIf="!isOffline; else onlineState">
  <div *ngIf="listType === 'cell'">
    <div class="diversionCentre-clients">
      <form novalidate class="was-validated" (ngSubmit)="saveOfflineCellVisit(cellForm)" [formGroup]="cellForm">
        <div>
          <div class="accordion accordion-theme" id="accordionExample">
            <div class="accordion-item">
              <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo">
                <div class="accordion-body">
                  <section class="cellVisits-clients">
                    <div class="row g-3 g-xl-4 align-items-center">
                      <div class="col-md-6">
                        <div class="form-consent-heading">
                          <label class="text-label form-label">First Name:</label>
                          <div class="form-consent-body"><input name="firstName" placeholder="" type="text"
                            class="form-fields pt-0 form-control" formControlName="firstName"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-consent-heading">
                          <label class="text-label form-label">Last Name:</label>
                          <div class="form-consent-body"><input name="lastName" placeholder="" type="text"
                            class="form-fields pt-0 form-control"formControlName="lastName"></div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-consent-heading">
                          <label class="text-label form-label">Client Email:</label>
                          <div class="form-consent-body"><input name="clientEmail" placeholder="" type="email"
                            class="form-fields pt-0 form-control" formControlName="email"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                        <label for="inCharge">Officer In Charge:</label>
                        <input type="text" class="form-control" formControlName="inCharge" id="inCharge">
                      </div>
                    </div>
                    </div>
                    <div class="bg-gray form-theme box-full py-xl-4 py-md-3 py-4">
                      <div class="row g-3 g-xl-4">
                        <div class="col-xl-4"><label>Does the client have any belongings on them (Including
                            medication)?</label></div>
                        <div class="col-md-2">
                          <div class="form-check mb-0">
                            <input type="radio" value="false" id="isBelonging_no" class="form-check-input"
                              formControlName="isBelonging" (click)="changeIsBelonging($event)">
                            <label for="isBelonging_no" class="form-check-label"> No </label>
                          </div>
                        </div>
                        <div class="col-xl-6 col-md-10">
                          <div class="form-check mb-2">
                            <input type="radio" value="true" id="isBelonging_yes" class="form-check-input"
                              formControlName="isBelonging" (click)="changeIsBelonging($event)">
                            <label for="isBelonging_yes" class="form-check-label"> Yes </label>
                          </div>
                          <textarea [ngClass]="{'is-invalid':cellForm.controls['belongingComment'].errors}"
                            class="form-control" rows="2" formControlName="belongingComment"
                            [required]="belongingCommentRequired" id="belongingComment"></textarea>
                          <div class="invalid-feedback" *ngIf="belongingCommentRequired">
                            belonging comment is required</div>
                        </div>
                      </div>
                    </div>

                    <div class="form-theme py-xl-4 py-md-3 py-2">
                      <div class="row g-3 g-xl-4">
                        <div class="col-xl-4">
                          <label>
                            Are there any "No contact" Domestic Violence Orders in place?
                          </label>
                        </div>
                        <div class="col-md-2">
                          <div class="form-check mb-0">
                            <input type="radio" value="false" id="isViolence_no" class="form-check-input"
                              formControlName="isViolence" (click)="changeIsViolence($event)">
                            <label for="isViolence_no" class="form-check-label"> No </label>
                          </div>
                        </div>
                        <div class="col-xl-1 col-md-2">
                          <div class="form-check mb-0">
                            <input type="radio" value="true" id="isViolence_yes" class="form-check-input"
                              formControlName="isViolence" (click)="changeIsViolence($event)">
                            <label for="isViolence_yes" class="form-check-label"> Yes </label>
                          </div>
                        </div>
                        <div class="col-xl-5 col-md-8">
                          <table class="table-addMore w-100 " formArrayName="domesiticViolances">

                            <tr *ngFor="let quantity of domesiticViolances().controls; let i=index" [formGroupName]="i">
                              <td>
                                <input type="text" formControlName="violancerName" id="violancerName_{{i}}"
                                  class="form-control">
                              </td>
                              <td>
                                <div (click)="removeDomesiticViolance(i)">
                                  <svg class="close-svg ms-2 float-right" focusable="false" viewBox="0 0 24 24"
                                    aria-hidden="true">
                                    <path
                                      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                                    </path>
                                  </svg>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2">
                                <button type="button" (click)="addDomesiticViolance()" class="btn btn-success mnw-auto">
                                  Add More
                                </button>
                              </th>
                            </tr>
                          </table>
                        </div>
                        <div class="col-xl-5 col-md-8">
                        </div>
                      </div>
                    </div>
                    <div class="bg-gray form-theme box-full py-xl-4 py-md-3 py-2">
                      <div class="row g-3 g-xl-4">
                        <div class="col-xl-4"><label>Does the client have any known medical conditions?</label></div>
                        <div class="col-md-2">
                          <div class="form-check mb-0">
                            <input type="radio" value="false" id="isMedical_no" class="form-check-input"
                              formControlName="isMedicalCondition">
                            <label for="isMedical_no" class="form-check-label"> No </label>
                          </div>
                        </div>
                        <div class="col-xl-6 col-md-10">
                          <div class="form-check mb-2">
                            <input type="radio" value="true" id="isMedical_yes" class="form-check-input"
                              formControlName="isMedicalCondition">
                            <label for="isMedical_yes" class="form-check-label">
                              Yes. If yes, you must complete the Client needs and risk identification form
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="medical-block border border-secondary p-3 m-3"
                        *ngIf="cellForm.controls['isMedicalCondition'].value === 'true'">
                        <div class="py-xl-4 py-md-3 py-2">
                          <div class="row  g-3 g-xl-4">
                            <div class="col-xl-4"><label>Does an ambulance need to be contacted?</label></div>
                            <div class="col-md-2">
                              <div class="form-check mb-0">
                                <input type="radio" value="false" id="isAmbulance_no" class="form-check-input"
                                  formControlName="isAmbulance">
                                <label for="isAmbulance_no" class="form-check-label"> No </label>
                              </div>
                            </div>
                            <div class="col-xl-6 col-md-10">
                              <div class="form-check mb-2">
                                <input type="radio" value="true" id="isAmbulance_yes" class="form-check-input"
                                  formControlName="isAmbulance">
                                <label for="isAmbulance_yes" class="form-check-label"> Yes </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="py-xl-4 py-md-3 py-2">
                          <div class="row  g-3 g-xl-4">
                            <div class="col-xl-4"><label>Is the client on any medication?</label></div>
                            <div class="col-md-2">
                              <div class="form-check mb-0">
                                <input type="radio" value="false" id="isOnMedication_no" class="form-check-input"
                                  formControlName="isOnMedication" (click)="changeIsOnMedication($event)">
                                <label for="isOnMedication_no" class="form-check-label"> No </label>
                              </div>
                            </div>
                            <div class="col-xl-6 col-md-10">
                              <div class="form-check mb-2">
                                <input type="radio" value="true" id="isOnMedication_yes" class="form-check-input"
                                  formControlName="isOnMedication" (click)="changeIsOnMedication($event)">
                                <label for="isOnMedication_yes" class="form-check-label">
                                  Yes. If yes name the medication
                                </label>
                              </div>
                              <textarea [ngClass]="{'is-invalid':cellForm.controls['medicationName'].errors}"
                                class="form-control" rows="2" formControlName="medicationName"
                                [required]="medicationNameRequired" id="medicationName"></textarea>
                              <div class="invalid-feedback" *ngIf="medicationNameRequired">
                                medication name is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="py-xl-4 py-md-3 py-2">
                          <div class="row  g-3 g-xl-4">
                            <div class="col-xl-4"><label>Do arrangements need to be made for medication to be
                                provided to
                                the
                                client?</label></div>
                            <div class="col-md-2">
                              <div class="form-check mb-0">
                                <input type="radio" value="0" id="isArrangements_no" class="form-check-input"
                                  formControlName="isArrangements">
                                <label for="isArrangements_no" class="form-check-label"> No </label>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-check mb-2">
                                <input type="radio" value="1" id="isArrangements_unknown" class="form-check-input"
                                  formControlName="isArrangements">
                                <label for="isArrangements_unknown" class="form-check-label"> Unknown </label>
                              </div>
                            </div>
                            <div class="col-xl-4 col-md-8">
                              <div class="form-check mb-2">
                                <input type="radio" value="2" id="isArrangements_yes" class="form-check-input"
                                  formControlName="isArrangements">
                                <label for="isArrangements_yes" class="form-check-label">
                                  Yes. If yes, arrange for client to be taken to a doctor.
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="py-xl-4 py-md-3 py-2">
                          <div class="row  g-3 g-xl-4">
                            <div class="col-xl-4"><label>If applicable when did the client last take their
                                medication?</label></div>
                            <div class="col-xl-4 col-md-6 ">
                              <input type="date" value="1" id="medicationDate" class="form-control"
                                formControlName="medicationDate">
                            </div>
                            <div class="col-xl-4 col-md-6 ">
                              <input type="time" value="2" id="medicationTime" class="form-control"
                                formControlName="medicationTime">
                            </div>
                          </div>
                        </div>
                        <div class="pt-4">
                          <h4>GENERAL OBSERVATIONS</h4>
                        </div>
                        <div class="py-xl-4 py-md-3 py-2">
                          <div class="row  g-3 g-xl-4">
                            <div class="col-xl-4"><label>Is the client currently showing any signs of aggression e.g.
                                spitting, swearing, hitting, punching?</label></div>
                            <div class="col-md-2">
                              <div class="form-check mb-0">
                                <input type="radio" value="false" id="isAggression_no" class="form-check-input"
                                  formControlName="isAggression">
                                <label for="isAggression_no" class="form-check-label">
                                  No
                                </label>
                              </div>
                            </div>
                            <div class="col-xl-6 col-md-10">
                              <div class="form-check mb-2">
                                <input type="radio" value="true" id="isAggression_yes" class="form-check-input"
                                  formControlName="isAggression">
                                <label for="isAggression_yes" class="form-check-label">
                                  Yes. If yes, don not approach the client - First assess whether a medical and/or
                                  police
                                  response is required.
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="py-xl-4 py-md-3 py-2">
                          <div class="row  g-3 g-xl-4">
                            <div class="col-xl-4">
                              <label for="symptoms_1">Does the client have any of the following symptoms?</label>
                            </div>
                            <table class="col-md" formArrayName="medicalObservations">
                              <tr *ngFor="let quantity of medicalObservations().controls; let i=index"
                                [formGroupName]="i">
                                <td colspan="2">
                                  <div class="col-md p-2">
                                    <select class="form-select" id="symptoms_{{i}}" formControlName="symptoms"
                                      (change)="symptomsSelected($event)">
                                      <ng-container *ngFor="let prm of symptomsList">
                                        <option value="{{prm.value}}">
                                          {{prm.label}}
                                        </option>
                                      </ng-container>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div (click)="removeMedicalObservations(i)">
                                    <svg class="close-svg ms-2 float-right" focusable="false" viewBox="0 0 24 24"
                                      aria-hidden="true">
                                      <path
                                        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                                      </path>
                                    </svg>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th class="col-auto">
                                  <button type="button" (click)="addMedicalObservations()"
                                    class="btn btn-success mnw-auto">Add
                                    More
                                  </button>
                                </th>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-theme py-xl-4 py-md-3 py-2">
                      <div class="row g-3 g-xl-4 align-items-center">
                        <div class="col-xl-4">
                          <label>Was service refused?</label>
                        </div>
                        <div class="col-md-2">
                          <div class="form-check mb-2">
                            <input (change)="changeServerRefusedBy($event)" type="radio" value="false" id="noRefused"
                              class="form-check-input" formControlName="isServerRefused">
                            <label for="noRefused" class="form-check-label"> No </label>
                          </div>
                          <div class="form-check mb-0">
                            <input (change)="changeServerRefusedBy($event)" type="radio" value="true" id="yesRefused"
                              class="form-check-input" formControlName="isServerRefused">
                            <label for="yesRefused" class="form-check-label"> Yes </label>
                          </div>
                        </div>
                        <div class="col-xl-6 col-md-10">
                          <select class="form-select" formControlName="serverRefusedBy" id="serverRefusedBy">
                            <option value="1">By MurriWatch</option>
                            <option value="2">By Client</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="bg-gray form-theme box-full py-xl-4 py-md-3 py-2">
                      <div class="mb-30">
                        <h4>CLIENT RECORD</h4>
                        <p><strong>Actions taken -</strong> Physical observations; observations of client wellbeing;
                          who,
                          what, when, outcome.</p>
                      </div>
                      <div class="mb-xl-4 mb-md-3 mb-2">
                        <input type="text" class="form-control" formControlName="actionTaken" id="actionTaken">
                      </div>
                      <div class="">
                        <label for="clientComments">Comments:</label>
                        <textarea class="form-control" rows="3" formControlName="clientComments"
                          id="clientComments"></textarea>
                      </div>
                    </div>

                    <div class="pt-xl-4 pt-md-3 pt-2">
                      <div class="row justify-content-end g-2 g-md-3 g-xxl-4">

                        <div class="col-auto">
                          <button [disabled]="cellForm.invalid" type="submit" class="btn btn-red"> Check In
                          </button>
                        </div>

                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<ng-template #onlineState>
  <div class="mb-30">
    <div class="row g-3">
      <div class="col">
        <div class="input-group">

          <div class="form-floating">
            <input #searchText type="text" class="form-control" id="search_text" placeholder="Search"
              (keyup)="searching(searchText.value)">
            <label for="search_text">Client Search</label>
          </div>
          <span class="input-group-text">
            <svg class="" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path
                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
              </path>
            </svg></span>
          <a class="btn btn-orange" *ngIf="listType !== 'diversion'" (click)="addNew(content,content2)">Bulk CheckIn</a>
        </div>
      </div>
    </div>
  </div>

  <div class="theme-box p-0">
    <div class="table-responsive mb-30">
      <table class="table table-hover table-theme table-stripedTheme table-dashboard text-center">
        <thead class="thead-dark">
          <tr>
            <th *ngIf="listType !== 'diversion'"></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Mobile</th>
            <th>DOB</th>
            <th>Time In</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cnt of clientList | paginate: {
            itemsPerPage: pageLimit,
            currentPage: currentPage,
            totalItems :totalCount
        }">
            <td *ngIf="listType !== 'diversion'">
              <input #bulkCheckIn class="form-check-input" type="checkbox" value="" [checked]="isClientSelected(cnt.id)"
                id="isMasterRecord" name="master_option" (click)="selectedClient(cnt.id,bulkCheckIn.checked)">
            </td>
            <td (click)="selectItem(cnt)">{{cnt.firstName}}</td>
            <td>{{cnt.lastName}}</td>
            <td>{{cnt.phone}}</td>
            <td>{{cnt.dob | date}}</td>
            <td *ngIf="listType === 'diversion'">{{(cnt.diversionCenters && cnt.diversionCenters.length >
              0)?(cnt.diversionCenters[0].timeIn | date
              :'HH:mm'):''}}</td>
            <td *ngIf="listType === 'cell'">{{(cnt.cellVisits && cnt.cellVisits.length > 0)?(cnt.cellVisits[0].timeIn |
              date
              :'HH:mm'):''}}</td>
            <td *ngIf="listType === 'community'">{{(cnt.communityPatrols && cnt.communityPatrols.length >
              0)?(cnt.communityPatrols[0].timeIn | date
              :'HH:mm'):''}}</td>
          </tr>
        </tbody>
      </table>
      <div class="has-text-centered">
        <app-pagination [currentPage]="currentPage" [pageSize]="pageLimit"
        [totalRows]="totalCount"
        (pageChange)="pageChanged($event)"
        ></app-pagination>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #content let-modal>
  <div class="modal-header">
    <h3 class="modal-title">checking in {{clientIdArray.length}} clients</h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="diversionCentre-clients">
      <form novalidate class="was-validated" (ngSubmit)="saveCommunityPatrol(communityForm)"
        [formGroup]="communityForm">
        <div>
          <div class="accordion accordion-theme" id="accordionExample">
            <div class="accordion-item">
              <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo">
                <div class="accordion-body">
                  <section class="cellVisits-clients">
                    <div class="bg-gray form-theme box-full py-xl-4 py-md-3 py-2">
                      <div class="row  g-3 g-xl-4 align-items-center">
                        <div class="col-md-4">
                          <label>
                            Is this a Foot Patrol?
                          </label>
                        </div>
                        <div class="col-md-3">
                          <div class="form-check mb-0">
                            <input type="radio" value="false" id="No_foot" class="form-check-input"
                              formControlName="footPrint">
                            <label for="No_foot" class="form-check-label"> No </label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-check mb-0">
                            <input type="radio" value="true" id="Yes_foot" class="form-check-input"
                              formControlName="footPrint">
                            <label for="Yes_foot" class="form-check-label"> Yes </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-theme py-xl-4 py-md-3 py-2">
                      <div class="row g-3 g-xl-4 ">
                        <div class="col-md-4">
                          <label for="accessComment">How did the person access the service?</label>
                        </div>
                        <div class="col-md-8">
                          <select class="form-select" id="accessComment" formControlName="accessComment">
                            <option *ngFor="let acl of accessCommentList" value="{{acl.value}}">{{acl.label}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-theme py-xl-4 py-md-3 py-2">
                      <div class="row g-3 g-xl-4 ">
                        <div class="col-md-4">
                          <label for="accessComment">Location</label>
                        </div>
                        <div class="col-md-8">
                          <select class="form-select" aria-label="Default select example" formControlName="locationId"
													id="locationId" [ngClass]="{ 'is-invalid': communityForm.controls.locationId.errors }"
													maxlength="250" required>
													<option value="" disabled selected>Select Location</option>
													<option *ngFor="let loc of communityLocationList" value="{{loc.id}}">{{loc.name}}</option>
												</select>
												<div *ngIf="communityForm.controls.locationId.errors" class="invalid-feedback">
													<div *ngIf="communityForm.controls.locationId.errors.required">
														Location is required
													</div>
												</div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-red" (click)="saveCommunityPatrol(communityForm)">Save</button>
          <button type="button" class="btn btn-red" (click)="modal.close('Close click')">Close</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h3 class="modal-title">checking in {{clientIdArray.length}} clients</h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="diversionCentre-clients">
      <form novalidate class="was-validated" (ngSubmit)="saveCellVisit(cellForm)" [formGroup]="cellForm">
        <div>
          <div class="accordion accordion-theme" id="accordionExample">
            <div class="accordion-item">
              <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo">
                <div class="accordion-body">
                  <section class="cellVisits-clients">
                    <div class="bg-gray form-theme box-full py-xl-4 py-md-3 py-2">
                      <div class="row  g-3 g-xl-4 align-items-center">
                        <div class="col-md-4">
                          <label>
                            Is this cell visit?
                          </label>
                        </div>
                        <div class="col-md-3">
                          <div class="form-check mb-0">
                            <input type="radio" value="false" id="person" class="form-check-input"
                              formControlName="isCellvisit">
                            <label for="person" class="form-check-label"> In Person </label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-check mb-0">
                            <input type="radio" value="true" id="via" class="form-check-input"
                              formControlName="isCellvisit">
                            <label for="via" class="form-check-label"> Via Videolink </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-theme py-xl-4 py-md-3 py-2">
                      <div class="row g-3 g-xl-4 ">
                        <div class="col-md-4">
                          <label for="accessComment">How did the person access the service?</label>
                        </div>
                        <div class="col-md-8">
                          <select class="form-select" id="accessComment" formControlName="accessComment">
                            <option *ngFor="let acl of accessCommentList" value="{{acl.value}}">{{acl.label}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="bg-gray form-theme py-xl-4 py-md-3 py-2">
                      <div class="row g-3 g-xl-4 align-items-center">
                        <div class="col-md-4">
                          <label for="inCharge">Officer In Charge:</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" class="form-control" formControlName="inCharge" id="inCharge">
                        </div>
                      </div>
                    </div>
                    <div class="form-theme py-xl-4 py-md-3 py-2">
                      <div class="row g-3 g-xl-4 ">
                        <div class="col-md-4">
                          <label for="accessComment">Location</label>
                        </div>
                        <div class="col-md-8">
                          <select class="form-select" aria-label="Default select example" formControlName="locationId"
													id="locationId" [ngClass]="{ 'is-invalid': cellForm.controls.locationId.errors }"
													maxlength="250" required>
													<option value="" disabled selected>Select Location</option>
													<option *ngFor="let loc of cellLocationList" value="{{loc.id}}">{{loc.name}}</option>
												</select>
												<div *ngIf="cellForm.controls.locationId.errors" class="invalid-feedback">
													<div *ngIf="cellForm.controls.locationId.errors.required">
														Location is required
													</div>
												</div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-red" (click)="saveCellVisit(cellForm)">Save</button>
          <button type="button" class="btn btn-red" (click)="modal.close('Close click')">Close</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
