import { Directive, OnChanges, Input, EventEmitter, ElementRef, Output, HostListener } from "@angular/core";

@Directive({
  selector: '[appContenteditableModel]'
})
export class ContenteditableModelDirective implements OnChanges {
  @Input('appContenteditableModel') model: unknown;
  @Output() contenteditableModelChange = new EventEmitter();

  private lastViewModel: unknown;

  constructor(private elRef: ElementRef) {
  }

  ngOnChanges(changes:{ [key: string]: unknown}) {
    if (isPropertyUpdated(changes, this.lastViewModel)) {
      this.lastViewModel = this.model
      this.refreshView()
    }
  }

  @HostListener('keyup')
  onKeyUp() {
    let value = this.elRef.nativeElement.innerText
    this.lastViewModel = value;

    if( value.length )
      addClass(this.elRef, "hasContent");
    else
      removeClass(this.elRef, "hasContent");

    this.contenteditableModelChange.emit(value)
  }

  protected refreshView() {
    this.elRef.nativeElement.innerText = this.model
  }
}

/* Util for adding items to elementRef items */
function addClass( el:ElementRef, _class:string ) {
  let nE = el.nativeElement;

  let matcher = new RegExp( "[^a-zA-Z]?" + _class + "[^a-zA-Z]?");

  if( nE.className.match(matcher) ) return;

  if( nE.className.length > 0 ) nE.className += ' ';

  nE.className += _class;
}

function removeClass( el:ElementRef, _class:string ) {
  let nE = el.nativeElement;

  if( !nE.className.length ) return;

  let replacer = new RegExp( "[^a-zA-Z]?" + _class + "[^a-zA-Z]?");

  nE.className = nE.className.replace(replacer, '');
}

function isPropertyUpdated(changes: {[key: string]: any}, viewModel: unknown): boolean {
  if (!changes.hasOwnProperty('model')) return false;
  const change = changes['model'];

  if (change.isFirstChange()) return true;

  return !(viewModel === change.currentValue);
}
