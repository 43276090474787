import { ValidationErrors, AbstractControl } from "@angular/forms";
import { FieldRejection } from '../model/api.model';
import { of } from 'rxjs';
import { hasKey } from "./object.util";

export const isString = (val: unknown): val is string => val instanceof String || typeof val === "string";

export const matchValues = (
	matchTo: string // name of the control to match to
): (AbstractControl) => ValidationErrors | null => {
	return (control: AbstractControl): ValidationErrors | null => {
		return !!control.parent &&
			!!control.parent.value &&
			control.value === control.parent.controls[matchTo].value
			? of(null)
			: of({ isMatching: true });
	};
}

export const serverSide = (
	rejections: FieldRejection[], // Reference to a list of field rejections for the form,
	fieldName: string, // name of the control to match to
	joiner?: string
): (AbstractControl) => ValidationErrors | null => {
	//const signature = `Utils.serverSide: field[${fieldName}]:`
	return (control: AbstractControl): ValidationErrors | null => {
		const rejection = rejections.find(rejection => rejection.field === fieldName);

		if (!rejection) {
			//logger.silly(signature+'No Rejection Found')
			return of(null);
		}

		if (isString(rejection.expected)) {
			return of({ serverValidation: rejection.expected });
		}

		if (hasKey(rejection, 'expected') && hasKey(rejection.expected, '0')) {
			const result = rejection.expected.join(joiner || '\n');

			return of({ serverValidation: result });
		}

		//logger.silly(signature+'Found Generic Validation Error')
		return of({ serverValidation: 'Invalid' });
	};
}

/**
 * @description Sorts an array of records by the 'createdAt' property in ascending order.
 *
 * @param {Array<{ createdAt: string }>} records - Array of records with a 'createdAt' property of type string.
 * @return {Array<{ createdAt: string }>} - Returns the sorted array of records.
 */
export const sortByCreatedAt = (records: { createdAt: string }[]) => {
	return records.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
}

export const uuid = () => (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
