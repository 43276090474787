<!--component html goes here -->
<div class="d-flex">
  <div class="px-1" *ngIf="showFootPatrol">
    <i class="fa-solid fa-flag text-black"></i> Foot Patrol
  </div>
  <div class="px-1">
    <i class="fa-solid fa-flag text-primary"></i> High Risk Medical
  </div>
  <div class="px-1" *ngIf="showMaster">
    <i class="fa-solid fa-flag text-warning"></i> Master Record
  </div>
  <div class="px-1">
    <i class="fa-solid fa-flag text-danger"></i> Domestic Violence Order
  </div>
</div>
