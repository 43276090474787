import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { NotificationsService } from "angular2-notifications";
import { ClientApi } from "../api/client.api";
import { IClient } from "../model/client.model";
import { bool } from "aws-sdk/clients/redshiftdata";
import { apiCallWrapper } from "../api/api.util";

@Injectable()
export class ClientService extends GenericService<IClient> {

  public entityName: string = "Client";

  constructor(
    public clientApi: ClientApi,
    public notifications: NotificationsService
  ) {
    super(clientApi, notifications);
  }

  getMaxTimeInRecord(client: { cellVisits: { timeIn: string }, communityPatrols: { timeIn: string }, diversionCenters: { timeIn: string } }): { isMedicalCondition: boolean, isViolence: boolean } | null {
    const allTimeInValues: number[] = [];

    // Extract "timeIn" values from cellVisits
    if (client.cellVisits && Array.isArray(client.cellVisits)) {
      const cellVisitsTimeIn = client.cellVisits.map((visit: { timeIn: string }) => new Date(visit.timeIn).getTime());
      allTimeInValues.push(...cellVisitsTimeIn);
    }

    // Extract "timeIn" values from communityPatrols
    if (client.communityPatrols && Array.isArray(client.communityPatrols)) {
      const communityPatrolsTimeIn = client.communityPatrols.map((patrol: { timeIn: string }) => new Date(patrol.timeIn).getTime());
      allTimeInValues.push(...communityPatrolsTimeIn);
    }

    // Extract "timeIn" values from diversionCenters
    if (client.diversionCenters && Array.isArray(client.diversionCenters)) {
      const diversionCentersTimeIn = client.diversionCenters.map((center: { timeIn: string }) => new Date(center.timeIn).getTime());
      allTimeInValues.push(...diversionCentersTimeIn);
    }

    if (allTimeInValues.length === 0) {
      return null; // Return null if there are no "timeIn" values to compare.
    }

    const maxTimeIn = Math.max(...allTimeInValues);

    // Find the record with the maximum "timeIn"
    let recordWithMaxTimeIn: { isMedicalCondition: boolean, isViolence: boolean } | null = null;

    // Check cellVisits
    if (client.cellVisits && Array.isArray(client.cellVisits)) {
      recordWithMaxTimeIn = client.cellVisits.find((visit: { timeIn: string }) => new Date(visit.timeIn).getTime() === maxTimeIn);
      if (recordWithMaxTimeIn) return recordWithMaxTimeIn;
    }

    // Check communityPatrols
    if (client.communityPatrols && Array.isArray(client.communityPatrols)) {
      recordWithMaxTimeIn = client.communityPatrols.find((patrol: { timeIn: string }) => new Date(patrol.timeIn).getTime() === maxTimeIn);
      if (recordWithMaxTimeIn) return recordWithMaxTimeIn;
    }

    // Check diversionCenters
    if (client.diversionCenters && Array.isArray(client.diversionCenters)) {
      recordWithMaxTimeIn = client.diversionCenters.find((center: { timeIn: string }) => new Date(center.timeIn).getTime() === maxTimeIn);
    }

    return recordWithMaxTimeIn;
  }

  clientSearchListing(query: {
    page: number;
    limit: number;
    search: string;
    listType: string;
    mainId?: string | null;
    clientId?: string | null;
    isCheckout?: boolean;
  }) {
    return apiCallWrapper(
      this.clientApi.clientSearchListing(query),
      {
        notificationsService: this.notifications,
        action: `Fetching Client Records`
      }
    );
  }
}