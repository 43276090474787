import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { NotificationsService } from 'angular2-notifications';
import { IUser } from '../../model/user.model';
import { getAllFormErrors } from '../../util/formUtils';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  public token: string = "";
  public id: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private __route: ActivatedRoute,
    private __adminUserService: AdminService,
    private _notifications: NotificationsService,
  ) {
    this.registerForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required]],
      password: ["", Validators.required],
      c_password: ["", Validators.required]
    });
  }

  ngOnInit() {
    this.__route.paramMap.subscribe(params => {
      this.token = (params.get('token') || '');
      if (this.token) {
        this.__adminUserService.adminApi.getTokenedUser({ token: this.token }).subscribe(async (response: {data: {email:string, id: string}}) => {
          let { email, id } = response.data;
          this.id = id;
          this.registerForm.controls['email'].setValue(email);
        }, err => {
          console.error(err);
          this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
        })
      }
    })
  }

  register() {
    if (!this.registerForm.valid) {
      return;
    }
    
    this.__adminUserService.adminApi.updateUser(Object.assign(this.registerForm.value, { id: this.id, confirmSignup: "" }))
      .subscribe(response => {
        this.router.navigate(['/login']);
      })
  }

}