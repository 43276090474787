import { Component, Input } from "@angular/core";

@Component({
  selector: "app-section-title",
  template: `
    <p *ngIf="pretext" class="text-uppercase text-spaced mt-1 mb-0 font-weight-bold">{{pretext}}</p>
<h2 *ngIf="!useH1">{{text}}</h2>
<h1 *ngIf="useH1" class="font-weight-bold py-0">{{text}}</h1>

  `,
  styles: [
    `
      h1 {
        font-size: 230%;
        line-height: 90%;
      }
    `,
  ],
})
export class SectionTitleComponent {
  @Input()
  pretext: string;

  @Input()
  text: string;

  @Input()
  useH1: boolean = false;

  constructor() {}
}



