import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICommunityPatrol } from '../model/community-patrol.model';
import { createUrl } from './api.util';

@Injectable()
export class CommunityPatrolApi extends GenericApi<ICommunityPatrol> {
  public path = "community-patrol";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }


  /**
   * createCommunityPatrol
   */
  public createCommunityPatrol(credentials: {}) {
    return this.httpClient.post<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'createCommunityPatrol'), credentials);
  }

  /**
    * createBulkCommunityPatrol
    */
  public createBulkCommunityPatrol(credentials: {}) {
    return this.httpClient.post<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'createBulkCommunityPatrol'), credentials);
  }


  /**
   * updateCommunityPatrol
   */
  public updateCommunityPatrol(communityId, credentials: {}) {
    return this.httpClient.put<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'updateCommunityPatrol', communityId), { ...credentials, communityId: communityId });
  }


  /**
   * updateCommunityPatrol
   */
  public updateBulkCommunityPatrol(bulkCommunityId, credentials: {}) {
    return this.httpClient.put<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'updateBulkCommunityPatrol', bulkCommunityId), { ...credentials, communityId: bulkCommunityId });
  }


  /**
   * listingCommunityPatrol
   */
  public listingCommunityPatrol(credentials: {}) {
    return this.httpClient.post<{
      message: string,
      status: boolean,
      data: {
        count: number,
        rows: unknown[]
      }
    }>(createUrl(this.path, 'listingCommunityPatrol'), credentials);
  }

}