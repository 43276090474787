import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-community-patrol-dashboard',
    templateUrl: 'community-patrol-dashboard.component.html',
    styleUrls: ['community-patrol-dashboard.component.scss']
})
export class CommunityPatrolDashboardComponent implements OnInit{
    constructor(private router: Router){}
    ngOnInit(){
        this.router.navigate(['/community-patrol']);
    }
}
