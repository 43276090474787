<div class="modal-header">
  <h4 class="modal-title text-center w-100">Pin Code</h4>
</div>
<div class="modal-body">
  <p class="text-center" *ngIf="error">{{error}}</p>
  <input [(ngModel)]="pin" type="password" pattern="[0-9]{4}" maxlength="4" class="w-100 text-center">
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-red w-100 text-center" (click)="submitPin()">Unlock</button>
  <button type="button" class="btn btn-red w-100 text-center pt-2" (click)="logout()">Logout</button>
</div>
