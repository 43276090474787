<div class="form-theme theme-box">
  <div class="d-flex justify-content-end">
    <flag-key [showMaster]="false" [showFootPatrol]="false"></flag-key>
  </div>

  <div class="table-responsive mb-30">
    <table class="table table-hover table-theme table-stripedTheme table-dashboard table-diversion-centre text-center">
      <thead class="thead-dark">
        <tr>
          <th *ngIf="isOffline"></th>
          <th *ngIf="isOffline"></th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Mobile</th>
          <th>DOB</th>
          <th>Date</th>
          <th>Time In</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dvs of cellList | paginate: {
            itemsPerPage: pageLimitActive,
            currentPage: currentPageActive,
            totalItems : totalCountActive,
        }">
          <td *ngIf="isOffline">
            <input #bulkcheckOut class="form-check-input" type="checkbox" value="" [checked]="isClientSelected(dvs.client.id)"
              id="isMasterRecord" name="master_option"
              (click)="selectedClient(dvs.client.id,bulkcheckOut.checked, dvs.id)">
          </td>
          <td *ngIf="isOffline" (click)="selectItemActive(dvs)">
            <i *ngIf="dvs.client.highRisk" class="fa-solid fa-flag text-primary mx-1" title="HighRisk"></i>
            <i *ngIf="dvs.isViolence" class="fa-solid fa-flag text-danger mx-1" title="Violence"></i>
          </td>
          <td (click)="selectItemActive(dvs)">{{dvs.client ? dvs.client.firstName : dvs.firstName}}</td>
          <td (click)="selectItemActive(dvs)">{{dvs.client ? dvs.client.lastName : dvs.lastName}}</td>
          <td (click)="selectItemActive(dvs)">{{dvs.client ? dvs.client.phone : 'N/A'}}</td>
          <td (click)="selectItemActive(dvs)" *ngIf="dvs.client; else dob">{{dvs.client.dob | date}}</td>
          <ng-template #dob>
            <td (click)="selectItemActive(dvs)">{{dvs.dob ? dvs.dob : 'N/A'}}</td>
          </ng-template>
          <td (click)="selectItemActive(dvs)" *ngIf="dvs.client; else createdAt">{{dvs.client.createdAt |
            date:'YYYY-MM-dd'}}</td>
          <ng-template #createdAt>
            <td (click)="selectItemActive(dvs)">{{date | date:'YYYY-MM-dd'}}</td>
          </ng-template>
          <td (click)="selectItemActive(dvs)">{{dvs.timeIn | date:'HH:mm'}}</td>
          <td *ngIf="isOffline" (click)="selectItemActive(dvs)">
            <img src="../../../../assets/images/orange-checkbox.svg" alt="" height="30px" width="30px" class="align-top" *ngIf="dvs.isCheckout" [title]="dvs.checkoutAt">
          </td>
        </tr>
      </tbody>
    </table>
    <div class="has-text-centered" *ngIf="isOffline">
      <button class="btn btn-orange ms-auto my-5" (click)="addNew()">Bulk CheckOut</button>
      <app-pagination [currentPage]="currentPageActive" [pageSize]="pageLimitActive"
        [totalRows]="totalCountActive"
        (pageChange)="pageChangedActive($event)"
        ></app-pagination>
    </div>
  </div>
</div>
