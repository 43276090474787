<div class="form-theme theme-box">
  <div class="d-flex justify-content-end">
    <flag-key [showMaster]="false" [showFootPatrol]="false"></flag-key>
  </div>

  <div class="table-responsive mb-30">
    <table class="table table-hover table-theme table-stripedTheme table-dashboard table-diversion-centre text-center">
      <thead class="thead-dark">
        <tr>
          <th></th>
          <th></th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Mobile</th>
          <th>DOB</th>
          <th>Date</th>
          <th>Time In</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dvs of diversionList | paginate: {
            itemsPerPage: pageLimitActive,
            currentPage: currentPageActive,
            totalItems : totalCountActive,
        }">
          <td>
            <input #bulkCheckOut class="form-check-input" type="checkbox" value="" [checked]="isClientSelected(dvs.client.id)"
              id="isMasterRecord" name="master_option"
              (click)="selectedClient(dvs.client.id,bulkCheckOut.checked,dvs.id)">
          </td>
          <td (click)="selectItemActive(dvs)">
            <i *ngIf="dvs.client.highRisk" class="fa-solid fa-flag text-primary mx-1" title="HighRisk"></i>
            <i *ngIf="dvs.isViolence" class="fa-solid fa-flag text-danger mx-1" title="Violence"></i>
          </td>
          <td (click)="selectItemActive(dvs)">{{dvs.client.firstName}}</td>
          <td (click)="selectItemActive(dvs)">{{dvs.client.lastName}}</td>
          <td (click)="selectItemActive(dvs)">{{dvs.client.phone}}</td>
          <td (click)="selectItemActive(dvs)">{{dvs.client.dob | date}}</td>
          <td (click)="selectItemActive(dvs)">{{dvs.client.createdAt | date:'YYYY-MM-dd'}}</td>
          <td (click)="selectItemActive(dvs)">{{dvs.timeIn | date:'HH:mm'}}</td>

          <td (click)="selectItemActive(dvs)">
            <img src="../../../../assets/images/orange-checkbox.svg" alt="" height="30px" width="30px" class="align-top"
              *ngIf="dvs.isCheckout" [title]="dvs.checkoutAt">
            <i *ngIf="shouldDisplayClockIcon(dvs)" class="fa-regular fa-clock fa-2x text-warning align-top" [title]="clockIconDetail(dvs)"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="has-text-centered">
      <button class="btn btn-orange ms-auto my-5" (click)="addNew()">Bulk CheckOut</button>
      <app-pagination [currentPage]="currentPageActive" [pageSize]="pageLimitActive"
      [totalRows]="totalCountActive"
      (pageChange)="pageChangedActive($event)"
      ></app-pagination>
    </div>
  </div>
</div>
