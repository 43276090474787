import { Component, OnInit } from '@angular/core';
import { slideInAnimation } from '../../util/animations';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldRejection } from '../../model/api.model';
import { AuthService } from '../../services/auth.service';
import { JwtService } from '../../services/jwt.service';
import { serverSide } from '../../util/utils';
import { CommonControllerComponent } from '../../util/common.controller';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: [],
  animations: [slideInAnimation]
})
export class TwoFactorAuthComponent extends CommonControllerComponent implements OnInit {
  form: UntypedFormGroup;
  fieldRejections: FieldRejection[] = [];
  error: string = '';
  obj: { email: string, password: string };
  activatedRoute: string;
  email: string;
  linkExpire: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private jwtService: JwtService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    super();

    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email], serverSide(this.fieldRejections, 'email')],
      password: [null, Validators.required, serverSide(this.fieldRejections, 'password')],
      code: [null],
      token: [null]
    });
  }

  ngOnInit() {
    this.obj = this.authService.getAuthValues();

    combineLatest([
      this.activeRoute.url,
      this.activeRoute.queryParams,
      this.activeRoute.params,
    ]).subscribe(([routeUrl, query, params]) => {
      this.activatedRoute = routeUrl[0].path;
      this.email = query.email;

      if (this.authService.isAuthenticated()) {
        this.router.navigate(['/']);
        return;
      }

      if (this.activatedRoute == "One-Time-Password") {
        this.authService.checkLinkExpiry({ email: this.email, token: params.token }).subscribe(res => {
          this.linkExpire = !res.success;

          if (!this.linkExpire) {
            this.form.controls.token.setValue(params.token);
          }

          if (!res.require2fa) {
            this.form.controls.code.setValue('000000');
            this.authenticate();
          }
        });
        return;
      }

      if (this.obj == undefined) {
        this.router.navigate(['/login']);
        return
      }
    });
  }

  authenticate() {
    if (this.activatedRoute == "One-Time-Password") {
      this.form.controls.email.setValue(this.email);
    } else {
      this.form.controls.email.setValue(this.obj.email);
      this.form.controls.password.setValue(this.obj.password);
      if (!this.canSubmit(this.form)) return;
      this.form.markAsPending();
    }
    if (!this.form.controls.code.value) {
      this.error = 'Invalid Code';
      return;
    }

    const code = this.form.controls.code.value.toString();
    if (code.length !== 6) {
      this.error = 'Invalid Code';
      return;
    }

    this.authService.authenticate(this.form.value)
      .subscribe(response => {

        if ('accessToken' in response) {
          this.jwtService.saveJWTData(response);
          this.router.navigate(['/dashboard']);
          return;
        }

      },
        () => this.form.markAsUntouched());
  }

  preventAutocomplete(event: FocusEvent) {
    if (event?.target) {
      const element = event.target as HTMLInputElement;
      if (element.autocomplete) {
        element.autocomplete = 'off';
      }
    }
  }

  filterInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);

    if (!/^[0-9]+$/.test(inputChar)) {
      event.preventDefault();
    }
  }
}
