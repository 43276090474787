import { Injectable } from "@angular/core";

@Injectable()
export class UtilService {
  constructor() {
  
  }
  
  generateId = ( chunks:number = 8 ) => Array.apply(null, Array(chunks)).map( () => this.S4() ).join('');
  createUuid = () => `${this.S4()}${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`;
  
  private S4(): string {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
}