import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IClientNote } from '../model/client-note.model';
import { createUrl } from './api.util';

@Injectable()
export class ClientNoteApi extends GenericApi<IClientNote> {
  public path = "clientnote";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  /**
   * clientNoteListing
   */
  public clientNoteListing(credentials: {}) {
    return this.httpClient.post<{
      status: boolean,
      message: string,
      data: IClientNote[]
    }>(createUrl(this.path, 'clientNoteListing'), credentials);
  }

}