import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorMessage, FieldRejection } from '../../model/api.model';
import { AuthService } from '../../services/auth.service';
import { JwtService } from '../../services/jwt.service';
import { slideInAnimation } from '../../util/animations';
import { CommonControllerComponent } from '../../util/common.controller';
import { serverSide } from '../../util/utils';
import { AuthApi } from '../../api/auth.api';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  animations: [slideInAnimation]
})
export class LoginComponent extends CommonControllerComponent implements OnInit {
  form: UntypedFormGroup;
  fieldRejections: FieldRejection[] = [];
  text: string = "LOGIN";
  sendEmailOTP: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private authApi: AuthApi,
    private jwtService: JwtService,
    private router: Router
  ) {
    super();

    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email], serverSide(this.fieldRejections, 'email')],
      password: [null, Validators.required, serverSide(this.fieldRejections, 'password')],
      code: [null]
    });
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
      return;
    }
    if (this.authService.getText()) {
      this.text = this.authService.getText();
    }
  }

  authenticate() {
    if (!this.canSubmit(this.form)) return;
    this.form.markAsPending();
    this.authService.setAuthValues({ email: this.form.controls.email.value, password: this.form.controls.password.value });
    this.authService.authenticate(this.form.value)
      .subscribe(response => {
        if ('status' in response && response.status) {
          this.router.navigate(['/verify-thats-you']);
          return;
        }

        if ('accessToken' in response) {
          this.jwtService.saveJWTData(response);
          this.router.navigate(['/dashboard']);
          return;
        }
      },
        (err: ErrorMessage) => this.handleValidationErrors(err, this.form, this.fieldRejections),
        () => this.form.markAsUntouched());
  }

  sendEmail() {
    if (!this.form.controls.email.value) {
      this.form.markAsPending();
      return;
    }

    this.authService.oneTimeLogin({ email: this.form.controls.email.value }).subscribe(response => {
      this.sendEmailOTP = true;
      this.router.navigate(['/one-time-password']);
    },
      (err: ErrorMessage) => this.handleValidationErrors(err, this.form, this.fieldRejections),
      () => this.form.markAsUntouched());
  }
}
