import { Component, ElementRef, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { BehaviorSubject, debounceTime, filter, skip } from 'rxjs';
import { asyncSearchDelay } from '../../util/consts';
import { DataGridComponent } from 'src/app/ui/view/data-grid/data-grid.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { IClient } from '../../model/client.model';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { logger } from '../../util/Logger';
import { CellVisitService } from '../../services/cell-visit.service';
import { environment } from 'src/environments/environment';
import { hasKey } from '../../util/object.util';
import { ICommunityPatrol } from '../../model/community-patrol.model';
import { ICellVisit } from '../../model/cell-visit.model';
import { IDiversionCentre } from '../../model/diversion-centre.model';

const className = "ClientListComponent";

@Component({
  selector: 'app-client-list',
  templateUrl: 'client-list.component.html',
  styleUrls: ['client-list.component.scss']
})
export class ClientListComponent implements AfterViewInit, OnInit {
  public searchTerm = new BehaviorSubject<string | null>(null);
  tooltipText: string;
  @ViewChild('dataGrid') dataGrid: DataGridComponent;
  @ViewChild('tooltipTarget') tooltipTarget: ElementRef;
  public search: string = "";

  constructor(
    private _notifications: NotificationsService,
    private __router: Router,
    public __clientService: ClientService,
    private modalService: NgbModal,
    public __cellVisitService: CellVisitService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      if (hasKey(params, 'page')) {
        this.initialPage = Number(params.page);
      }

      if (hasKey(params, 'search')) {
        this.searchTerm.next(params['search'] as string);
      }
    });
  }

  public clientList: IClient[] = [];
  public totalCount: number = 0;
  public currentPage: number = 1;
  public initialPage: number = 1;
  public pageLimit: number = environment.defaultCollectionLimit || 10;
  public isArchived: boolean = false;
  public clientIdArray: string[] = [];
  public mastervalue: string = "";
  public isMasterRecord: number = 0;
  public obj: {};

  ngOnInit() {
    this.monitorSearchTerm();
  }

  ngAfterViewInit() {
    if (this.tooltipTarget) {
      this.tooltipTarget.nativeElement.ngbTooltip = this.tooltipTarget.nativeElement.getAttribute('ngbTooltip');
    }
  }

  pageChanged(page: number) {
    this.currentPage = page;
    this.getUserCollection();
  }

  getUserCollection() {
    this.isArchived = false;
    let skip = (this.currentPage * this.pageLimit) - this.pageLimit;
    let search = (this.search) ? { $or: { email: { $like: `%${this.search}%` }, firstName: { $like: `%${this.search}%` }, lastName: { $like: `%${this.search}%` }, phone: { $like: `%${this.search}%` }, fullName: { $like: `%${this.search}%` } } } : {};

    this.__clientService.list({
      skip: skip,
      limit: this.pageLimit,
      filter: search,
      sortBy: 'lastName',
      order: 'asc'
    }).subscribe(async (response: {
      rows: IClient[];
      count: number;
      message?: string;
    }) => {
      if (response && response.rows) {
        this.clientList = response.rows;
        this.totalCount = response.count;
      } else {
        this._notifications.warn("ERROR", response.message || "", {});
      }
    }, err => {
      console.error(err);
      this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
    })
  }

  updateSearchTerm(searchTerm: string) {
    this.searchTerm.next(searchTerm);
  }

  /**
   * selectUser
   */
  public selectUser(cat: IClient): void {
    this.__router.navigate(['/clients/edit', cat.id], {
      queryParams: { search: this.search, page: this.currentPage },
    });
  }

  monitorSearchTerm() {
    this.searchTerm.pipe(
      debounceTime(asyncSearchDelay)
    ).subscribe({
      next: (searchTerm: string) => {
        this.currentPage = this.initialPage;
        if( this.initialPage != 1 ) {
          this.initialPage = 1;
        }

        this.search = searchTerm;
        this.getUserCollection();
      }
    })
  }

  /**
   * Adds or removes a clientId from the clientIdArray based on the provided parameters.
   *
   * @param {string} clientId - The ID of the client to add or remove.
   * @param {boolean} include - Specifies whether to include or remove the clientId.
   * @param {boolean} isMaster - Specifies if the clientId is a master record.
   * @returns {undefined} - This function does not return a value.
   *
   * @description
   * The `addMerge` function adds or removes a clientId from the `clientIdArray` based on the provided parameters.
   * If `include` is `true` and the clientId doesn't exist in the array, it will be added. If `isMaster` is also `true`,
   * the `isMasterRecord` count will be incremented.
   * If `include` is `false` and the clientId exists in the array, it will be removed. If `isMaster` is also `true`,
   * the `isMasterRecord` count will be decremented.
   * This function logs the process with a "silly" level of severity using the `logger` object.
   */
  addMerge(clientId: string, include: boolean, isMaster: boolean) {
    const signature = className + `.addMerge: ClientId[${clientId}] Include[${include}] isMaster[${isMaster}] `;

    const clientIdIndex = this.clientIdArray.indexOf(clientId);
    const clientIdExists = clientIdIndex !== -1;

    logger.silly(signature + 'Started');
    if (include && !clientIdExists) {
      this.clientIdArray.push(clientId);
      if (isMaster) {
        this.isMasterRecord++;
      }
    } else if (!include && clientIdExists) {
      this.clientIdArray.splice(clientIdIndex, 1);
      if (isMaster) {
        this.isMasterRecord--;
      }
    }
  }

  mergeModel(content) {
    if (this.isMasterRecord > 1) {
      Swal.fire('Master Record', 'Multiple master records selected. Only 1 master record can be used per merge!', 'error')
    } else if (this.isMasterRecord < 1) {
      Swal.fire('Master Record', 'A master record must be selected for client merge!', 'error')
    } else {
      for (let cntId of this.clientIdArray) {
        for (let cnt of this.clientList) {
          if (cnt.id == cntId && cnt.isMaster == true) {
            this.mastervalue = cnt.id;
            if (this.clientIdArray.indexOf(cnt.id) !== -1) {
              this.clientIdArray.splice(this.clientIdArray.indexOf(cnt.id), 1);
            }
          }
        }
      }
      this.obj = {
        "UserIdFrom": this.clientIdArray,
        "UserIdTo": this.mastervalue
      }
      this.modalService.open(content, { scrollable: true, centered: true, size: 'md' });
    }
  }

  isClientSelected(clientId: string): boolean {
    return this.clientIdArray.includes(clientId);
  }

  onConfirm() {
    this.__clientService.clientApi.clientMerge(this.obj)
      .subscribe({
        next: async (response: unknown) => {
          if (response) {
            this.modalService.dismissAll();
            this.clientIdArray = [];
            this.mastervalue = '';
            this.isMasterRecord = 0;
            this.getUserCollection();
          }
        },
        error: (err) => {
          console.error(err);
          this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {});
        }
      });
  }

  onClose() {
    this.modalService.dismissAll();
    this.clientIdArray = [];
    this.mastervalue = '';
    this.isMasterRecord = 0;
  }

  getLastService(client: IClient) {
    const recentServices: (ICommunityPatrol|ICellVisit|IDiversionCentre)[] = ['lastCellVisit','lastCommunityPatrol','lastDiversionCenter']
      .map(lastServiceName => client[lastServiceName])
      .filter(lastService => !!lastService);

    if( !recentServices.length ) {
      return null;
    }

    return recentServices.reduce( (recentService, lastService) => {
      if( new Date(recentService.createdAt).getTime() > new Date(lastService.createdAt).getTime() ) {
        return recentService;
      }

      return lastService;
    }, recentServices[0]);
  }
}
