import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IContact } from '../model/contact.model';

@Injectable()
export class ContactApi extends GenericApi<IContact> {
  public path = "contact";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}