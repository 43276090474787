import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { CellVisitService } from '../../services/cell-visit.service';
import { ConnectionServiceService } from '../../services/connection-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from '../../services/client.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorMessage, FieldRejection } from '../../model/api.model';
import { Subscription } from 'rxjs';
import { logger } from '../../util/Logger';
import { ICellVisit } from '../../model/cell-visit.model';


@Component({
  selector: 'app-cell-visit-sync-list',
  templateUrl: './cell-visit-sync-list.component.html',
  styleUrls: []
})
export class CellVisitSyncListComponent {
  @Input() type: string;
  public cellList = <any>[];
  public matchedEmail = <any>[];
  public exactEmailValue: { email: string };
  cellForm: FormGroup;
  fieldRejections: FieldRejection[] = [];
  public date = new Date();

  public modelData = <any>{};
  isOffline: boolean;
  private onlineOfflineSubscription: Subscription;

  constructor(
    private _notifications: NotificationsService,
    private __router: Router,
    public __cellVisitService: CellVisitService,
    public __conectionService: ConnectionServiceService,
    private modalService: NgbModal,
    public __clientService: ClientService,
    private formBuilder: FormBuilder,
    private clientService: ClientService
  ) {
    this.onlineOfflineSubscription = this.__conectionService
      .getOnlineOfflineStatus()
      .subscribe((status: boolean) => {
        this.isOffline = status;
        if (status == true) {
          this.cellList = __conectionService.getDataFromLocalStorage(false);
        }
      });

    this.cellForm = this.formBuilder.group({
      email: [null, [Validators.required]]
    });
  }

  selectItemActive(content, dvs: object) {
    this.modelData = dvs;
    this.exactEmailValue.email = '';
    this.__clientService.list({
      filter: {
        email: this.modelData.email
      },
      sortBy: 'lastName'
    }).subscribe(async (response: { rows: unknown }) => {
      if (response && response.rows) {
        this.matchedEmail = response.rows;
        let exactEmail = this.matchedEmail.filter(item => item.email === this.modelData.email);
        if (exactEmail.length > 0) {
          this.exactEmailValue = exactEmail[0].email;
        } else {
          this.exactEmailValue.email = '';
        }
      }
    }, err => {
      console.error(err);
      this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
    })
    this.modalService.open(content, { scrollable: true, centered: true, size: 'lg' });
  }

  saveCellVisit() {
    let clientId: string = '';
    if (!this.cellForm.controls.email.value) {
      const formData = this.modelData;
      [
        'dob',
        'address',
        'startDate',
        'endDate'
      ].forEach(nullableField => {
        if (!formData[nullableField]) {
          formData[nullableField] = null;
        }
      });

      const saveMethod = this.clientService.create(formData);

      saveMethod.subscribe({
        next: (val) => {
          clientId = val.id;
          this.createCellVisit(clientId);
        },
        complete: () => this.cellForm.markAsUntouched()
      });
    } else {
      const client = this.matchedEmail.filter(item => item.email === this.cellForm.controls.email.value);
      clientId = client[0]?.id;
      this.createCellVisit(clientId);
    }
  }


  updateSyncStatus(tempId) {
    try {
      const localStorageDataString = localStorage.getItem('cellVisits');
      if (!localStorageDataString) {
        logger.silly("'cellVisits' data not found in localStorage.");
        return;
      }

      const localStorageData = JSON.parse(localStorageDataString);

      const itemToUpdate = localStorageData.find(item => item.tempId === tempId);
      if (!itemToUpdate) {
        logger.silly(`Item with tempId '${tempId}' not found.`);
        return;
      }

      itemToUpdate.synced = true;

      localStorage.setItem('cellVisits', JSON.stringify(localStorageData));
    } catch (error) {
      console.error("An error occurred during the update process:", error);
    }
  }


  createCellVisit(clientId: string) {
    const formData = this.modelData;
    let formValue = Object.assign(formData, { clientId: clientId });
    let saveMethod = this.__cellVisitService.cellVisitApi.createCellVisit(formValue);
    saveMethod.subscribe({
      next: () => {
        this.updateSyncStatus(formData.tempId);
        this.modalService.dismissAll();
        this.cellForm.controls.email.setValue(null);
        this.cellForm.reset()
        this.__router.navigate(['/cell-visits/dashboard']);
      },
      error: (err: ErrorMessage) => { },
      complete: () => this.cellForm.markAsUntouched()
    });
  }

  onClose() {
    this.modalService.dismissAll();
    this.cellForm.reset();
  }
}
