import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'admin-tools',
    templateUrl: 'admin-tools.component.html',
    styleUrls: ['admin-tools.component.scss']
})
export class AdminToolsComponent {

}
