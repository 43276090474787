<div class="tab-theme">
	<app-tab-set #tabSet [tabs]="['Locations','Add New']" (newItemEvent)="clickedTab($event)"></app-tab-set>

	<div class="tab-content">
		<!--component html goes here -->
		<div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0 }">
			<div class="table-responsive">
				<table class="table table-theme table-stripedTheme table-dashboard text-center">
					<thead>
						<tr>
							<th>Name</th>
							<th>Diversion Center</th>
							<th>Cell Visit</th>
							<th>Community Patrol</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let loc of locationList | paginate: {
                          itemsPerPage: pageLimit,
                          currentPage: currentPage,
                          totalItems :totalCount
                      }" (click)="selectLoc(loc)">
							<td>{{loc.name}}</td>
							<td>
								<div class="custom-switch-theme ">
									<input type="checkbox" name="isDiversion" id="isDiversion" value="true" [checked]="loc.isDiversion"
										disabled />
									<label class="" for="isDiversion"></label>
								</div>
							</td>
							<td>
								<div class="custom-switch-theme ">
									<input type="checkbox" name="isCellVisit" id="isCellVisit" value="true" [checked]="loc.isCellVisit"
										disabled />
									<label class="" for="isCellVisit"></label>
								</div>
							</td>
							<td>
								<div class="custom-switch-theme ">
									<input type="checkbox" name="isCommunityPatrol" id="isCommunityPatrol" value="true"
										[checked]="loc.isCommunityPatrol" disabled />
									<label class="" for="isCommunityPatrol"></label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="has-text-centered">
					<app-pagination [currentPage]="currentPage" [pageSize]="pageLimit" [totalRows]="totalCount"
						(pageChange)="pageChanged($event)"></app-pagination>
				</div>
			</div>
		</div>
		<div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1 }">
			<form [formGroup]="locationForm" class="form-inline">
				<div class="bg-gray  form-theme  p-xl-4 p-3 ">
					<div class="row g-3 g-xl-5 align-items-center ">
						<div class="col-lg-6">
							<div class="row g-3 g-xl-4 align-items-center ">
								<div class="col-lg-5 col-md-4"><label>Location</label></div>
								<div class="col-lg-7  col-md-8">
									<div class="form-group">
										<input type="text" class="form-control mb-2 mr-sm-2" id="exampleInputEmail1" placeholder="location"
											autocomplete="location" formControlName="name"
											[ngClass]="{ 'is-invalid': submitted && f['name'].errors }" required>
										<div *ngIf="submitted && f['name'].errors" class="invalid-feedback mb-2 mr-sm-2">
											<div *ngIf="f['name'].errors.required">location is required
											</div>
										</div>
									</div>
									<!-- <input type="text" class="form-control"> -->
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row g-3 g-xl-4 align-items-center ">
								<div class="col-lg-5 col-md-4"><label>Diversion Center</label></div>
								<div class="col-lg-7  col-md-8">
									<div class="form-group">
										<div class="custom-switch-theme ">
											<input type="checkbox" name="isDiversion" formControlName="isDiversion" id="isDiversion1"
												value="true" />
											<label class="custom-control-input" for="isDiversion1"></label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-theme p-xl-4 p-3">
					<div class="row g-3 g-xl-5 align-items-center ">
						<div class="col-lg-6">
							<div class="row g-3 g-xl-4 align-items-center ">
								<div class="col-lg-5 col-md-4"><label>Cell Visit</label></div>
								<div class="col-lg-7  col-md-8">
									<div class="form-group">
										<div class="custom-switch-theme ">
											<input type="checkbox" name="isCellVisit" formControlName="isCellVisit" id="isCellVisit1"
												value="true" />
											<label class="custom-control-input" for="isCellVisit1"></label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row g-3 g-xl-4 align-items-center ">
								<div class="col-lg-5 col-md-4"><label>Community Patrol</label></div>
								<div class="col-lg-7  col-md-8">
									<div class="form-group">
										<div class="custom-switch-theme ">
											<input type="checkbox" name="isCommunityPatrol" formControlName="isCommunityPatrol"
												id="isCommunityPatrol1" value="true" />
											<label class="custom-control-input" for="isCommunityPatrol1"></label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<app-select-input field="tags" label="Tags" [multiple]="true" [tags]="true" [form]="locationForm"
								[errors]='{
                  required: "Location Tags required",
                  serverValidation: "Rejected by server"
              }'>
								<option value="{{tag}}" *ngFor="let tag of tagList">{{tag}}</option>
							</app-select-input>
						</div>
					</div>
				</div>
				<div class="text-end  p-xl-4 p-3 ">
					<button type="button" class="btn btn-primary mb-2" (click)="createLocation()">Save</button>
				</div>
			</form>
		</div>
	</div>
</div>