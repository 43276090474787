<div class="clientsDiversion-block">
	<div class="text-center mb-4">
		<h2 *ngIf="type === 'community'">Community Patrol</h2>
		<h2 *ngIf="type === 'cell'">Cell Visit</h2>
		<h2 *ngIf="type === 'diversion'">Diversion Centre</h2>
	</div>
	<div class="table-responsive mb-4">
		<table class="table table-theme table-stripedTheme  text-center">
			<thead>
				<tr>
					<th>First Name</th>
					<th>Last Name</th>
					<th>Mobile</th>
					<th *ngIf="diversionCenters && diversionCenters.location">Location</th>
					<th *ngIf="cellVisits && cellVisits.location">Location</th>
					<th *ngIf="communityPatrols && communityPatrols.location">Location</th>
					<th>DOB</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngIf="clientData">
					<td>{{clientData.firstName}}</td>
					<td>{{clientData.lastName}}</td>
					<td>{{clientData.phone}}</td>
					<td *ngIf="diversionCenters && diversionCenters.location">{{diversionCenters.location.name}}</td>
					<td *ngIf="cellVisits && cellVisits.location">{{cellVisits.location.name}}</td>
					<td *ngIf="communityPatrols && communityPatrols.location">{{communityPatrols.location.name}}</td>
					<td>{{clientData.dob | date}}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<section *ngIf="diversionCenters">
		<service-data-row
			question="Has the client been involved in any altercations immediately prior to their arrival at the Diversion Centre?"
			[response]="helpers.yesOrNo(diversionCenters.isAlter)" [detail]="diversionCenters.alterComment"
			[showDetail]="diversionCenters.isAlter"></service-data-row>

		<service-data-row [bg]="1" question="Does the client have any belongings on them (Including medication)?"
			[response]="helpers.yesOrNo(diversionCenters.isBelonging)" [detail]="diversionCenters.belongingComment"
			[showDetail]="diversionCenters.isBelonging"></service-data-row>

		<service-data-row question="Does the client have any known medical conditions?"
			[response]="helpers.yesOrNo(diversionCenters.isMedical)"></service-data-row>
		<service-data-row [bg]="1" question="Does an ambulance need to be contacted?"
			[response]="helpers.yesOrNo(diversionCenters.isAmbulance)"></service-data-row>
		<service-data-row question="Is the client on any medication?"
			[response]="helpers.yesOrNo(diversionCenters.isOnMedication)" [detail]="diversionCenters.medicationName"
			[showDetail]="diversionCenters.isOnMedication">
		</service-data-row>
		<service-data-row [bg]="1" question="Do arrangements need to be made for medication to be provided to the client?"
			[response]="helpers.getArrangementsValue(diversionCenters.isArrangements)"></service-data-row>
		<service-data-row question="If applicable when did the client last take their medication?">
			<p class="text-center">{{diversionCenters.medicationDate | date:'YYYY-MM-dd'}} {{diversionCenters.medicationTime}}
			</p>
		</service-data-row>

		<div class=" bg-gray form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">OBSERVATIONS OF CLIENT WELLBEING</h4>
		</div>
		<service-data-row [wide]="true" question="If you notice the client is acting strangely or particularly out of character it is important that you talk
		to them about it. Select any of the following behaviours shown by the client.">
			<div *ngIf="diversionCenters.wellBeingObservations">
				<p class="m-3 text-center" *ngFor="let itm of diversionCenters.wellBeingObservations">
					{{itemBehaviour(itm.behaviour)}}
				</p>
			</div>
		</service-data-row>

		<div class=" bg-gray form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">File notes</h4>
		</div>
		<service-data-row
			question="Is the client currently showing any signs of aggression e.g. spitting,swearing, hitting, punching?"
			[response]="helpers.yesOrNo(diversionCenters.isAggression)"></service-data-row>

		<service-data-row question="Does the client have any of the following symptoms?"
			[response]="helpers.yesOrNo(diversionCenters.medicalObservations && diversionCenters.medicalObservations.length)">
			<div *ngIf="diversionCenters.medicalObservations && diversionCenters.medicalObservations.length">
				<p class="m-2 text-center" *ngFor="let itm of diversionCenters.medicalObservations">
					{{itemSymptoms(itm.symptoms)}}
				</p>
			</div>
		</service-data-row>

		<div class=" bg-gray form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">CLIENT OBSERVATION RECORD</h4>
		</div>

		<div class="form-theme p-xl-4 p-md-3 p-2">
			<div class="diversionObservation-box">
				<div class="table-responsive mb-30">
					<table class="table table-theme table-stripedTheme table-dashboard" v-align="top">
						<thead>
							<tr>
								<th>Did you check on them?</th>
								<th>Were they awake or asleep?</th>
								<th>Status Change?</th>
								<th>Comments <br> <small>(Mandatory when status changed)</small></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let itm of diversionCenters.clientObservationForms">
								<td>{{itm.clientCheck | date:'dd/MM/yy hh:mm a' }}</td>
								<td>{{(itm.clientAwake === '1')?'Awake':'Sleep'}}</td>
								<td>{{(itm.clientStatusChange === '1')?'Yes':'No'}}</td>
								<td>{{itm.statusComments}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class=" bg-gray form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">CLIENT BACKGROUND INFORMATION</h4>
		</div>

		<div class="bg-gray form-theme p-2">
			<div class="" *ngIf="diversionCenters.clientBackgroundInformations">
				<p class="m-3" *ngFor="let itm of diversionCenters.clientBackgroundInformations">
					{{itm.information}}
				</p>
			</div>
		</div>

		<div class="form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">CLIENT EXIT DETAILS</h4>
		</div>
		<service-data-row question="Has a referral been made on behalf of the client?"
			[response]="helpers.yesOrNo(diversionCenters.isReferral)"></service-data-row>
		<service-data-row question="How did the client leave the service?"
			[response]="helpers.getLeaveCellText(diversionCenters.isLeave)"
			[detail]="getLeaveDiversionComment(diversionCenters)"></service-data-row>

		<service-check-in-out-row [createdAt]="diversionCenters.createdAt"
			[createdBy]="helpers.getProperty(diversionCenters.createdDiversion, 'name')"
			[checkoutAt]="diversionCenters.checkoutAt"
			[checkoutBy]="helpers.getProperty(diversionCenters.updatedDiversion, 'name')">
		</service-check-in-out-row>
	</section>
	<section *ngIf="cellVisits">

		<service-data-row question="Officer In Charge:" [response]="cellVisits.inCharge"></service-data-row>
		<service-data-row [bg]="1" question="Does the client have any belongings on them (Including medication)?"
			[response]="helpers.yesOrNo(cellVisits.isBelonging)" [detail]="cellVisits.belongingComment"
			[showDetail]="cellVisits.isBelonging"></service-data-row>
		<service-data-row question="Is there a 'safe place' contact person:"
			[response]="helpers.yesOrNo(cellVisits.isSafePlace)" [detail]="cellVisits.safePlaceDetails"
			[showDetail]="cellVisits.isSafePlace"></service-data-row>
		<service-data-row [bg]="1" question="Are there any 'No contact' Domestic Violence Orders in place?"
			[response]="helpers.yesOrNo(cellVisits.domesiticViolances && cellVisits.domesiticViolances.length)">
			<div class=" col-md-7" *ngIf="cellVisits.domesiticViolances">
				<p class="m-3" *ngFor="let itm of cellVisits.domesiticViolances">
					{{itm.violancerName}}
				</p>
			</div>
		</service-data-row>
		<service-data-row question="Does the client have any known medical conditions?"
			[response]="helpers.yesOrNo(cellVisits.isMedicalCondition)"></service-data-row>
		<service-data-row [bg]="1" question="Does an ambulance need to be contacted?"
			[response]="helpers.yesOrNo(cellVisits.isAmbulance)"></service-data-row>
		<service-data-row question="Is the client on any medication?"
			[response]="helpers.yesOrNo(cellVisits.isOnMedication)" [detail]="cellVisits.medicationName"
			[showDetail]="cellVisits.isOnMedication">
		</service-data-row>
		<service-data-row [bg]="1" question="Do arrangements need to be made for medication to be provided to the client?"
			[response]="helpers.getArrangementsValue(cellVisits.isArrangements)"></service-data-row>
		<service-data-row question="If applicable when did the client last take their medication?">
			<p class="text-center">{{cellVisits.medicationDate | date:'YYYY-MM-dd'}} {{cellVisits.medicationTime}}</p>
		</service-data-row>
		<div class=" bg-gray form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">File notes</h4>
		</div>
		<service-data-row
			question="Is the client currently showing any signs of aggression e.g. spitting,swearing, hitting, punching?"
			[response]="helpers.yesOrNo(cellVisits.isAggression)"></service-data-row>
		<service-data-row [bg]="1" question="Does the client have any of the following symptoms?"
			[response]="helpers.yesOrNo(cellVisits.medicalObservations && cellVisits.medicalObservations.length)">
			<div *ngIf="cellVisits.medicalObservations && cellVisits.medicalObservations.length">
				<p class="m-2 text-center" *ngFor="let itm of cellVisits.medicalObservations">
					{{itemSymptoms(itm.symptoms)}}
				</p>
			</div>
		</service-data-row>
		<service-data-row question="Was service refused?" [response]="helpers.yesOrNo(cellVisits.isServerRefused)"
			[detail]="cellVisits.refusedBy" [showDetail]="cellVisits.isServerRefused"></service-data-row>
		<div class=" bg-gray form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">Client Record</h4>
		</div>

		<service-data-row question="Actions Taken" [detail]="cellVisits.actionTaken"></service-data-row>

		<service-data-row question="Client Comments" [detail]="cellVisits.clientComments"></service-data-row>

		<div class=" bg-gray form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">CLIENT EXIT DETAILS</h4>
		</div>
		<service-data-row question="Has a referral been made on behalf of the client?"
			[response]="helpers.yesOrNo(cellVisits.isReferral)"></service-data-row>
		<service-data-row question="How did the client leave the service?"
			[response]="helpers.getLeaveCellText(cellVisits.isLeaveDiversion)"
			[detail]="getLeaveCellComment(cellVisits)"></service-data-row>

		<service-check-in-out-row [createdAt]="cellVisits.createdAt"
			[createdBy]="helpers.getProperty(cellVisits.createdCell, 'name')" [checkoutAt]="cellVisits.checkoutAt"
			[checkoutBy]="helpers.getProperty(cellVisits.updatedCell, 'name')">
		</service-check-in-out-row>
	</section>

	<section *ngIf="communityPatrols">
		<service-data-row [bg]="1" question="Does the client have any belongings on them (Including medication)?"
			[response]="helpers.yesOrNo(communityPatrols.isBelonging)" [detail]="communityPatrols.belongingComment"
			[showDetail]="communityPatrols.isBelonging"></service-data-row>

		<service-data-row question="Is there a 'safe place' contact person:"
			[response]="helpers.yesOrNo(communityPatrols.isSafePlace)" [detail]="communityPatrols.safePlaceDetails"
			[showDetail]="communityPatrols.isSafePlace"></service-data-row>

		<service-data-row [bg]="1" question="Are there any 'No contact' Domestic Violence Orders in place?"
			[response]="helpers.yesOrNo(communityPatrols.domesiticViolances && communityPatrols.domesiticViolances.length)">
			<div class=" col-md-7" *ngIf="communityPatrols.domesiticViolances">
				<p class="m-3" *ngFor="let itm of communityPatrols.domesiticViolances">
					{{itm.violancerName}}
				</p>
			</div>
		</service-data-row>

		<service-data-row question="Does the client have any known medical conditions?"
			[response]="helpers.yesOrNo(communityPatrols.isMedicalCondition)"></service-data-row>
		<service-data-row [bg]="1" question="Does an ambulance need to be contacted?"
			[response]="helpers.yesOrNo(communityPatrols.isAmbulance)"></service-data-row>
		<service-data-row question="Is the client on any medication?"
			[response]="helpers.yesOrNo(communityPatrols.isOnMedication)" [detail]="communityPatrols.medicationName"
			[showDetail]="communityPatrols.isOnMedication"></service-data-row>

		<service-data-row [bg]="1" question="Do arrangements need to be made for medication to be provided to the client?"
			[response]="helpers.getArrangementsValue(communityPatrols.isArrangements)"></service-data-row>
		<service-data-row question="If applicable when did the client last take their medication?">
			<p class="text-center">{{communityPatrols.medicationDate | date:'YYYY-MM-dd'}} {{communityPatrols.medicationTime}}
			</p>
		</service-data-row>

		<div class=" bg-gray form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">File notes</h4>
		</div>

		<service-data-row
			question="Is the client currently showing any signs of aggression e.g. spitting,swearing, hitting, punching?"
			[response]="helpers.yesOrNo(communityPatrols.isAggression)"></service-data-row>
		<service-data-row [bg]="1" question="Does the client have any of the following symptoms?"
			[response]="helpers.yesOrNo(communityPatrols.medicalObservations && communityPatrols.medicalObservations.length)">
			<div *ngIf="communityPatrols.medicalObservations && communityPatrols.medicalObservations.length">
				<p class="m-2 text-center" *ngFor="let itm of communityPatrols.medicalObservations">
					{{itemSymptoms(itm.symptoms)}}
				</p>
			</div>
		</service-data-row>
		<service-data-row question="Was service refused?" [response]="helpers.yesOrNo(communityPatrols.isServerRefused)"
			[detail]="helpers.getServiceRefusedByText(communityPatrols.serverRefusedBy)"
			[showDetail]="communityPatrols.isServerRefused"></service-data-row>

		<div class=" bg-gray form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">Client Record</h4>
		</div>

		<service-data-row question="Actions Taken"
			[detail]="(communityPatrols.actionTaken||'') + ' ' + (communityPatrols.clientComments||'')"></service-data-row>

		<div class=" bg-gray form-theme p-xl-4 p-md-3 p-2">
			<h4 class="mb-0">CLIENT EXIT DETAILS</h4>
		</div>
		<service-data-row question="Has a referral been made on behalf of the client?"
			[response]="helpers.yesOrNo(communityPatrols.isReferral)"></service-data-row>
		<service-data-row question="How did the client leave the service?"
			[response]="helpers.getLeaveCellText(communityPatrols.isLeaveDiversion)"
			[detail]="getLeaveCellComment(communityPatrols)"></service-data-row>

		<service-check-in-out-row [createdAt]="communityPatrols.createdAt"
			[createdBy]="helpers.getProperty(communityPatrols.createdCommunity, 'name')"
			[checkoutAt]="communityPatrols.checkoutAt"
			[checkoutBy]="helpers.getProperty(communityPatrols.updatedCommunity, 'name')">
		</service-check-in-out-row>
	</section>
</div>
