import { Component, HostListener } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { handleValidationErrors, canSubmit, displayValidationErrors } from './formUtils';

@Component({
	template: ''
})
export class CommonControllerComponent {
	constructor() { }

	keypress: BehaviorSubject<string | null> = new BehaviorSubject(null);

	@HostListener('document:keypress', ['$event'])
	private handleKeyboardEvent(event: KeyboardEvent) {
		this.keypress.next(event.key);
	}

	handleValidationErrors = handleValidationErrors;
	displayValidationErrors = displayValidationErrors;
	canSubmit = canSubmit;

	scrollTop() {
		window.scrollTo(0, 0);
	}

	scrollTo(tgt: string) {
		let targetElem = document.querySelector('#' + tgt);

		if (!targetElem) return;

		targetElem.scrollIntoView();
		window.scrollBy(0, -50);
	}
}
