import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ConnectionServiceService {

  private onlineOffline$: BehaviorSubject<boolean>;

  constructor() {
    this.onlineOffline$ = new BehaviorSubject<boolean>(navigator.onLine);

    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
  }

  private updateOnlineStatus(): void {
    this.onlineOffline$.next(navigator.onLine);
  }

  public getOnlineOfflineStatus(): Observable<boolean> {
    return this.onlineOffline$.asObservable();
  }

  getDataFromLocalStorage(sync: boolean){
    const rawData = localStorage.getItem('cellVisits');
    const data = rawData ? JSON.parse(rawData) : null;

    if (data) {
      const syncedItems = data.filter(item => item.synced === sync);
      return syncedItems
    } else {
      console.log("No data found in localStorage.");
      return null;
    }

  }

}
