<div>
  <div class="theme-box p-0">
    <div class="table-responsive mb-30">
      <table
        class="table table-hover table-theme table-stripedTheme table-dashboard table-diversion-centre text-center">
        <thead class="thead-dark">
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Mobile</th>
            <th>DOB</th>
            <th>Date</th>
            <th>Time In</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dvs of cellList">
            <td (click)="selectItemActive(content,dvs)">{{dvs.firstName}}</td>
            <td (click)="selectItemActive(content,dvs)">{{dvs.lastName}}</td>
            <td (click)="selectItemActive(content,dvs)">{{dvs.phone ? dvs.phone : 'N/A'}}</td>
            <td (click)="selectItemActive(content,dvs)">{{dvs.dob ? dvs.dob : 'N/A'}}</td>
            <td (click)="selectItemActive(content,dvs)">{{date | date:'YYYY-MM-dd'}}</td>
            <td (click)="selectItemActive(content,dvs)">{{dvs.timeIn | date:'HH:mm'}}</td>
          </tr>
        </tbody>
      </table>
      <div class="has-text-centered">

      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
    <div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title">Client Matched for this visit</h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
  </div>

  <form (ngSubmit)="saveCellVisit()" [formGroup]="cellForm">
    <div class="modal-body">
      <div class="row align-items-center">
        <div class="col"><label class="confrimation-label">Client Email:</label></div>
        <div class="col">
          <p class="confirmation-text">{{modelData.email}}</p>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col"><label class="confrimation-label">Client Firstname:</label></div>
        <div class="col">
          <p class="confirmation-text">{{modelData.firstName}}</p>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col"><label class="confrimation-label">Client Lastname:</label></div>
        <div class="col">
          <p class="confirmation-text">{{modelData.lastName}}</p>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col"><label class="confrimation-label"> Matched Email :</label></div>
        <div class="col">
          <select class="form-select" aria-label="Default select example" [(ngModel)]="exactEmailValue"  formControlName="email">
            <option *ngFor="let ctx of matchedEmail" value="{{ctx.email}}">{{ctx.email}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-auto">
        <button type="button" (click)="saveCellVisit()"
          class="btn btn-orange" [disabled]="cellForm.controls['email'].errors">Confirm</button>
      </div>
      <div class="col-auto ms-4">
        <button type="button" (click)="saveCellVisit()"
          [disabled]="exactEmailValue?.email || !cellForm.controls['email'].errors"
          class="btn btn-success">Confirm and
          Create Client</button>
      </div>
    </div>
  </form>
</div>
</ng-template>