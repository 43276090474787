<div class="tab-theme tab-dashboard">
  <app-tab-set #tabSet [tabs]="['Active','Add New']"></app-tab-set>

  <div class="tab-content">
    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0}">
      <app-community-patrol-list></app-community-patrol-list>
    </div>
    <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1}">
      <app-client-search listType='community' (itemSelected)="selectClient($event)"></app-client-search>
    </div>
  </div>
</div>