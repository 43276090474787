import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { ResetComponent } from './auth/reset.component';
import { Error404Component } from './error/404.component';
import { ForgotComponent } from './auth/forgot.component';
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AuthGuard } from "./guard/auth.guard";
import { LoginComponent } from "./auth/login/login.component";
import { UnAuthGuard } from "./guard/unauth.guard";
import { AuthTemplateComponent } from "./template/auth-template/auth-template.component";
import { AppTemplateComponent } from "./template/app-template/app-template.component";
import { ClientListComponent } from "./client/client-list/client-list.component";
import { ClientEditComponent } from "./client/client-edit/client-edit.component";
import { ClientSearchComponent } from "./client/client-search/client-search.component";
import { ClientDiversionComponent } from "./client/client-diversion/client-diversion.component";
import { ContactComponent } from "./contact/contact.component";
import { DiversionCentreComponent } from "./diversion-centre/diversion-centre.component";
import { CellVisitComponent } from "./cell-visit/cell-visit.component";
import { CommunityPatrolComponent } from "./community-patrol/community-patrol.component";
import { CategoriesComponent } from "./categories/categories.component";
import { UserListComponent } from "./admin/user/user-list/user-list.component";
import { UserEditComponent } from "./admin/user/user-edit/user-edit.component";
import { LocationComponent } from "./location/location.component";
import { ReportComponent } from "./report/report.component";
import { RegisterComponent } from "./auth/register/register.component";
import { ContactEditComponent } from "./contact/contact-edit/contact-edit.component";
import { DiversionCentreEditComponent } from "./diversion-centre/diversion-centre-edit/diversion-centre-edit.component";
import { CellVisitEditComponent } from './cell-visit/cell-visit-edit/cell-visit-edit.component';
import { CommunityPatrolEditComponent } from './community-patrol/community-patrol-edit/community-patrol-edit.component';
import { CommunityPatrolDashboardComponent } from "./community-patrol-dashboard/community-patrol-dashboard.component";
import { CellVisitDashboardComponent } from "./cell-visit-dashboard/cell-visit-dashboard.component";
import { TwoFactorAuthComponent } from "./auth/two-factor-auth/two-factor-auth.component";
import { AdminGuard } from "./guard/admin.guard";
import { AdminToolsComponent } from "./admin/admin-tools/admin-tools.component";
import { OneTimePassword } from "./auth/OneTimePassword.component";

const routes: Routes = [
  { path: '', component: HomeComponent, data: { animation: "Home" } },
  {
    path: '', canActivate: [AuthGuard], component: AppTemplateComponent, children: [
      { path: 'dashboard', component: DashboardComponent, data: { animation: "Dashboard" } },
      { path: 'clients', component: ClientListComponent, data: { animation: "ClientList" } },
      { path: 'clients/edit', component: ClientEditComponent, data: { animation: "ClientEdit" } },
      { path: 'clients/edit/:id', component: ClientEditComponent, data: { animation: "ClientEdit" } },
      { path: 'clients-search', component: ClientSearchComponent, data: { animation: "ClientSearch" } },
      { path: 'clients/:type/:id/:clientId', component: ClientDiversionComponent, data: { animation: "ClientDiversion" } },
      { path: 'contacts/list', component: ContactComponent, data: { animation: "ContactList" } },
      { path: 'contacts/edit', component: ContactEditComponent, data: { animation: "ContactList" } },
      { path: 'contacts/edit/:id', component: ContactEditComponent, data: { animation: "ContactList" } },
      { path: 'diversion-centre', component: DiversionCentreComponent, data: { animation: "DiversionCentre" } },
      { path: 'diversion-centre/edit/:id', component: DiversionCentreEditComponent, data: { animation: "DiversionCentreEdit" } },
      { path: 'cell-visits', component: CellVisitComponent, data: { animation: "CellVisit" } },
      { path: 'cell-visits/dashboard', component: CellVisitDashboardComponent, data: { animation: "CellVisit" } },
      { path: 'cell-visits/edit/:id', component: CellVisitEditComponent, data: { animation: "CellEdit" } },
      { path: 'community-patrol', component: CommunityPatrolComponent, data: { animation: "CommunityPatrol" } },
      { path: 'community-patrol/dashboard', component: CommunityPatrolDashboardComponent, data: { animation: "CommunityPatrol" } },
      { path: 'community-patrol/edit/:id', component: CommunityPatrolEditComponent, data: { animation: "CommunityPatrolEdit" } },
      { path: 'reports', component: ReportComponent, data: { animation: "Report" } },
      { path: 'locations', component: LocationComponent, data: { animation: "Location" } },
      { path: 'categories', component: CategoriesComponent, data: { animation: "Category" } },
      { path: 'admin/users', component: UserListComponent, canActivate: [ AdminGuard ], data: { animation: "UserList" } },
      { path: 'admin/tools', component: AdminToolsComponent, canActivate: [ AdminGuard ], data: { animation: "AdminTools" } },
      { path: 'admin/users/edit',canActivate: [ AdminGuard ], component: UserEditComponent, data: { animation: "UserCreate" } },
      { path: 'admin/users/edit/:id', component: UserEditComponent, data: { animation: "UserEdit" } },
    ]
  },
  {
    path: '', canActivate: [UnAuthGuard], component: AuthTemplateComponent, children: [
      { path: 'login', component: LoginComponent, data: { animation: "Auth" } },
      { path: 'register/:token', component: RegisterComponent, },
      { path: 'forgot', component: ForgotComponent, data: { animation: "Forgot" } },
      { path: 'reset/:token', component: ResetComponent, data: { animation: "Reset" } },
      { path: 'verify-thats-you', component: TwoFactorAuthComponent, data: { animation: "verify-thats-you" } },
      { path: 'One-Time-Password/:token', component: TwoFactorAuthComponent, data: { animation: "verify-thats-you" } },
      { path: 'one-time-password', component: OneTimePassword },
    ]
  },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
