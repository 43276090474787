import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MenuStates } from '../app-menu/app-menu.component';
import { InactivityService } from '../../services/inactivity.service';
import { AdminService } from '../../services/admin.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
	selector: 'app-template',
	templateUrl: 'app-template.component.html',
	styleUrls: ['app-template.component.scss']
})
export class AppTemplateComponent {

	@ViewChild('viewPort') viewPort: ElementRef;

	public menuState: MenuStates = 'open';
	pin: string | null = null;

	constructor(
		public inactivityService: InactivityService,
		private __adminUserService: AdminService,
		public notifications: NotificationsService
	) {
		this.loadAutoLOack();
	}
	public setMenuOpenState(state: MenuStates) {
		this.menuState = state;
	}

	@HostListener('document:keydown', ['$event'])
	@HostListener('document:mousemove', ['$event'])
	@HostListener('document:keypress', ['$event'])
	@HostListener('document:touchstart', ['$event'])
	@HostListener('document:scroll', ['$event'])
	@HostListener('document:navigate', ['$event'])
	onUserActivity(event: Event): void {
		if (this.pin) {
			this.inactivityService.userActivity();
		}
	}

	loadAutoLOack() {
		this.__adminUserService.getAuthenticatedUser().subscribe({
			next: usr => {
				this.pin = usr && usr.pin && usr.pin.length ? usr.pin : null;

				if (this.pin) {
					this.inactivityService.resetInactivityTimer();
					this.inactivityService.setPin(this.pin);
				}
			},
			error: err => {
				console.error(err);
				this.notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
			}
		});
	}
}
