import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ClientService } from '../../services/client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommunityPatrolService } from '../../services/community-patrol.service';
import { ErrorMessage } from 'aws-sdk/clients/cloudformation';
import { CLIENT_ACCESS } from '../../util/drop_down.helper';
import { LocationService } from '../../services/location.service';
import { ILocation } from '../../model/location.model';
import { CellVisitService } from '../../services/cell-visit.service';
import Swal from 'sweetalert2'
import { logger } from '../../util/Logger';
import { Observable, Subscription } from 'rxjs';
import { ConnectionServiceService } from '../../services/connection-service.service';
import { SYMPTOMS_DATA } from "../../util/drop_down.helper"
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';

const className = "ClientSearchComponent";

@Component({
	selector: 'app-client-search',
	templateUrl: './client-search.component.html',
	styleUrls: ['./client-search.component.sass']
})
export class ClientSearchComponent implements OnInit, AfterViewInit, OnDestroy {
	isOffline$: Observable<boolean>;
	communityForm: FormGroup;
	public isFormSubmit: boolean = false;
	communityId: string | null = null;
	id: string | null = null;
	accessCommentList: { label: string, value: string, name: string }[] = CLIENT_ACCESS;
	communityLocationList: ILocation[] = [];
	cellLocationList: ILocation[] = [];
	cellId: string | null = null;
	cellForm: FormGroup;
	@ViewChild('ReferralModal') ReferralModal: ElementRef;
	symptomsList: ({
		label: string;
		value: string;
		selected: boolean;
		disabled: boolean;
	} | {
		label: string;
		value: number;
		selected?: undefined;
		disabled?: undefined;
	})[] = SYMPTOMS_DATA;
	isOffline: boolean;
	private onlineOfflineSubscription: Subscription;

	constructor(
		private _notifications: NotificationsService,
		private __router: Router,
		public __clientService: ClientService,
		private modalService: NgbModal,
		private formBuilder: FormBuilder,
		private clientService: ClientService,
		private __communityPatrolService: CommunityPatrolService,
		private __locationService: LocationService,
		private __cellVisitService: CellVisitService,
		public __conectionService: ConnectionServiceService,
	) {
		this.communityForm = this.formBuilder.group({
			footPrint: ['false', []],
			isServerRefused: ['false', []],
			serverRefusedBy: [null, []],
			clientComments: [null, []],
			isLeaveDiversion: ['1', []],
			leaveDiversionComment2: [null, []],
			leaveDiversionComment1: [null, []],
			isBelongingTaken: ['1', []],

			locationId: [null, []],
			timeIn: [new Date(), []],
			accessComment: [null, []],
			isBelonging: ['false', []],
			belongingComment: [{ value: null, disabled: true }, []],
			isViolence: ['false', []],
			domesiticViolances: this.formBuilder.array([
				this.formBuilder.group({
					violancerName: [{ value: null, disabled: true }, []],
				})
			]),
			isMedicalCondition: ['false', []],
			isAmbulance: ['false', []],
			isOnMedication: ['false', []],
			medicationName: [{ value: null, disabled: true }, []],
			isArrangements: ['0', []],
			medicationDate: [null, []],
			medicationTime: [null, []],
			isAggression: ['false', []],
			medicalObservations: this.formBuilder.array([]),
			clientBackgroundInformations: this.formBuilder.array([
				this.formBuilder.group({
					information: [null, []],
				})
			]),
			isReferral: ['false', []],
			estimatedTime: [null, []],
			thingsToConsider: ['false', []],
			thingsToConsider2: ['false', []],
			thingsToConsider3: ['false', []],
			agreeFor: ['false', []],
			isReferralSupport: ['false', []],
			referrelId: [null, []],
			agreeForDetail3: [null, []],
			checkoutAt: [null, []],
			isCheckout: ['false', []],
		});

		this.cellForm = this.formBuilder.group({
			firstName: ['', []],
			lastName: ['', []],
			email: ['', []],
			isCellvisit: ['false', []],
			inCharge: [null, []],
			isServerRefused: ['false', []],
			serverRefusedBy: [null, []],
			actionTaken: [null, []],
			clientComments: [null, []],
			isLeaveDiversion: ['3', []],
			leaveDiversionComment2: [null, []],

			locationId: [null, []],
			timeIn: [new Date(), []],
			accessComment: [null, []],
			isBelonging: ['false', []],
			belongingComment: [{ value: null, disabled: true }, []],
			isViolence: ['false', []],
			domesiticViolances: this.formBuilder.array([
				this.formBuilder.group({
					violancerName: [{ value: null, disabled: true }, []],
				})
			]),
			isMedicalCondition: ['false', []],
			isAmbulance: ['false', []],
			isOnMedication: ['false', []],
			medicationName: [{ value: null, disabled: true }, []],
			isArrangements: ['0', []],
			medicationDate: [null, []],
			medicationTime: [null, []],
			isAggression: ['false', []],
			medicalObservations: this.formBuilder.array([]),
			clientBackgroundInformations: this.formBuilder.array([
				this.formBuilder.group({
					information: [null, []],
				})
			]),
			isReferral: ['false', []],
			estimatedTime: [null, []],
			thingsToConsider: ['false', []],
			thingsToConsider2: ['false', []],
			thingsToConsider3: ['false', []],
			agreeFor: ['false', []],
			isReferralSupport: ['false', []],
			referrelId: [null, []],
			agreeForDetail3: [null, []],
			checkoutAt: [null, []],
			isCheckout: ['false', []],
		});

		this.onlineOfflineSubscription = this.__conectionService
			.getOnlineOfflineStatus()
			.subscribe((status: boolean) => {
				this.isOffline = status;
			});
	}

	public clientList = <any>[];
	public totalCount: number = 0;
	public currentPage: number = 1;
	public pageLimit: number = environment.defaultCollectionLimit || 10;
	public search: string = "";
	public isArchived: boolean = false;
	public clientIdArray = <any>[];

	@Input() listType: string;

	@Output()
	itemSelected = new EventEmitter<object>();


	ngOnInit(): void {
		this.getCellLocations();
		this.getCommunityLocations();
	}

	ngOnDestroy(): void {
		this.onlineOfflineSubscription.unsubscribe();
	}

	domesiticViolances(): FormArray {
		return this.cellForm.get("domesiticViolances") as FormArray
	}

	removeDomesiticViolance(i: number) {
		if (this.cellForm.controls['isViolence'].value == 'true') {
			this.domesiticViolances().removeAt(i);
		}
	}

	changeIsBelonging(evt: Event | null) {
		this.cellForm.controls['belongingComment'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.cellForm.controls['belongingComment'].enable();
			this.cellForm.controls['belongingComment'].setValidators([Validators.required]);
		} else {
			this.cellForm.controls['belongingComment'].disable();
			this.cellForm.controls['belongingComment'].setValidators([]);
		}
		this.cellForm.controls['belongingComment'].updateValueAndValidity();
		this.cellForm.updateValueAndValidity();
	}

	get belongingCommentRequired() {
		return this.cellForm.controls['belongingComment'].errors && this.cellForm.controls['belongingComment'].errors.required;
	}

	changeServerRefusedBy(evt: Event | null) {
		this.cellForm.controls['serverRefusedBy'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.cellForm.controls['serverRefusedBy'].enable();
		} else {
			this.cellForm.controls['serverRefusedBy'].disable();
		}
	}
	newDomesiticViolances(): FormGroup {
		return this.formBuilder.group({
			violancerName: ['', []],
		})
	}

	get medicationNameRequired() {
		return this.cellForm.controls['medicationName'].errors && this.cellForm.controls['medicationName'].errors.required;
	}

	newMedicalObservations(): FormGroup {
		return this.formBuilder.group({
			symptoms: ['', []],
		})
	}

	medicalObservations(): FormArray {
		return this.cellForm.get("medicalObservations") as FormArray
	}

	removeMedicalObservations(i: number) {
		this.medicalObservations().removeAt(i);
	}

	symptomsSelected(itm: unknown) {
		/* let cunt = itm.target.value;
		for (let itm of this.symptomsList) {
			if (itm.value == cunt) {
				itm.visible = false;
			}
		} */
	}

	addMedicalObservations() {
		this.medicalObservations().push(this.newMedicalObservations());
	}

	addDomesiticViolance() {
		if (this.cellForm.controls['isViolence'].value == 'true') {
			this.domesiticViolances().push(this.newDomesiticViolances());
		}
	}

	changeReferral(evt: Event | null) {
		this.cellForm.controls['referrelId'].setValue(null);
		this.cellForm.controls['agreeForDetail3'].setValue(null);
		this.cellForm.controls['thingsToConsider'].setValue('false');
		this.cellForm.controls['thingsToConsider2'].setValue('false');
		this.cellForm.controls['thingsToConsider3'].setValue('false');
		this.cellForm.controls['agreeFor'].setValue('false');
		this.cellForm.controls['isReferralSupport'].setValue('false');

		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.cellForm.controls['thingsToConsider'].setValidators([Validators.required]);
			this.cellForm.controls['thingsToConsider2'].setValidators([Validators.required]);
			this.cellForm.controls['thingsToConsider3'].setValidators([Validators.required]);
			this.modalService.open(this.ReferralModal, { scrollable: true, centered: true, size: 'lg' });
		} else {
			console.log(this.cellForm.value);
		}
		this.cellForm.updateValueAndValidity();
	}


	changeIsOnMedication(evt: Event | null) {
		this.cellForm.controls['medicationName'].setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			this.cellForm.controls['medicationName'].enable();
			this.cellForm.controls['medicationName'].setValidators([Validators.required]);
		} else {
			this.cellForm.controls['medicationName'].disable();
			this.cellForm.controls['medicationName'].setValidators([]);
		}
		this.cellForm.controls['medicationName'].updateValueAndValidity();
		this.cellForm.updateValueAndValidity();
	}

	changeIsViolence(evt: Event | null) {
		let dd: any = this.cellForm.controls['domesiticViolances'];
		if (!dd.controls.length) {
			this.cellForm.updateValueAndValidity();
			return;
		}
		let firstViolancerName = dd.controls[0].controls['violancerName'];

		firstViolancerName.setValue(null);
		if (evt?.target instanceof HTMLInputElement && evt.target.value === 'true') {
			// this.canViolence = true;
			firstViolancerName.enable();
			firstViolancerName.setValidators([Validators.required]);
		} else {
			let i = (this.domesiticViolances().controls.length - 1);
			while (this.domesiticViolances().controls.length != 1) {
				this.removeDomesiticViolance(i);
				i--;
			}
			firstViolancerName.disable();
			firstViolancerName.setValidators([]);
			// this.canViolence = false;
		}
		firstViolancerName.updateValueAndValidity();
		this.cellForm.updateValueAndValidity();
	}

	getCommunityLocations() {
		this.__locationService.list({ filter: { isCommunityPatrol: true }, limit: 1000 }).subscribe(async (response: any) => {
			if (response) {
				this.communityLocationList = response.rows;
			} else {
				this._notifications.warn("ERROR", response.message || "", {});
			}
		}, err => {
			console.error(err);
			this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
		})
	}

	getCellLocations() {
		this.__locationService.list({ filter: { isCellVisit: true }, limit: 1000 }).subscribe(async (response: any) => {
			if (response) {
				this.cellLocationList = response.rows;
			} else {
				this._notifications.warn("ERROR", response.message || "", {});
			}
		}, err => {
			console.error(err);
			this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
		})
	}

	pageChanged(page: number) {
		this.currentPage = page;
		this.getClientCollection();
	}

	getClientCollection() {
		this.__clientService.clientSearchListing({ page: this.currentPage, limit: this.pageLimit, search: this.search, listType: this.listType }).subscribe({
			next: response => {
				this.clientList = response.data.rows;
				this.totalCount = response.data.count;
			},
			error: err => {
				console.error(err);
				this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
			}
		});
	}

	ngAfterViewInit() {
		this.getClientCollection();
	}

	searching(searchText: string) {
		this.search = searchText;
		this.currentPage = 1;
		this.getClientCollection();
	}

	/**
	 * selectItem
	 */
	public selectItem(data: unknown): void {
		this.itemSelected.emit({ data: data, listType: this.listType });
	}

	addNew(content, content2) {
		if (this.clientIdArray == 0) {
			Swal.fire('checkIn', 'please select minimum one client for checkIn !', 'error')
		} else {
			if (this.listType == "community") {
				this.modalService.open(content, { scrollable: true, centered: true, size: 'lg' });
			} else if (this.listType == "cell") {
				this.modalService.open(content2, { scrollable: true, centered: true, size: 'lg' });
			}
		}
	}

	isClientSelected(clientId: string): boolean {
		return this.clientIdArray.includes(clientId);
	}

	openReferral(content) {
		this.modalService.open(content, { scrollable: true, centered: true, size: 'lg' });
	}
	/**
	 * Adds or removes a clientId from the clientIdArray based on the provided parameters.
	 *
	 * @param {string} clientId - The ID of the client to add or remove.
	 * @param {boolean} include - Specifies whether to include or remove the clientId.
	 * @returns {undefined} - This function does not return a value.
	 */

	selectedClient(clientId: string, include: boolean) {
		const signature = className + `.bulkCheckIN: ClientId[${clientId}] Include[${include}]] `;

		const clientIdIndex = this.clientIdArray.indexOf(clientId);
		const clientIdExists = clientIdIndex !== -1;

		logger.silly(signature + 'Started');
		if (include && !clientIdExists) {
			this.clientIdArray.push(clientId);
		} else if (!include && clientIdExists) {
			this.clientIdArray.splice(clientIdIndex, 1);
		}
	}

	saveCommunityPatrol(formData: FormGroup) {
		this.isFormSubmit = true;
		if (!this.communityForm.valid) {
			return;
		}
		if (!formData.value.leaveDiversionComment1) {
			formData.value.leaveDiversionComment1 = "";
		}
		if (!formData.value.leaveDiversionComment2) {
			formData.value.leaveDiversionComment2 = "";
		}

		let formValue = Object.assign(formData.value, { clientId: this.clientIdArray });
		const saveMethod = this.communityId ?
			this.__communityPatrolService.communityPatrolApi.createBulkCommunityPatrol(formValue) : this.__communityPatrolService.communityPatrolApi.createBulkCommunityPatrol(formValue);

		saveMethod.subscribe({
			next: () => {
				this.__router.navigate(['/community-patrol/dashboard']);
				this.clientIdArray = []
				this.modalService.dismissAll()
			},
			error: (err: ErrorMessage) => { },
			complete: () => this.communityForm.markAsUntouched()
		});
	}

	saveCellVisit(formData: FormGroup) {
		this.isFormSubmit = true;
		if (!this.cellForm.valid) {
			return;
		}
		let formValue = Object.assign(formData.value, { clientId: this.clientIdArray });
		const saveMethod = this.cellId ?
			this.__cellVisitService.cellVisitApi.createBulkCellVisit(formValue) : this.__cellVisitService.cellVisitApi.createBulkCellVisit(formValue);

		saveMethod.subscribe({
			next: () => {
				this.__router.navigate(['/cell-visits/dashboard']);
				this.clientIdArray = []
				this.modalService.dismissAll();
			},
			error: (err: ErrorMessage) => { },
			complete: () => this.cellForm.markAsUntouched()
		});
	}

	saveOfflineCellVisit(formData: FormGroup) {
		this.isFormSubmit = true;
		if (!this.cellForm.valid) {
			return;
		}
		let formValue = Object.assign(formData.value, { synced: false, tempId: uuidv4() });
		let existingData = localStorage.getItem('cellVisits');

		if (existingData) {
			let parsedData = JSON.parse(existingData);
			parsedData.push(formValue);
			localStorage.setItem('cellVisits', JSON.stringify(parsedData));
		} else {
			localStorage.setItem('cellVisits', JSON.stringify([formValue]));
		}
		this.__router.navigate(['/cell-visits/dashboard']);

	}
}
