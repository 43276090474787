import { Component, Input } from '@angular/core';

@Component({
	selector: 'service-check-in-out-row',
	templateUrl: 'service-check-in-out-row.component.html',
	styleUrls: ['service-check-in-out-row.component.scss']
})
export class ServiceCheckInOutRowComponent {
	@Input() createdAt: string | number | Date;
	@Input() createdBy: string | null = null;
	@Input() checkoutAt: string | number | Date | null = null;
	@Input() checkoutBy: string | null = null;
}
