import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { environment } from "../../../../../environments/environment";
import { AdminService } from 'src/app/main/services/admin.service';
import { IUser } from '../../../model/user.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-user-list',
    templateUrl: 'user-list.component.html',
    styleUrls: ['user-list.component.scss']
})
export class UserListComponent implements OnInit {

    constructor(
        private _notifications: NotificationsService,
        private __router: Router,
        private __adminUserService: AdminService,
    ) {
    }

    public userList: IUser[] = [];
    public totalCount: number = 0;
    public currentPage: number = 1;
    public pageLimit: number = environment.defaultCollectionLimit || 10;
    public search: string = "";
    public isArchived: boolean = false;

    pageChanged(page: number) {
        this.currentPage = page;
        if (this.isArchived == false) {
            this.getUserCollection();
        } else {
            this.showArchived();
        }
    }

    getUserCollection() {
        this.isArchived = false;
        let skip = (this.currentPage * this.pageLimit) - this.pageLimit;
        let search = (this.search) ? { $or: { email: { $like: `%${this.search}%` }, firstName: { $like: `%${this.search}%` }, lastName: { $like: `%${this.search}%` }, role: { $like: `%${this.search}%` }, locationId: { $like: `%${this.search}%` } } } : {};

        this.__adminUserService.list({
            skip: skip,
            limit: this.pageLimit,
            filter: search,
        }).subscribe(async (response: {
            rows: IUser[];
            count: number;
            message?: string;
          }) => {
            if (response && response.rows) {
                this.userList = response.rows;
                this.totalCount = response.count;
                // this._notifications.success("Done", response.message || "", {});
            } else {
                this._notifications.warn("ERROR", response.message || "", {});
            }
        }, err => {
            console.error(err);
            this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
        })
    }

    showArchived() {
        this.isArchived = true;
        this.__adminUserService.adminApi.archivedAdminListing({ page: this.currentPage, limit: this.pageLimit, search: this.search }).subscribe(async (response: {
            status: boolean;
            message: string;
            data: {
                count: number;
                rows: IUser[];
            };
        }) => {
            if (response && response.status) {
                this.userList = response.data.rows;
                this.totalCount = response.data.count;
                // this._notifications.success("Done", response.message || "", {});
            } else {
                this._notifications.warn("ERROR", response.message || "", {});
            }
        }, err => {
            console.error(err);
            this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
        })
    }

    ngOnInit() {
        this.getUserCollection();
    }

    searching(searchText: string) {
        this.search = searchText;
        if (this.isArchived == false) {
            this.getUserCollection();
        } else {
            this.showArchived();
        }
    }

    /**
       * selectUser
       */
    public selectUser(cat: IUser): void {
        this.__router.navigate([`/admin/users/edit`, btoa(cat.id)]);
    }

}
