import {
	AuthApi, resetInput, authenticateInput, resetContent,
	forgotInput, forgotContent, authenticateOutput
} from '../api/auth.api';
import { Injectable } from "@angular/core";
import { JwtService } from './jwt.service';
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { IJWTPayload } from '../model/auth.model';

interface auth {
	email: string,
	password: string
}

@Injectable()
export class AuthService {
	constructor(
		private authApi: AuthApi,
		private jwtService: JwtService,
		public notifications: NotificationsService
	) { }

	private obj: { email: string, password: string };
	private _text: string = '';

	/**
	 * @description Fetches a new JWT token using the supplied refresh token
	 */
	public readonly refreshToken = (jwtString: string, jwtRefreshString: string): Observable<IJWTPayload> => {
		return apiCallWrapper(
			this.authApi.refresh({ accessToken: jwtString, refreshToken: jwtRefreshString }),
			{
				notificationsService: this.notifications,
				action: "Obtaining new access keys"
			}
		)
	}

	public reset = (opts: resetInput): Observable<resetContent> => {
		return apiCallWrapper(
			this.authApi.reset(opts),
			{
				notificationsService: this.notifications,
				action: "Changing password"
			}
		);
	}

	public authenticate = (opts: authenticateInput): authenticateOutput => {
		return apiCallWrapper(
			this.authApi.authenticate(opts),
			{
				notificationsService: this.notifications,
				action: "Authenticating"
			}
		)
	}

	public forgot = (opts: forgotInput): Observable<forgotContent> => {

		return apiCallWrapper(
			this.authApi.forgot(opts),
			{
				notificationsService: this.notifications,
				action: "Reset Password",
				failTitle: "Reset Failed",
				successTitle: "Reset Complete",
				successMessage: "Instructions were sent to your email address"
			}
		)
	}

	public oneTimeLogin = (opts: { email: string }): Observable<{ success: boolean }> => {
		return apiCallWrapper(
			this.authApi.oneTimeLogin(opts),
			{
				notificationsService: this.notifications,
				action: "Send OTP"
			}
		)
	}

	public checkLinkExpiry = (opts: { email: string, token: string }): Observable<{ success: boolean, require2fa: boolean }> => {
		return apiCallWrapper(
			this.authApi.checkLinkExpiry(opts),
			{
				notificationsService: this.notifications,
				action: "check Token"
			}
		)
	}

	public isAuthenticated = () => this.jwtService.currentJwtPayload$.getValue() !== null;

	setAuthValues(data: auth) {
		this.obj = data
	}

	getAuthValues(): { email: string, password: string } {
		return this.obj
	}

	getText(): string {
		return this._text;
	}

	setText(text: string) {
		this._text = text;
	}

}
