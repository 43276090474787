import { slideInAnimation } from "../util/animations";
import { AuthService } from "../services/auth.service";
import { CommonControllerComponent } from '../util/common.controller';
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { matchValues, serverSide } from '../util/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { FieldRejection, ErrorMessage } from '../model/api.model';

@Component({
	template: `
	<section class="features-section-5 relative" id="contact">
		<div class="form-outer login-box">
			
					<div class="app-section-header text-center mb-4 mb-md-5">
							<app-section-title text="Set your Password" pretext="Profile"></app-section-title>
						</div>

						<div class="alert alert-danger" *ngIf="displayValidationErrors(fieldRejections)" [innerHTML]="displayValidationErrors(fieldRejections)"></div>

						<div class="clearfix"></div>

						<form [formGroup]="form" class="">
							<div class="form-floating mb-30">
								<app-text-input type="password" field="password" label="Password" [form]="form" [errors]='{ 
									required: "Password Required",
									serverValidation: "Rejected by server"
								}'></app-text-input>
							</div>

							<div class="form-floating mb-30">
								<app-text-input type="password" field="confirmPassword" label="Confirm Password" [form]="form" [errors]='{ 
									required: "Password Required",
									isMatching: "Does not match"
								}'></app-text-input>
							</div>

							<div class="mb-4 mb-md-5">
								<button class="btn btn-theme send_mess w-100" [disabled]="!canSubmit(form)" (click)="resetPassword()">Set Password</button>
							</div>

							<div class="text-center">
							<a class="send_mess mr-2" routerLink="/login">Cancel</a>
							</div>
						</form>
					
		</div>
	</section>

	<p id="back-top"> <a (click)="scrollTop()"><span></span></a> </p>
	`,
	animations: [slideInAnimation]
})
export class ResetComponent extends CommonControllerComponent implements OnInit {
	form: UntypedFormGroup;
	fieldRejections: FieldRejection[] = [];

	private email: string;
	private token: string;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private jwtService: JwtService,
		private router: Router
	) {
		super();

		this.form = this.formBuilder.group({
			password: [null, Validators.required, serverSide(this.fieldRejections, 'password')],
			confirmPassword: [null, Validators.required, matchValues('password')]
		});
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.token = params.token;
		});

		this.activatedRoute.queryParams.subscribe(queryParams => {
			this.email = queryParams.email;
		});

		this.form.controls['password'].valueChanges.subscribe({
			next: val => {
				if (val.length)
					this.form.controls['confirmPassword'].updateValueAndValidity();
			}
		});
	}

	resetPassword() {
		if (!this.canSubmit(this.form)) return;

		this.form.markAsPending();

		this.authService.reset({
			email: this.email,
			password: this.form.value.password,
			resetToken: this.token
		})
			.subscribe(
				jwtPayload => {
					this.jwtService.saveJWTData(jwtPayload);

					this.router.navigate(['/dashboard']);
				},
				(err: ErrorMessage) => {
					this.router.navigate(['/login']);
					this.handleValidationErrors(err, this.form, this.fieldRejections)
				},
				() => this.form.markAsUntouched()
			);
	}
}
