import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILocation } from '../model/location.model';
import { Observable } from 'rxjs';
import { createUrl } from './api.util';

@Injectable()
export class LocationApi extends GenericApi<ILocation> {
	public path = "location";

	constructor(
		public httpClient: HttpClient
	) {
		super(httpClient);
	}
}
