<div *ngIf="!linkExpire">
<div class="alert alert-primary" role="alert" *ngIf="error">
  {{error}}
</div>

<div class="app-section-header text-center mb-4 mb-md-5">
  <app-section-title text="Verify It’s You"></app-section-title>
</div>

<p class="text-large text-center">We have sent a verification code to you via sms. Enter the 6 digit code in the field below.</p>

<form [formGroup]="form" (ngSubmit)="authenticate()">
  <div class="form-group mb-5">
    <input class="verify-field" name="code" type="text" value="" formControlName="code" autocomplete="new-password"
      (focus)="preventAutocomplete($event)"
      (keypress)="filterInput($event)">
  </div>
  <button class="btn btn-success send_mess w-100 mb-4">submit</button>
  <a class="btn btn-secondary w-100" [routerLink]="'/login'">Cancel</a>
</form>
</div>
<div *ngIf="linkExpire">
<h3 class="p-4 text-center">OTP Link is expired.</h3>
<a class="btn btn-primary w-100" [routerLink]="'/login'">Try Again</a>
</div>
