import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ClientService } from '../../services/client.service';
import { CLIENT_ACCESS, SYMPTOMS_DATA, BEHAVIOUR_DATA } from '../../util/drop_down.helper';
import { hasKey } from '../../util/object.util';
import { isString, sortByCreatedAt } from '../../util/utils';
import { getArrangementsValue, getLeaveCellText, getProperty, getServiceRefusedByText, yesOrNo } from '../../util/clientService.util';

@Component({
	selector: 'app-client-diversion',
	templateUrl: 'client-diversion.component.html',
	styleUrls: ['client-diversion.component.scss']
})
export class ClientDiversionComponent implements OnInit {

	public id: string | null = null;
	public type: string | null = null;
	public clientId: string | null = null;
	public clientData;
	public diversionCenters;
	public cellVisits;
	public communityPatrols;
	public accessCommentList: { label: string, value: string, name: string }[] = CLIENT_ACCESS;
	public symptomsList: any[] = SYMPTOMS_DATA;
	public behaviourList: any[] = BEHAVIOUR_DATA;

	public helpers = {
		getArrangementsValue,
		yesOrNo,
		getLeaveCellText,
		getProperty,
		getServiceRefusedByText
	};

	constructor(
		private clientService: ClientService,
		private route: ActivatedRoute,
		private _notifications: NotificationsService,
	) {
	}

	ngOnInit(): void {
		this.id = this.route.snapshot.paramMap.get('id');
		this.type = this.route.snapshot.paramMap.get('type');
		this.clientId = this.route.snapshot.paramMap.get('clientId');
		this.route.params.subscribe({
			next: params => {
				if (hasKey(params, 'id') && isString(params.id) && hasKey(params, 'type') && isString(params.type) && hasKey(params, 'clientId') && isString(params.clientId)) {
					this.id = params.id;
					this.type = params.type;
					this.clientId = params.clientId;

					//#region get data
					this.clientService.clientSearchListing({ page: 1, limit: 10, search: '', listType: this.type, mainId: this.id, clientId: params.clientId, isCheckout: true }).subscribe({
						next: (divData: any) => {
							if (divData && divData.status) {
								let currentRowData = divData.data.rows.shift();
								if (currentRowData.diversionCenters && currentRowData.diversionCenters.length) {
									this.clientData = currentRowData;
									let currentData = currentRowData.diversionCenters.shift();
									this.diversionCenters = currentData;
									if (this.diversionCenters.clientObservationForms) {
										this.diversionCenters.clientObservationForms.map((itm) => {
											itm.clientCheck = new Date(itm.clientCheck);
										});
										sortByCreatedAt(this.diversionCenters.clientObservationForms);
									}
								}
								if (currentRowData.cellVisits && currentRowData.cellVisits.length) {
									this.clientData = currentRowData;
									let currentData = currentRowData.cellVisits.shift();
									this.cellVisits = currentData;
									if (this.cellVisits.clientObservationForms) {
										this.cellVisits.clientObservationForms.map((itm) => {
											itm.clientCheck = new Date(itm.clientCheck);
										});
										sortByCreatedAt(this.cellVisits.clientObservationForms);
									}
								}
								if (currentRowData.communityPatrols && currentRowData.communityPatrols.length) {
									this.clientData = currentRowData;
									let currentData = currentRowData.communityPatrols.shift();
									this.communityPatrols = currentData;
									if (this.communityPatrols.clientObservationForms) {
										this.communityPatrols.clientObservationForms.map((itm) => {
											itm.clientCheck = new Date(itm.clientCheck);
										});
										sortByCreatedAt(this.communityPatrols.clientObservationForms);
									}
								}
								// this._notifications.success("Done", divData.message || "", {});
							} else {
								this._notifications.warn("ERROR", divData.message || "", {});
							}
						}
					})
					//#endregion

				}
			}
		});
	}

	itemBehaviour(selctedVal) {
		const behaviour = this.behaviourList.filter((itm) => {
			if (itm.value == selctedVal) {
				return itm.label;
			}
		}).shift();

		if (behaviour) {
			return behaviour.label;
		}

		return '';
	}

	itemSymptoms(selctedVal) {
		const symptom = this.symptomsList.filter((itm) => {
			if (itm.value == selctedVal) {
				return itm.label;
			}
		}).shift();

		if (symptom) {
			return symptom.label;
		}

		return '';
	}

	getLeaveCellComment(cellVisitRecord: any) {
		if (cellVisitRecord.isLeaveDiversion === 1) {
			return cellVisitRecord.leaveDiversionComment1;
		}

		if (cellVisitRecord.isLeaveDiversion === 4) {
			return cellVisitRecord.leaveDiversionComment2;
		}

		return '';
	}

	getLeaveDiversionComment(diversionRecord: any) {
		if (diversionRecord.isLeave === 1) {
			return diversionRecord.leaveComment1;
		}

		if (diversionRecord.isLeave === 4) {
			return diversionRecord.leaveComment2;
		}

		return '';
	}
}
