import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { NotificationsService } from "angular2-notifications";
import { CommunityPatrolApi } from "../api/community-patrol.api";
import { ICommunityPatrol } from "../model/community-patrol.model";
import { apiCallWrapper } from "../api/api.util";

@Injectable()
export class CommunityPatrolService extends GenericService<ICommunityPatrol> {
  constructor(
    public communityPatrolApi: CommunityPatrolApi,
    public notifications: NotificationsService
  ) {
    super(communityPatrolApi, notifications);
  }

  listingCommunityPatrol(query: {
    page: number;
    limit: number;
    search: string;
    filter: Record<string, unknown>
  }) {
    return apiCallWrapper(
      this.communityPatrolApi.listingCommunityPatrol(query),
      {
        notificationsService: this.notifications,
        action: `Fetching Community Patrol Records`
      }
    );
  }
} 