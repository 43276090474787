export enum EReportType {
	combined_department = "Combined",
	diversion_department = "Diversion Centre",
	diversion_detail = "Diversion Centre Detail",
	cell_department = "Cell Visit",
	cell_detail = "Cell Visit Detail",
	community_department = "Community Patrol",
	community_detail = "Community Patrol Detail"
}

export type IReportOptions = {
	locationIds: string[];
	fromDate: string | null;
	toDate: string | null;
	minAge: number | null;
	maxAge: number | null;
	reportType: EReportType
}
