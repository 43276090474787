<!--component html goes here -->

<!---
/*
<ng-select2
    *ngIf="ready"
    [id]="fieldId"
    [formControlName]="field"
    (keyup)="onKeyUp()"
    (click)="onClick()"
    (valueChanged)="onChange()"
    [options]="selectOptions"
    [data]="data">
</ng-select2>
*/

-->
<div class="formGroup">
	<span class="highlight"></span> <span class="bar"></span>
	<label [for]="fieldId" class="label">{{ fieldLabel }}</label>

	<select #selectElement class="js-select2 form-select" [disabled]="disabled" [ngClass]="{
		hasContent: hasContent,
		textEdit: true,
		'outline-none': true,
		'ng-touched': isTouched,
		'isOpen': isOpen
	}"></select>

	<span class="span" *ngFor="let errorText of errorsToDisplay">{{
		errorText
		}}</span>
	<div class="d-none" (cdkObserveContent)="onContentChange()" #contentWrapper>
		<ng-content></ng-content>
	</div>
</div>