import { Component, Input } from '@angular/core';

@Component({
    selector: 'flag-key',
    templateUrl: 'flag-key.component.html',
    styleUrls: ['flag-key.component.scss']
})
export class FlagKeyComponent {
  @Input()
  showMaster: boolean = true;

  @Input()
  showFootPatrol: boolean = true;
}
