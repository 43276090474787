import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { NotificationsService } from "angular2-notifications";
import { CellVisitApi } from "../api/cell-visit.api";
import { ICellVisit } from "../model/cell-visit.model";
import { apiCallWrapper } from "../api/api.util";

@Injectable()
export class CellVisitService extends GenericService<ICellVisit> {
  constructor(
    public cellVisitApi: CellVisitApi,
    public notifications: NotificationsService
  ) {
    super(cellVisitApi, notifications);
  }

  listingCellVisit(query: {
    page: number;
    limit: number;
    search: string;
    filter: Record<string, unknown>
  }) {
    return apiCallWrapper(
      this.cellVisitApi.listingCellVisit(query),
      {
        notificationsService: this.notifications,
        action: `Fetching Cell Visit Records`
      }
    );
  }
}