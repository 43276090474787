import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { NotificationsService } from "angular2-notifications";
import { ContactApi } from "../api/contact.api";
import { IContact } from "../model/contact.model";

@Injectable()
export class ContactService extends GenericService<IContact> {
  public entityName: string = "Contact";

  constructor(
    public contactApi: ContactApi,
    public notifications: NotificationsService
  ) {
    super(contactApi, notifications);
  }
}