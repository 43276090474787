<div class="tab-theme theme-box form-theme">

	<form [formGroup]="form">
		<div class="row g-3 g-xl-4 g-xxl-5">
			<div class="col-xl-12">
				<app-select-input [form]="form" field="locationIds" label="Locations" [allowClear]="true" [multiple]="true"
					(change)="updateLocationIds">
					<option value="{{ctx.id}}" *ngFor="let ctx of locationList">{{ctx.name}}</option>
				</app-select-input>
			</div>
			<div class="col-xl-12 mt-3">
				<app-select-input [form]="form" field="tags" label="Location Tags" [allowClear]="true" [multiple]="true">
					<option value="{{tag}}" *ngFor="let tag of tagList">{{tag}}</option>
				</app-select-input>
			</div>
			<div class="col-xl-12 mt-3">
				<p class="m-0 p-0 small text-secondary">
					Which records will be included in my report? <br />
					Locations <span class="text-danger">Not Selected</span> Tags <span class="text-danger">Not Selected</span>:
					All records will be considered<br />

					Locations <span class="text-success">Selected</span> Tags <span class="text-danger">Not Selected</span>: Any
					record in the selected locations will be considered<br />

					Locations <span class="text-danger">Not Selected</span> Tags <span class="text-success">Selected</span>: Any
					record in any location with any of the selected tags will be considered<br />

					Locations <span class="text-success">Selected</span> Tags <span class="text-success">Selected</span>: Any
					record in any selected location or any location with any of the selected tags will be considered
				</p>
			</div>
			<div class="col-lg-6">
				<label>From</label>
				<input type="date" class="form-control" formControlName="fromDate">
			</div>
			<div class="col-lg-6">
				<label>To</label>
				<input type="date" class="form-control" formControlName="toDate">
			</div>

			<div class="col-lg-6">
				<label>Mininmum Age</label>
				<input type="number" class="form-control" formControlName="minAge">
			</div>
			<div class="col-lg-6">
				<label>Maximum Age</label>
				<input type="number" class="form-control" formControlName="maxAge">
			</div>
			<div class="col-6 offset-6">
				<label>Report</label>
				<select class="form-select" formControlName="reportType">
					<option *ngFor="let reportType of reportTypeKeys" [value]="reportType">{{reportTypes[reportType]}}</option>
				</select>
			</div>
		</div>
	</form>

	<div class="row justify-content-end g-2 g-md-3 g-xxl-4 my-5">
		<div class="col-auto"><a class="btn btn-theme" (click)="downloadReport()">Download Report</a></div>
	</div>
</div>