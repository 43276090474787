import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { PinComponent } from '../autoLock/pin/pin.component';

const inactivityTimeoutSeconds = 600; // 5 minutes
const inactivityWarningSeconds = 30;
const inactivityWarningTimeoutSeconds = inactivityTimeoutSeconds - inactivityWarningSeconds;  // 4.5 minutes

@Injectable({
	providedIn: 'root'
})
export class InactivityService {
	private inactivityTimeout: any;
	private timerInterval: any;
	private lockTimeout: any;
	pin: string;
	isLoackScreen: boolean = false;

	constructor(private modalService: NgbModal) { }

	public resetInactivityTimer(clear: boolean = false): void {
		clearTimeout(this.inactivityTimeout);
		clearInterval(this.timerInterval);
		this.isLoackScreen = false;

		if (clear) {
			return;
		}

		this.inactivityTimeout = setTimeout(() => {
			this.warnUser();
		}, inactivityWarningTimeoutSeconds * 1000);
	}

	private warnUser(): void {
		let remainingSeconds = inactivityWarningSeconds;

		this.timerInterval = setInterval(() => {
			Swal.update({
				title: 'Warning',
				text: `Screen will lock in ${remainingSeconds} seconds.`,
			});

			remainingSeconds--;

			if (remainingSeconds < 0) {
				clearInterval(this.timerInterval);
				this.lockScreen();
				Swal.close();
			}
		}, 1000);

		Swal.fire({
			title: 'Warning',
			text: `Screen will lock in ${remainingSeconds} seconds.`,
			showConfirmButton: false,
			icon: 'warning',
		});
	}

	private lockScreen(): void {
		this.openModal();
		this.isLoackScreen = true;
		console.log('Screen is locked');
	}

	openModal() {
		const dataToPass = {
			pin: this.pin
		};

		this.modalService.open(PinComponent, {
			scrollable: true,
			centered: true,
			size: 'sm',
			backdrop: 'static',
			keyboard: false
		}).componentInstance['data'] = dataToPass;
	}

	public userActivity(): void {
		if (!this.isLoackScreen) {
			clearTimeout(this.lockTimeout);
			clearInterval(this.timerInterval);
			Swal.close();
			this.resetInactivityTimer();
		}
	}

	public setPin(pin: string) {
		this.pin = pin;
	}

	public getPin(): string {
		return this.pin;
	}
}
