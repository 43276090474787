<div class="form-content-wrapper">
    <form novalidate class="theme-box mb-4 mb-md-5 pb-4" (ngSubmit)="save()" [formGroup]="clientForm">
      <div class="form-theme bg-light mb-4 pb-3">
        <div class="row g-4 align-items-center">
          <div class="col-lg-6">
            <div class="form-group">
            <label for="firstName">Name</label>
            <div class="textarea-wrapper">
              <input #firstNameTb type="text" class="form-control" placeholder="First Name" formControlName="firstName"
              id="firstName" [ngClass]="{ 'is-invalid': clientForm.controls.firstName.errors }"
              [appMaxLengthIndicator]="250">
            <app-field-errors-component class="invalid-feedback" [formGroup]="clientForm" [name]="'firstName'"
              [errors]="{ required: 'Firstname is required' }"></app-field-errors-component>
            </div>
          </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="firstName">Last Name</label>
            <div class="textarea-wrapper">
              <input #lastNameTb maxlength="250" type="text" class="form-control" placeholder="Last Name"
              formControlName="lastName" name="lastName"
              [ngClass]="{ 'is-invalid': clientForm.controls.lastName.errors }" [appMaxLengthIndicator]="250">
              <app-field-errors-component class="invalid-feedback" [formGroup]="clientForm"
              [name]="'lastName'"
              [errors]="{ required: 'Lastname is required' }"></app-field-errors-component>
            </div>
          </div>
          </div>
          <div class="col-lg-6">
            <div class="textarea-wrapper">
              <input #alias1Tb maxlength="250" type="text" class="form-control" placeholder="Alias 1"
              formControlName="alias1" name="alias1" [appMaxLengthIndicator]="250">
            </div>
          </div>
          <div class="col-lg-6">
           <div class="textarea-wrapper">
            <input #alias2Tb maxlength="250" type="text" class="form-control" placeholder="Alias 2"
            formControlName="alias2" name="alias2" [appMaxLengthIndicator]="250">
           </div>
          </div>
          <div class="col-auto">
            <div class="d-flex align-items-center h-100">
              <div class="form-check mb-0">
                <input class="form-check-input" type="checkbox" value="true" id="isMaster" formControlName="isMaster"
                  name="isMaster">
                <label class="form-check-label" for="isMaster">
                  Master Record
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-theme bg-light mb-4 pb-3">
        <div class="row g-4 align-items-start">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="dob">DOB</label>
            <div class="textarea-wrapper">
              <input max="{{today | date:'yyyy-MM-dd'}}" name="dob" type="date" class="form-control" value=""
              formControlName="dob" id="dob" [ngClass]="{ 'is-invalid': clientForm.controls.dob.errors }">
              <app-field-errors-component class="invalid-feedback" [formGroup]="clientForm"
              [name]="'dob'"
              [errors]="{ required: 'dob is required' }"></app-field-errors-component>
            </div>

                <div class="form-check my-4">
                  <input #isDobInput class="form-check-input" type="checkbox" value="true" id="isDob"
                    formControlName="isDob" name="isDob" (change)="changeDOB($event)" (checked)="isDob">
                  <label class="form-check-label" for="isDob">
                    Unknown
                  </label>
                </div>
              </div>
         </div>

          <div class="col-lg-6">
           <div class="form-group">
            <label for="gender">Gender</label>
            <select class="form-select" aria-label="Default select example" formControlName="gender" id="gender"
              required [ngClass]="{ 'is-invalid': clientForm.controls.gender.errors }">
              <option>Male</option>
              <option>Female</option>
              <option>Transgender</option>
              <option>Undisclosed</option>
            </select>
           </div>
            <app-field-errors-component class="invalid-feedback" [formGroup]="clientForm"
              [name]="'gender'"
              [errors]="{ required: 'gender is required' }"></app-field-errors-component>
          </div>
        </div>
      </div>

      <div class="form-theme bg-light mb-4 pb-3">
        <div class="row g-4 align-items-end">
          <div class="col-lg-8">
            <div class="form-group">
              <label for="culturalIdentity">Cultural Identity</label>
            <select class="form-select" aria-label="Default select example" formControlName="culturalIdentity"
              id="culturalIdentity" required [ngClass]="{ 'is-invalid': clientForm.controls.culturalIdentity.errors }">
              <option value="Aboriginal">Aboriginal</option>
              <option value="Torres Strait Islander">Torres Strait Islander</option>
              <option value="South Sea">South Sea</option>
              <option value="Aboriginal &amp; Torres Strait Islander">Aboriginal &amp; Torres Strait Islander
              </option>
              <option value="Aboriginal, Torres Strait Islander and South Sea">Aboriginal, Torres Strait
                Islander and
                South Sea</option>
              <option value="CALD">CALD</option>
              <option value="Non-Indigenous">Non-Indigenous</option>
            </select>
            </div>
            <div *ngIf="clientForm.controls.culturalIdentity.errors" class="invalid-feedback">
              <div *ngIf="clientForm.controls.culturalIdentity.errors.required">
                Cultural Identity is required
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-check mb-0">
              <input class="form-check-input" type="checkbox" value="" id="highRisk" formControlName="highRisk">
              <label class="form-check-label" for="highRisk">
                High Risk Medical
              </label>
            </div>
          </div>
          <div class="col-lg-6 align-self-start">
            <div class="form-group">
             <label for="phone">Contact Number</label>
             <input #phoneText maxlength="250" type="text" class="form-control" formControlName="phone" id="phone"
               autocomplete="off" (input)="formatContactNumber(phoneText.value)">
             <span class="remaning">{{phoneText.value.length}}/250</span>
            </div>
           </div>
           <div class="col-lg-6 align-self-start">
            <div class="form-group">
             <label for="email">Email Address</label>
             <div class="textarea-wrapper">
             <input #emailTb maxlength="250" type="email" class="form-control" formControlName="email" id="email"
               name="email" autocomplete="off" [ngClass]="{ 'is-invalid': clientForm.controls.email.errors }">
             <div *ngIf="clientForm.controls.email.errors" class="invalid-feedback">
               <div *ngIf="clientForm.controls.email.errors.email">
                 Please enter valid email address
               </div>
             </div>
             <span class="remaning">{{emailTb.value.length}}/250</span>
            </div>
            </div>
           </div>
        </div>
      </div>

      <div class="form-theme bg-light mb-4 pb-3">
        <div class="row g-4 align-items-center">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="address">Address</label>
                <div class="input-group textarea-wrapper">
                  <input #addresstext maxlength="250" type="search" class="form-control" formControlName="address"
                    id="address" autocomplete="off">

                  <span _ngcontent-tqx-c21="" class="input-group-text"><svg focusable="false" viewBox="0 0 24 24"
                      aria-hidden="true">
                      <path _ngcontent-tqx-c21=""
                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                      </path>
                    </svg></span>
                </div>
                <span class="remaning">{{addresstext.value.length}}/250</span>
              </div>

                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" value="" id="isAddressTransitional"
                    formControlName="isAddressTransitional" (click)="uncheckOther('isAddressTransitional',$event)">
                  <label class="form-check-label" for="isAddressTransitional" name="addres_option">
                    Transitional Address
                  </label>
                </div>
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" value="" id="isAddressOutOfArea"
                    formControlName="isAddressOutOfArea"
                    (click)="removeAddress($event);uncheckOther('isAddressOutOfArea',$event)" name="addres_option">
                  <label class="form-check-label" for="isAddressOutOfArea">
                    Out of Area
                  </label>
                </div>
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" value="" id="isHomeless" formControlName="isHomeless"
                    (click)="removeAddress($event);uncheckOther('isHomeless',$event)" name="addres_option">
                  <label class="form-check-label" for="isHomeless">
                    Homeless (Sleeping Rough)
                  </label>
                </div>
                <div class="form-check mb-0">
                  <input class="form-check-input" type="checkbox" value="" id="isAddressUnkown"
                    formControlName="isAddressUnkown"
                    (click)="removeAddress($event);uncheckOther('isAddressUnkown',$event)" name="addres_option">
                  <label class="form-check-label" for="isAddressUnkown">
                    Unknown
                  </label>
                </div>

          </div>
        </div>
      </div>

      <div class="form-theme bg-light mb-4 pb-3">
        <div class="row g-4 align-items-center">
        <div class="col-lg-12">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="isBowmanJohnson"
                formControlName="isBowmanJohnson">
              <label class="form-check-label" for="isBowmanJohnson">
                Bowman Johnson
              </label>
            </div>

            <div class="row g-3 checkIn-box">
              <div class="col-lg-4 textarea-wrapper">
                <label for="startDate" class="mb-1">Check In</label>
                <input type="date" max="{{today | date:'yyyy-MM-dd'}}" class="form-control" formControlName="startDate"
                  id="startDate" name="startDate">
              </div>
              <div class="col-lg-4 textarea-wrapper">
                <label for="endDate" class="mb-1"> Check Out</label>
                <input type="date" max="{{today | date:'yyyy-MM-dd'}}" class="form-control" formControlName="endDate"
                  id="endDate" name="endDate">
              </div>
              <div class="col-lg-4 textarea-wrapper">
                <label for="roomNo" class="mb-1">Room #</label>
                <input #roomNoTb maxlength="250" type="text" class="form-control" formControlName="roomNo" id="roomNo"
                  name="roomNo">
                <span class="remaning">{{roomNoTb.value.length}}/250</span>
              </div>
            </div>
          </div>
          </div>
      </div>

      <div class="row justify-content-end g-2 g-md-3 g-xxl-4 pe-5">
        <div class="col-auto">
          <button [disabled]="clientForm.invalid" class="btn btn-theme">Save</button>
        </div>
       <div *ngIf="id" class="col-auto">
        <button class="btn btn-themeNew" [routerLink]="['/diversion-centre/edit', id]">Diversion Centre</button>
       </div>
        <div *ngIf="id" class="col-auto">
          <button class="btn btn-themeNew" [routerLink]="['/cell-visits/edit', id]">Cell Visit</button>
        </div>
        <div *ngIf="id" class="col-auto">
          <button class="btn btn-themeNew" [routerLink]="['/community-patrol/edit', id]">Community Patrol</button>
        </div>
      </div>
    </form>

  <div *ngIf="id" class="tab-theme tab-dashboard theme-box mb-4 mb-md-5 pb-4">
    <app-tab-set #tabSet [tabs]="['Past Activities ','Medical Observations','File Notes']"></app-tab-set>
    <div class="tab-content">
      <div [ngClass]="{'d-none': tabSet.activeTabIdx !== 0}">
        <div class="table-responsive">
          <table class="table table-theme table-stripedTheme text-center">
            <thead>
              <tr>
                <th>DATE</th>
                <th>ACTIVITY</th>
                <th>DETAILS</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let itm of pastActivityData">
                <td>{{itm.createdAt | date:'dd/MM/YY hh:mm a'}}</td>
                <td>{{itm.name}}</td>
                <td>
                  <button [routerLink]="['/clients',itm.linkName,itm.id,itm.clientId]"
                    class="btn btn-grayTheme">VIEW</button>
                </td>
              </tr>
              <tr *ngIf="pastActivityData.length === 0">
                <td colspan="3" class="text-center">No Past Activity</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1}">
        <div class="row mb-3">
          <a class="btn btn-orange ms-auto col-auto" (click)="addNew(content,'medical')">Add New</a>
        </div>
        <div class="table-responsive">
          <table class="table table-theme table-stripedTheme text-center">
            <thead>
              <tr>
                <th>DATE</th>
                <th>ACTIVITY</th>
                <th>NOTE</th>
                <th>CREATED BY</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let itm of clientNotesList">
                <tr *ngIf="itm.notesType === 'medical'">
                  <td>{{itm.createdAt | date}}</td>
                  <td>{{itm.createdAt | date:'hh:mm a'}}</td>
                  <td><a (click)="editNote(contentEdit,itm.notes)">{{itm.notes}}</a></td>
                  <td>{{itm.user?.name}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 2}">
        <div class="row mb-3">
          <a class="btn btn-orange ms-auto col-auto" (click)="addNew(content,'general')">Add New</a>
        </div>

        <div class="table-responsive">
          <table class="table table-theme table-stripedTheme text-center">
            <thead>
              <tr>
                <th>DATE</th>
                <th>ACTIVITY</th>
                <th>NOTE</th>
                <th>CREATED BY</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let itm of clientNotesList">
                <tr *ngIf="itm.notesType === 'general'">
                  <td>{{itm.createdAt | date}}</td>
                  <td>{{itm.createdAt | date:'hh:mm a'}}</td>
                  <td><a (click)="editNote(contentEdit,itm.notes)">{{itm.notes}}</a></td>
                  <td>{{itm.user?.name}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h3 *ngIf="selectedType === 'medical'" class="modal-title">Write Medical Observation</h3>
    <h3 *ngIf="selectedType === 'general'" class="modal-title">Write Note</h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="noteForm">
      <input type="hidden" formControlName="notesType" id="notesType" name="notesType" value="selectedType">
      <textarea class="form-control" rows="3" formControlName="notes" id="notes" name="notes" required
        [ngClass]="{ 'is-invalid': noteForm.controls.notes.errors }"></textarea>
      <div *ngIf="noteForm.controls.notes.errors" class="invalid-feedback">
        <div *ngIf="noteForm.controls.notes.errors.required">
          Summary is required
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="createClientNotes()">Save</button>
    <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>

<ng-template #contentEdit let-modal>
  <div class="modal-header">
    <h3 class="modal-title">Full Note</h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="form-theme">
      <p>{{selectedNotes}}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
