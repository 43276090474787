<section class="features-section-5 relative" id="contact">

  <div class="form-outer login-box">

    <div class="app-section-header text-center mb-4 mb-md-5">
      <app-section-title text="Register"></app-section-title>
    </div>

    <div class="clearfix"></div>

    <form [formGroup]="registerForm" class="">
      <div class="pb-4 pb-md-5">
        <div class="form-floating mb-30">
          <app-text-input field="email" label="Email Address" [form]="registerForm" [disabled]="true" [errors]='{ 
              required: "Email Address Required",
              email: "Invalid Email Address",
              serverValidation: "Rejected by server"
          }'></app-text-input>
        </div>
        <div class="form-floating mb-30">
          <app-text-input field="password" label="Password" [form]="registerForm" type="password"
            [errors]='{ required: "Password Required" }'></app-text-input>
        </div>
        <div class="form-floating mb-30">
          <app-text-input field="c_password" label="Confirm Password" [form]="registerForm" type="password"
            [errors]='{ required: "Password Confirmation Required" }'></app-text-input>
        </div>
        <div class="form-floating mb-30">
          <app-text-input field="phone" label="Phone" [form]="registerForm"
            [errors]='{ required: "Phone Required" }'></app-text-input>
        </div>
      </div>

      <div class="mb-4 mb-md-5">
        <button class="btn btn-theme send_mess w-100" [disabled]="registerForm.invalid" (click)="register()">Register</button>
      </div>
      <div class="text-center">
        <a class="btn-link" routerLink="/login">Cancel</a>
      </div>
    </form>
  </div>
</section>