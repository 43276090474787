import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { environment } from "../../../environments/environment";
import { CategoryService } from 'src/app/main/services/category.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TabSetComponent } from 'src/app/ui/view/tab-set/tab-set.component';
import { ICategory } from '../model/category.model';

type ServiceTabState = 'list' | 'edit';
type action = 'Create' | 'Edit';

@Component({
    moduleId: module.id,
    selector: 'app-categories',
    templateUrl: 'categories.component.html',
    styleUrls: ['categories.component.scss']
})
export class CategoriesComponent implements AfterViewInit {
    public tabState: ServiceTabState = 'list';
    public actionType: action = 'Create';
    public categoryForm: FormGroup;

    @ViewChild(TabSetComponent, { static: false }) tbc: TabSetComponent;

    constructor(
        private _notifications: NotificationsService,
        private __router: Router,
        private __categoryService: CategoryService,
        private __formBuilder: FormBuilder,
    ) {
        this.categoryForm = this.__formBuilder.group({
            name: ['', [Validators.required]],
        });
    }

    public categoryList: ICategory[] = [];
    public selectedCategory: ICategory | undefined;
    public totalCount: number = 0;
    public currentPage: number = 1;
    public pageLimit: number = environment.defaultCollectionLimit;
    public search: string = "";
    public submitted = false;

    // convenience getter for easy access to form fields
    get f() { return this.categoryForm.controls; }

    pageChanged(page: number) {
        this.currentPage = page;
        this.getUserCollection();
    }

    getUserCollection() {
        let skip = (this.currentPage * this.pageLimit) - this.pageLimit;
        this.__categoryService.list({ skip: skip, limit: this.pageLimit, filter: {} }).subscribe(async (response: any) => {
            if (response && response.rows) {
                this.categoryList = response.rows;
                this.totalCount = response.count;
                // this._notifications.success("Done", response.message || "", {});
            } else {
                this._notifications.warn("ERROR", response.message || "", {});
            }
        }, err => {
            console.error(err);
            this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
        })
    }

    ngAfterViewInit() {
        this.getUserCollection();
    }

    /**
       * createcategory
       */
    public createCategory(): void {
        this.submitted = true;
        if (!this.categoryForm.valid) {
            return;
        }
        if (this.selectedCategory) {
            this.__categoryService.update(this.selectedCategory.id, this.categoryForm.value).subscribe(() => {
                this.getUserCollection();
                this.tbc.tabs = ['Categories', 'Add New'];
                this.tbc.setActiveTab(0);
            })
        } else {
            this.__categoryService.create(this.categoryForm.value).subscribe(() => {
                this.getUserCollection();
                this.tbc.tabs = ['Categories', 'Add New'];
                this.tbc.setActiveTab(0);
            })
        }
        this.f['name'].setValue('');
        this.f['name'].updateValueAndValidity();
    }

    /**
       * selectCat
       */
    public selectCat(cat: ICategory): void {
        this.tbc.tabs = ['Categories', cat.name];
        this.tbc.setActiveTab(1);
        this.selectedCategory = cat;
        this.f['name'].setValue(cat.name);
        this.actionType = 'Edit';
    }

    public clickedTab(idx: number): void {
        if (idx == 0) {
            this.selectedCategory = undefined;
            this.tbc.tabs = ['Categories', 'Add New'];
            this.f['name'].setValue('');
            this.actionType = 'Create';
        }
    }

    /**
       * deleteCategory
       */
    public deleteCategory(): void {
        if (this.selectedCategory) {
            this.__categoryService.delete(this.selectedCategory.id).subscribe(() => {
                this.getUserCollection();
                this.tbc.tabs = ['Categories', 'Add New'];
                this.tbc.setActiveTab(0);
            })
        }
        this.f['name'].setValue('');
        this.f['name'].updateValueAndValidity();
    }
}
