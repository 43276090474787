import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { environment } from "../../../environments/environment";
import { LocationService } from 'src/app/main/services/location.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TabSetComponent } from 'src/app/ui/view/tab-set/tab-set.component';
import { ILocation, ILocationTag } from '../model/location.model';
import { logger } from '../util/Logger';
import { getAllFormErrors } from '../util/formUtils';
import { CommonControllerComponent } from '../util/common.controller';

type ServiceTabState = 'list' | 'edit';
type action = 'Create' | 'Edit';
@Component({
	selector: 'app-location',
	templateUrl: 'location.component.html',
	styleUrls: ['location.component.scss']
})
export class LocationComponent implements AfterViewInit {
	public tabState: ServiceTabState = 'list';
	public actionType: action = 'Create';
	public locationForm: FormGroup;

	@ViewChild(TabSetComponent, { static: false }) tbc: TabSetComponent;

	constructor(
		private _notifications: NotificationsService,
		private __locationService: LocationService,
		private __formBuilder: FormBuilder,
	) {
		this.locationForm = this.__formBuilder.group({
			name: ['', [Validators.required]],
			isDiversion: true,
			isCellVisit: true,
			isCommunityPatrol: true,
			tags: this.__formBuilder.array([])
		});
	}

	public locationList: ILocation[] = [];
	public tagList: ILocationTag[] = [];

	public selectedLocation: ILocation | undefined;
	public totalCount: number = 0;
	public currentPage: number = 1;
	public pageLimit: number = environment.defaultCollectionLimit;
	public search: string = "";
	public submitted = false;

	// convenience getter for easy access to form fields
	get f() { return this.locationForm.controls; }


	pageChanged(page: number) {
		this.currentPage = page;
		this.getLocationCollection();
	}

	getLocations() {
		this.__locationService.list({
			limit: 1000
		}).subscribe({
			next: response => {
				const tagMap = response.rows
					.reduce((result, location) => {
						result.push(...(location.tags || []));
						return result;
					}, [] as string[])
					.reduce((result, tag) => {
						const key = tag.toLowerCase();
						if (!(key in result)) {
							result[key] = tag;
						}

						return result;
					}, {} as Record<string, string>);
				this.tagList = Object.keys(tagMap).map(key => tagMap[key]).sort();
			},
			error: err => {
				console.error(err);
				this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
			}
		});
	}

	getLocationCollection() {
		let skip = (this.currentPage * this.pageLimit) - this.pageLimit;
		this.__locationService.list({ skip: skip, limit: this.pageLimit, filter: {}, sortBy: 'name' }).subscribe(async (response: any) => {
			if (response && response.rows) {
				this.locationList = response.rows;
				this.totalCount = response.count;
			} else {
				this._notifications.warn("ERROR", response.message || "", {});
			}
		}, err => {
			console.error(err);
			this._notifications.error("ERROR", err.error && err.error.message || err.message || "", {})
		})
	}

	ngAfterViewInit() {
		this.getLocationCollection();
		this.getLocations();
	}

	/**
			 * createLocation
			 */
	public createLocation(): void {
		this.submitted = true;

		this.locationForm.controls['tags'].updateValueAndValidity();

		if (!this.locationForm.valid) {
			logger.error(getAllFormErrors(this.locationForm));
			return;
		}

		if (this.selectedLocation) {
			this.__locationService.update(this.selectedLocation.id, this.locationForm.value)
				.subscribe(() => {
					this.getLocationCollection();
					this.getLocations();
					this.tbc.tabs = ['Locations', 'Add New'];
					this.tbc.setActiveTab(0);
				})
		} else {
			this.__locationService.create(this.locationForm.value).subscribe(() => {
				this.getLocationCollection();
				this.getLocations();
				this.tbc.tabs = ['Locations', 'Add New'];
				this.tbc.setActiveTab(0);
			})
		}
		this.f['name'].setValue('');
		this.f['isDiversion'].setValue(false);
		this.f['isCellVisit'].setValue(false);
		this.f['isCommunityPatrol'].setValue(false);
		this.f['name'].updateValueAndValidity();
		this.f['isDiversion'].updateValueAndValidity();
		this.f['isCellVisit'].updateValueAndValidity();
		this.f['isCommunityPatrol'].updateValueAndValidity();
	}

	/**
		 * createLocation
		 */
	public selectLoc(loc: ILocation): void {
		this.tbc.tabs = ['Locations', loc.name];
		this.tbc.setActiveTab(1);
		this.selectedLocation = loc;
		this.f['name'].setValue(loc.name);
		this.f['isDiversion'].setValue(loc.isDiversion);
		this.f['isCellVisit'].setValue(loc.isCellVisit);
		this.f['isCommunityPatrol'].setValue(loc.isCommunityPatrol);

		const existingTags = this.f['tags'] as FormArray;
		while (existingTags.length) {
			existingTags.removeAt(0);
		}
		(loc.tags || []).forEach(tag => {
			existingTags.push(new FormControl(tag));
		});

		this.actionType = 'Edit';
	}

	public clickedTab(idx: number): void {
		if (idx == 0) {
			this.selectedLocation = undefined;
			this.tbc.tabs = ['Locations', 'Add New'];
			this.f['name'].setValue('');
			this.f['isDiversion'].setValue(false);
			this.f['isCellVisit'].setValue(false);
			this.f['isCommunityPatrol'].setValue(false);
			this.actionType = 'Create';
		}
	}

}
