import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[appMaxLengthIndicator]',
})
export class MaxLengthIndicatorDirective implements OnInit {
  @Input('appMaxLengthIndicator') maxLength: number;

  private indicatorElement: HTMLElement;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.createIndicator();
    this.updateIndicator();
  }

  private createIndicator() {
    this.indicatorElement = document.createElement('span');
    this.indicatorElement.classList.add('remaning');
    this.elementRef.nativeElement.parentNode.appendChild(this.indicatorElement);
  }

  private updateIndicator() {
    const value = this.elementRef.nativeElement.value;
    this.indicatorElement.textContent = `${value.length}/${this.maxLength}`;
  }

  @HostListener('input')
  onInput() {
    this.updateIndicator();
  }
}
