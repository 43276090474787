import { Injectable } from "@angular/core";
import { ReportApi } from "../api/report.api";
import { IReportOptions } from "../model/report.model";
import { apiCallWrapper } from "../api/api.util";
import { NotificationsService } from "angular2-notifications";
import { Observable } from "rxjs";

@Injectable()
export class ReportService {
  constructor(
    private reportApi: ReportApi,
    public notifications: NotificationsService
  ) {
  }

  getReport = (opts: IReportOptions): Observable<string> => {
    return apiCallWrapper(
      this.reportApi.getReport(opts),
      {
        notificationsService: this.notifications,
        action: "Downloading Report"
      }
    )
  };


}