<div
	[class]="'offlineNotification alert alert-danger py-0 mx-y text-center rounded-0 position-fixed w-100 ' + (showOfflineNotification?'open':'')">
	Internet Currently Offline
</div>
<div [@routeAnimations]="prepareRoute(outlet, 'animation')" #viewPort>
	<router-outlet #outlet="outlet"></router-outlet>
</div>

<!--- [options]="options" --->
<simple-notifications></simple-notifications>
