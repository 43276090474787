import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICellVisit } from '../model/cell-visit.model';
import { createUrl } from './api.util';

@Injectable()
export class CellVisitApi extends GenericApi<ICellVisit> {
  public path = "cell-visit";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  /**
   * createCellVisit
   */
  public createCellVisit(credentials: {}) {
    return this.httpClient.post<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'createCellVisit'), credentials);
  }

  /**
     * createBulkCellVisit
     */
  public createBulkCellVisit(credentials: {}) {
    return this.httpClient.post<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'createBulkCellVisit'), credentials);
  }

  /**
   * updateCellVisit
   */
  public updateCellVisit(cellId, credentials: {}) {
    return this.httpClient.put<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'updateCellVisit', cellId), { ...credentials, cellId: cellId });
  }


  /**
 * updateCellVisit
 */
  public updateBulkCellVisit(cellId, credentials: {}) {
    return this.httpClient.put<{
      status: boolean,
      message: string,
    }>(createUrl(this.path, 'updateBulkCellVisit', cellId), { ...credentials, cellId: cellId });
  }

  x
  /**
   * listingCellVisit
   */
  public listingCellVisit(credentials: {}) {
    return this.httpClient.post<{
      message: string,
      status: boolean,
      data: {
        count: number,
        rows: unknown[]
      }
    }>(createUrl(this.path, 'listingCellVisit'), credentials);
  }
}